import {Product, ProductVariant} from '@/management/product/state/product.model';
import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {filterNilValue} from '@datorama/akita';
import {DqnTableHeader} from '@dqn/components/table';
import {tap} from 'rxjs/operators';
import {FormModeDirective, FormModeHostDirective} from '../../../../directives/form-mode.directive';
import {getMatDialogConfig} from '../../../../utils/functions/mat-dialog';
import {VariantAttributesManagementQuery} from '../variant-attributes-management/variant-attributes-management.state';
import {
  VariantsAddDialogComponent,
  VariantsAddDialogData,
  VariantsAddDialogOutput
} from '../variants-add-dialog/variants-add-dialog.component';
import {createPossibleVariants} from './helpers/create-possible-variants';
import {VariantManagementQuery, VariantManagementService} from './variant-management.state';


@Component({
  selector: 'app-variant-management',
  templateUrl: './variant-management.component.html',
  styleUrls: ['./variant-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [
    FormModeHostDirective,
  ]
})
export class VariantManagementComponent {
  readonly formMode = inject(FormModeDirective, {self: true});
  readonly query = inject(VariantManagementQuery);
  // TODO: Decouple this service
  readonly variantAttributesManagementQuery = inject(VariantAttributesManagementQuery);

  private readonly dialog = inject(MatDialog);
  private readonly service = inject(VariantManagementService);

  @Input() set variants(variants: Product[]) {
    this.service.setInitialVariants(variants ?? []);
  }

  variantHeaders: DqnTableHeader[] = [
    {key: 'variant', title: 'Variante'},
    {key: 'name', title: 'Name'},
    {key: 'status', title: 'Status'},
    {key: 'suppliers', title: 'Lieferanten'},
  ];

  openVariantsAddDialog() {
    const dialogConfig = getMatDialogConfig<VariantsAddDialogData>({
      data: {
        // variantAttributes, // HINT: Preparation for filters in dialog
        variants: createPossibleVariants(this.variantAttributesManagementQuery.variantAttributes, this.query.variants),
      },
      autoFocus: false,
      minWidth: 'min(95vw, 800px)'
    });

    this.dialog.open<VariantsAddDialogComponent, unknown, VariantsAddDialogOutput>(VariantsAddDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(
        filterNilValue(),
        tap(productVariants => this.service.addVariants(productVariants))
      )
      .subscribe();
  }

  removeVariant(variantId: ProductVariant['id']) {
    this.service.removeVariant(variantId);
  }
}
