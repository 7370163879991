import {StatusesQuery} from '@/shared/state/status/statuses.query';
import {inject, Injectable} from '@angular/core';
import {combineQueries, QueryEntity} from '@datorama/akita';
import {DqnSingleOption} from '@dqn/components/combobox';
import {Observable, of} from 'rxjs';
import {distinctUntilChanged, filter, map, shareReplay, switchMap} from 'rxjs/operators';
import {addStatusToSupplierProduct} from './helpers/functions/add-status-to-suppliers';
import {calculateStatusForProduct} from './helpers/functions/calculate-status-for-product';
import {isProductWithVariants} from './helpers/functions/is-product-with-variants';
import {isSingleProduct} from './helpers/functions/is-single-product';
import {isVariantOfProduct} from './helpers/functions/is-variant-of-product';
import {ProductCategory} from './product-categories/product-category.model';
import {Product, ProductVariant} from './product.model';
import {ProductsState, ProductsStore} from './products.store';
import {VariantAttribute} from './variant-attributes/variant-attribute.model';
import {VariantAttributesQuery} from './variant-attributes/variant-attributes.query';

@Injectable({providedIn: 'root'})
export class ProductsQuery extends QueryEntity<ProductsState> {
  private readonly variantAttributesQuery = inject(VariantAttributesQuery);

  isLoading$ = this.selectLoading();

  products$ = combineQueries([
    this.selectAll(),
    this.statusesQuery.statuses$,
  ]).pipe(
    map(([products, statuses]) => statuses
      ? products.map(product => ({
        ...product,
        suppliers: addStatusToSupplierProduct(product.suppliers, statuses),
      } as Product))
      : products
    ),
  );

  topLevelProducts$ = this.products$.pipe(
    map(products => products.filter(product => product.parent_id === null)),
  );

  activeProduct$ = this.selectActive();

  activeProductOrVirtualProduct$ = this.activeProduct$.pipe(
    map(product => product?.parent_id ?? null),
    distinctUntilChanged(),
    switchMap(productId => this.selectProductById(productId)),
  );

  variantsForActiveProduct$: Observable<ProductVariant[]> = this.activeProduct$.pipe(
    filter(isProductWithVariants),
    switchMap(activeProduct => activeProduct ? this.selectVariantsForProduct(activeProduct.id) : of([])),
  );

  hasVariantsForActiveProduct$ = this.variantsForActiveProduct$.pipe(
    map(variants => variants.length > 0),
  );

  variantAttributesForActiveProduct$ = this.activeProductOrVirtualProduct$.pipe(
    switchMap(product => this.variantAttributesQuery.selectVariantAttributesForProduct(product?.id)),
  );

  productAttributesForActiveProduct$ = this.activeProduct$.pipe(
    map(product => product?.product_attributes ?? []),
  );

  variantAttributesAsProductAttributesForActiveProduct$: Observable<VariantAttribute[]> = this.activeProduct$.pipe(
    switchMap(activeProduct => {
      if (!activeProduct || isSingleProduct(activeProduct)) {
        return of([]);
      }

      return this.variantAttributesQuery.selectVariantAttributesForProduct(activeProduct.parent_id
        ? activeProduct.parent_id
        : activeProduct.id
      );
    }),
  );

  productAttributesWithoutVariantAttributesForActiveProduct$ = combineQueries([
    this.activeProduct$,
    this.variantAttributesAsProductAttributesForActiveProduct$,
  ]).pipe(
    map(([activeProduct, variantAttributes]) => activeProduct?.product_attributes.filter(productAttribute =>
      !variantAttributes.some(variantAttribute =>
        variantAttribute.product_attribute_template_id === productAttribute.product_attribute_template_id
      )
    )),
    shareReplay({refCount: true, bufferSize: 1}),
  );

  productAttributesWithOnlyVariantAttributesForActiveProduct$ = combineQueries([
    this.activeProduct$,
    this.variantAttributesAsProductAttributesForActiveProduct$,
  ]).pipe(
    map(([activeProduct, variantAttributes]) => activeProduct?.product_attributes.filter(productAttribute =>
      variantAttributes.some(variantAttribute =>
        variantAttribute.product_attribute_template_id === productAttribute.product_attribute_template_id
      )
    )),
    shareReplay({refCount: true, bufferSize: 1}),
  );

  constructor(
    protected store: ProductsStore,
    private statusesQuery: StatusesQuery,
  ) {
    super(store);
  }

  selectProductById(productId: Product['id']) {
    return this.selectEntity(productId);
  }

  selectProductsForCategory(categoryId: ProductCategory['id']) {
    return this.products$.pipe(
      map(products => products.filter(product => product.product_category_id === categoryId)),
    );
  }

  selectProductsForCategoryAsDqnOptions(categoryId: ProductCategory['id']) {
    return this.selectProductsForCategory(categoryId).pipe(
      map(products => products.map(({id, name}) => ({
        value: id,
        title: name,
      } as DqnSingleOption)))
    );
  }

  selectStatusForProduct(productId: Product['id']) {
    return this.selectProductById(productId).pipe(
      map(product => calculateStatusForProduct(product)),
    );
  }

  selectVariantsForProduct(productId: Product['id']) {
    return this.products$.pipe(
      map(products => products.filter(product => isVariantOfProduct(product, productId))),
    );
  }
}
