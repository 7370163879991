<mat-toolbar
  color="primary"
  class="fix-nav flex">
    <div class="flex flex-1 justify-start items-center w-full h-full">
        <button
          type="button"
          mat-icon-button
          class="waffle"
          color="primary"
          matTooltip="">
            <a
              [href]="userTenantNavigationUrl"
              target="_blank">
                <img
                  src="../../../../assets/icon_waffle.png"
                  alt="" />
            </a>
        </button>
        <img
          *ngIf="tenantId"
          class="logo"
          [src]="(tenantImagesQuery.getDefaultImageUrlByTenantId(tenantId) | async)"
          alt="" />
        <span
          class="title"
          matTooltip="zur Startseite"
          routerLink="/home">{{
            title
            }}</span>
    </div>

    <div
      *ngIf="environment.searchActive"
      class="search flex-1">
        <form class="flex justify-center items-center h-full">
            <mat-form-field
              subscriptSizing="dynamic"
              class="w-full">
                <input
                  matInput
                  [formControl]="searchControl"
                  (keydown.enter)="navigateToSearchResults()"
                  placeholder="Alle Produkte durchsuchen" />
                <span matIconPrefix><mat-icon>search</mat-icon></span>
            </mat-form-field>
        </form>
    </div>

    <div class="flex flex-1 justify-end items-center">
        <button
          *ngIf="environment.shopActive"
          type="button"
          mat-button
          class="cart"
          color="secondary"
          matTooltip="Einkaufswagen anzeigen"
          (click)="navigateToShoppingCart()">
            <mat-icon>shopping_cart</mat-icon>
            <span class="shopping-cart-count">{{ shoppingCartItemCount$ | async }}</span>
        </button>

        <button
          [matTooltip]="userName + ' (' + userEmail + ')'"
          class="profile"
          color="primary"
          mat-icon-button
          [matMenuTriggerFor]="profileMenu"
          type="button">
            <img
              src="/assets/icon_profile_default.svg"
              alt="" />
        </button>
        <mat-menu
          #profileMenu="matMenu"
          class="mat-typography profile-menu">
            <div>
                <div class="mb-2 flex flex-col items-center justify-center">
                    <img
                      class="flex"
                      src="/assets/icon_profile_default.png"
                      alt="" />
                    <div
                      class="flex"
                      class="profile-user-name">
                        {{ userName }}
                    </div>
                    <div
                      class="flex"
                      class="profile-tenant-name">
                        {{ tenantName }}
                    </div>
                    <div
                      class="flex"
                      class="profile-user-email">
                        {{ userEmail }}
                    </div>
                </div>
                <a
                  class="!flex justify-start items-center"
                  mat-menu-item
                  href="https://myaccount.microsoft.com/"
                  target="_blank">
                    <div class="w-full flex items-center justify-center">
                        <div class="flex">Account anzeigen</div>
                        <div class="flex flex-1 justify-end items-center">
                            <fa-icon
                              [icon]="['far', 'user-circle']"
                              [fixedWidth]="true"
                              size="lg"/>
                        </div>
                    </div>
                </a>

                <mat-divider></mat-divider>

                <a
                  class="!flex justify-start items-center"
                  mat-menu-item
                  href="https://portal.office.com"
                  target="_blank">
                    <div class="w-full flex items-center justify-center">
                        <div class="flex">Office-Portal</div>
                        <div class="flex flex-1 justify-end items-center">
                            <fa-icon
                              [icon]="['far', 'grid']"
                              [fixedWidth]="true"
                              size="lg"/>
                        </div>
                    </div>
                </a>

                <mat-divider></mat-divider>

                <ng-container *ngIf="(sleekplanQuery.isInitialized$ | async) === true">
                    <button
                      class="!flex justify-start items-center"
                      mat-menu-item
                      [disabled]="(sleekplanQuery.feedbackButtonDisabled$ | async) === true"
                      (click)="sleekplanService.openCreateFeedback()">
                        <div
                          class="w-full flex justify-center items-center">
                            <div class="flex">Feedback senden</div>
                            <div
                              class="flex flex-1 justify-end items-center">
                                <fa-icon
                                  [icon]="['far', 'lightbulb-on']"
                                  [fixedWidth]="true"
                                  size="lg"/>
                            </div>
                        </div>
                    </button>
                    <mat-divider></mat-divider>
                </ng-container>

                <button
                  type="button"
                  mat-menu-item
                  (click)="logout()"
                  color="primary">
                    <div class="flex">
                        <div class="flex-1">Abmelden</div>
                        <div class="flex flex-1 justify-end items-center">
                            <mat-icon class="material-icons-outlined">lock</mat-icon>
                        </div>
                    </div>
                </button>
            </div>
        </mat-menu>
    </div>
</mat-toolbar>
