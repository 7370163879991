import {OrderItemsUpdatedData} from '../types/order-items-updated-data';

export const getAdditionalDataForItemsUpdated = ({added_order_items, updated_order_items}: OrderItemsUpdatedData) => {
  const removedOrderItems = updated_order_items.filter(product => product.quantity === 0);
  const updatedOrderItems = updated_order_items.filter(product => product.quantity > 0);

  return [
    {
      title: 'Hinzugefügte Artikel',
      text: getDisplayTextForUpdatedOrderItems(added_order_items),
    },
    {
      title: 'Entfernte Artikel',
      text: getDisplayTextForUpdatedOrderItems(removedOrderItems),
    },
    {
      title: 'Aktualisierte Artikel',
      text: getDisplayTextForUpdatedOrderItems(updatedOrderItems),
    },
  ].filter(({text}) => text !== '');
}

const getDisplayTextForUpdatedOrderItems = (orderItems: OrderItemsUpdatedData['updated_order_items'] | OrderItemsUpdatedData['added_order_items']) => {
  return orderItems.map(({displayName, costCenterDisplayName, quantity}) => {
    const changedText = [
      (quantity ? 'Menge: ' + quantity : ''),
      (costCenterDisplayName ? 'Kostenstelle: ' + costCenterDisplayName : ''),
    ].filter(Boolean).join(', ');
    const changedDisplaytext = changedText ? `(${changedText})` : '';

    return changedDisplaytext
      ? `${displayName} ${changedDisplaytext}`
      : displayName;
  }).join(', ');
}
