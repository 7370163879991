<div mat-dialog-title>Bezugsquelle erstellen/bearbeiten</div>

<mat-dialog-content>
    <form
      [formGroup]="supplierProductForm"
      class="flex flex-col gap-1">
        <dqn-form-field class="w-full">
            <dqn-label>Lieferant</dqn-label>

            <dqn-combobox
              formControlName="supplierId"
              [options]="(suppliers$ | async)" />
        </dqn-form-field>

        <dqn-form-field>
            <dqn-label>Artikelnummer</dqn-label>
            <input
              dqnInput
              formControlName="supplierOrderNumber"
              required />
        </dqn-form-field>

        <div
          class="flex gap-1"
          formGroupName="unitPriceGroup">
            <app-dqn-input-number
              class="flex-1"
              [required]="
                      this.supplierProductForm.get('billingFrequencyPriceGroup.billingFrequencyPrice').value === null ||
                      this.supplierProductForm.get(
                        'billingFrequencyPriceGroup.billingFrequencyPrice'
                      )?.value === ''
                    "
              label="Einmalpreis"
              [value]="this.supplierProductForm.get('unitPriceGroup').get('unitPrice').value"
              (numberChange)="updateUnitPrice($event)">
            </app-dqn-input-number>

            <dqn-form-field class="w-[20%]">
                <dqn-label>Währung</dqn-label>
                <dqn-dropdown
                  formControlName="currencyCodeId"
                  [required]="!!this.supplierProductForm.get('unitPriceGroup').get('unitPrice').value">
                    <dqn-option
                      *ngFor="let currencyCode of currencyCodes$ | async"
                      [value]="currencyCode.id">
                        {{ currencyCode.display_name }}
                    </dqn-option>
                </dqn-dropdown>
            </dqn-form-field>
        </div>

        <div
          class="flex gap-1"
          formGroupName="billingFrequencyPriceGroup">
            <app-dqn-input-number
              class="flex-1"
              [required]="
                      this.supplierProductForm.get('unitPriceGroup').get('unitPrice').value === null
                      || this.supplierProductForm.get('unitPriceGroup').get('unitPrice').value === ''"
              label="Abrechnungspreis"
              [value]="this.supplierProductForm.get('billingFrequencyPriceGroup').get('billingFrequencyPrice').value"
              (numberChange)="updateBillingFrequencyPrice($event)">
            </app-dqn-input-number>

            <dqn-form-field class="w-[20%]">
                <dqn-label>Währung</dqn-label>
                <dqn-dropdown
                  formControlName="billingFrequencyCurrencyCodeId"
                  [required]="!!this.supplierProductForm.get('billingFrequencyPriceGroup').get('billingFrequencyPrice').value">
                    <dqn-option
                      *ngFor="let currencyCode of currencyCodes$ | async"
                      [value]="currencyCode.id">
                        {{ currencyCode.display_name }}
                    </dqn-option>
                </dqn-dropdown>
            </dqn-form-field>

            <dqn-form-field class="w-[20%]">
                <dqn-label>Abrechnungshäufigkeit</dqn-label>
                <dqn-dropdown
                  formControlName="billingFrequencyId"
                  [required]="!!this.supplierProductForm.get('billingFrequencyPriceGroup.billingFrequencyPrice')?.value">
                    <dqn-option
                      *ngFor="let billingFrequency of billingFrequencies$ | async"
                      [value]="billingFrequency.id">
                        {{ billingFrequency.display_name }}
                    </dqn-option>
                </dqn-dropdown>
            </dqn-form-field>
        </div>

        <div class="flex items-center gap-1">
            <dqn-form-field>
                <dqn-label>Status</dqn-label>
                <dqn-dropdown
                  formControlName="statusId"
                  required>
                    <dqn-option
                      *ngFor="let supplierAvailableStatus of supplierAvailableStatuses$| async"
                      [value]="supplierAvailableStatus.status_id">
                        {{ supplierAvailableStatus.status.display_name }}
                    </dqn-option>
                </dqn-dropdown>
            </dqn-form-field>
            <mat-slide-toggle
              formControlName="isPromoted"
              color="primary">Angesagt
            </mat-slide-toggle>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button
      mat-button
      color="primary"
      (click)="cancel()">Abbrechen
    </button>

    <button
      mat-raised-button
      color="primary"
      [disabled]="supplierProductForm.invalid"
      (click)="save()">
        Speichern
    </button>
</mat-dialog-actions>
