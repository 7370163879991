import {ApiResponse} from '@/shared/types/api/api-response';
import {handleError} from '@/shared/utils';
import {createHttpIncludes, getHttpOptionsWithInclude} from '@/shared/utils/functions/http-params';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ID, setLoading} from '@datorama/akita';
import {catchError, map, tap} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {SessionQuery} from '../session.query';

import {Tenant} from './tenant.model';
import {TenantStore} from './tenant.store';

@Injectable({providedIn: 'root'})
export class TenantService {
  static readonly includes = createHttpIncludes([
    'ordersApprovalWorkflow',
    'tenantSettings',
  ]);

  private readonly tenantStore = inject(TenantStore);
  private readonly sessionQuery = inject(SessionQuery);
  private readonly http = inject(HttpClient);
  private readonly snackBar = inject(MatSnackBar);

  getById(id: number) {
    const options = getHttpOptionsWithInclude(TenantService.includes, {
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http
      .get<ApiResponse<Tenant>>(environment.api.baseUrl + 'tenants/' + id, options)
      .pipe(
        setLoading(this.tenantStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.tenantStore)),
        tap((response) => {
          this.tenantStore.add(response['data']);
        }),
        map(response => response.data),
      );
  }

  add(tenant: Tenant) {
    this.tenantStore.add(tenant);
  }

  update(id, tenant: Partial<Tenant>) {
    this.tenantStore.update(id, tenant);
  }

  remove(id: ID) {
    this.tenantStore.remove(id);
  }
}
