import {Injectable} from '@angular/core';
import {TenantAddressesQuery} from '../tenant-addresses/tenant-addresses.query';
import {map} from 'rxjs/operators';
import {combineQueries} from '@datorama/akita';
import {UserAddressesQuery} from '../user-addresses/user-addresses.query';

@Injectable({providedIn: 'root'})
export class AddressesQuery {
  isLoading$ = combineQueries([
    this.tenantShippingAddressesQuery.isLoading$,
    this.userShippingAddressesQuery.isLoading$
  ]).pipe(
    map((isLoadingArray) => isLoadingArray.includes(true)),
  );

  addresses$ = combineQueries([
    this.tenantShippingAddressesQuery.tenantAddresses$,
    this.userShippingAddressesQuery.userAddresses$,
  ]).pipe(
    map(([tenantShippingAddresses, userShippingAddresses]) => [...userShippingAddresses, ...tenantShippingAddresses])
  );

  constructor(
    private userShippingAddressesQuery: UserAddressesQuery,
    private tenantShippingAddressesQuery: TenantAddressesQuery,
  ) {
  }

  getAllShippingAddresses() {
    // TODO: Add type for getting addresses
    const tenantShippingAddresses = this.tenantShippingAddressesQuery.getAll();
    const userShippingAddresses = this.userShippingAddressesQuery.getAll();

    return [...tenantShippingAddresses, ...userShippingAddresses];
  }
}
