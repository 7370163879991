import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogOption} from './enums/confirm-dialog-options.enum';

export interface ConfirmDeleteDialogOutputData {
  selection: ConfirmDialogOption;
}

@Component({
  selector: 'app-confirm-delete-dialog',
  templateUrl: './confirm-delete-dialog.component.html',
  styleUrls: ['./confirm-delete-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDeleteDialogComponent {

  constructor(
    private dialogRef: MatDialogRef<ConfirmDeleteDialogComponent>
  ) {
  }

  cancel(): void {
    this.dialogRef.close({selection: ConfirmDialogOption.declined} as ConfirmDeleteDialogOutputData);
  }

  confirm(): void {
    this.dialogRef.close({selection: ConfirmDialogOption.confirmed} as ConfirmDeleteDialogOutputData);
  }

}
