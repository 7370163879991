import {SessionQuery} from '@/core/session/state/session.query';
import {SuppliersService} from '@/management/supplier/state/suppliers.service';
import {SuppliersStore} from '@/management/supplier/state/suppliers.store';
import {HttpParamOptions} from '@/shared/enums/api/http-param-options';
import {ApiResponse} from '@/shared/types/api/api-response';
import {handleError} from '@/shared/utils';
import {
  createHttpAttributes,
  createHttpIncludes,
  getHttpOptionsWithIncludeAndAttributes
} from '@/shared/utils/functions/http-params';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {setLoading} from '@datorama/akita';
import {forkJoin, Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {ProductFilterPresets} from './enums/product-filter-presets';
import {ProductAttributeTemplatesService} from './product-attribute-templates/product-attribute-templates.service';
import {ProductCategory} from './product-categories/product-category.model';
import {Product} from './product.model';
import {ProductsStore} from './products.store';
import {SupplierProductsService} from './supplier-products/supplier-products.service';
import {SupplierProductsStore} from './supplier-products/supplier-products.store';
import {VariantAttributesService} from './variant-attributes/variant-attributes.service';

@Injectable({providedIn: 'root'})
export class ProductsService {
  static includes = createHttpIncludes([
    'productCategory',
    'supplierProducts',
    'suppliers',
    'productAttributes',
    'productAttributes.productAttributeTemplate.productAttributeType',
    'productAttributes.productAttributeTemplate.quantityUnit',
    'images',
  ]);

  static defaultAttributes = createHttpAttributes([
    'id',
    'tenant_id',
    'name',
    'description',
    'description_long',
    'keywords',
    'ean_gtin',
    'product_category_id',
    'product_category',
    'quantity_unit_id',
    'quantity_unit',
    'scale_unit_id',
    'scale_unit',
    'selling_quantity',
    'basic_quantity',
    'product_attributes',
    'requires_approval',
    'requires_offer',
    'images',
    'is_favorite',
    'is_virtual',
    'parent_id',
    'supplier_product_count',
    'variant_count',
    'preferred_supplier_product_id',
  ]);

  static defaultListAttributes = createHttpAttributes([
    ProductsService.defaultAttributes,
    'price_unit_price_from',
    'price_unit_price_to',
    'price_unit_price_currency_code_id',
    'price_billing_frequency_price_from',
    'price_billing_frequency_price_to',
    'price_billing_frequency_price_currency_code_id',
    'price_billing_frequency_id',
  ]);

  private readonly variantAttributesService = inject(VariantAttributesService);
  private readonly productAttributeTemplatesService = inject(ProductAttributeTemplatesService);
  private readonly suppliersService = inject(SuppliersService);
  private readonly supplierProductsService = inject(SupplierProductsService);
  private readonly productsStore = inject(ProductsStore);
  private readonly supplierProductsStore = inject(SupplierProductsStore);
  private readonly suppliersStore = inject(SuppliersStore);
  private readonly http = inject(HttpClient);
  private readonly snackBar = inject(MatSnackBar);
  private readonly sessionQuery = inject(SessionQuery);

  getByProductCategoryId(productCategoryId: ProductCategory['id']) {
    const options = getHttpOptionsWithIncludeAndAttributes(
      ProductsService.includes,
      ProductsService.defaultListAttributes,
      {
        [HttpParamOptions.FilterPreset]: ProductFilterPresets.Shop,
        'filter[and][product_category_id][eq]': productCategoryId.toString(),
        tenant_id: this.sessionQuery.tenantId.toString(),
      });

    return this.http
      .get<ApiResponse<Product[]>>(environment.api.baseUrl + 'products', options)
      .pipe(
        setLoading(this.productsStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productsStore)),
        tap(({data: products}) => this.productsStore.upsertMany(products)),
      );
  }

  getById(id: Product['id']) {
    const options = getHttpOptionsWithIncludeAndAttributes(
      ProductsService.includes,
      ProductsService.defaultAttributes,
      {
        [HttpParamOptions.FilterPreset]: ProductFilterPresets.Shop,
        tenant_id: this.sessionQuery?.tenantId?.toString(),
      });

    return this.http.get<ApiResponse<Product>>(environment.api.baseUrl + 'products/' + id, options).pipe(
      setLoading(this.productsStore),
      catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productsStore)),
      tap(({data: product}) => {
        const {supplier_products, suppliers} = product;

        if (supplier_products) {
          this.supplierProductsStore.upsertMany(supplier_products);
        }

        if (suppliers) {
          this.suppliersStore.upsertMany(suppliers);
        }

        this.productsStore.upsert(product.id, product);
      })
    );
  }

  getBySearchTermForApprover(searchTerm: string) {
    // Reset store for every search
    this.supplierProductsStore.set([]);

    const options = getHttpOptionsWithIncludeAndAttributes(
      ProductsService.includes,
      ProductsService.defaultListAttributes,
      {
        [HttpParamOptions.FilterPreset]: ProductFilterPresets.ProductCategoryApprover,
        [HttpParamOptions.FilterPresetData + '[searchTerm]']: searchTerm,
        tenant_id: this.sessionQuery.tenantId.toString(),
      }
    );

    return this.http
      .get<ApiResponse<Product[]>>(environment.api.baseUrl + 'products', options)
      .pipe(
        setLoading(this.productsStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productsStore)),
        tap(({data: products}) => {
          this.productsStore.set(products);
        })
      );
  }

  getBySearchTerm(searchTerm: string) {
    // Reset store for every search
    this.supplierProductsStore.set([]);

    const options = getHttpOptionsWithIncludeAndAttributes(
      ProductsService.includes,
      ProductsService.defaultListAttributes,
      {
        [HttpParamOptions.FilterPreset]: ProductFilterPresets.Shop,
        [HttpParamOptions.FilterPresetData + '[searchTerm]']: searchTerm,
        tenant_id: this.sessionQuery.tenantId.toString(),
      }
    );

    return this.http
      .get<ApiResponse<Product[]>>(environment.api.baseUrl + 'products', options)
      .pipe(
        setLoading(this.productsStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productsStore)),
        tap(({data: products}) => {
          this.productsStore.set(products);
        })
      );
  }

  getFavorites() {
    const options = getHttpOptionsWithIncludeAndAttributes(
      ProductsService.includes,
      ProductsService.defaultAttributes,
      {
        [HttpParamOptions.FilterPreset]: ProductFilterPresets.Shop,
        tenant_id: this.sessionQuery.tenantId.toString(),
      },
    );

    return this.http
      .get<ApiResponse<Product[]>>(environment.api.baseUrl + 'products/custom/favorites', options)
      .pipe(
        setLoading(this.productsStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productsStore)),
        tap(({data: products}) => {
          this.productsStore.upsertMany(products);
        })
      );
  }

  getVariantsForProduct(productId: Product['id']) {
    const options = getHttpOptionsWithIncludeAndAttributes(
      ProductsService.includes,
      ProductsService.defaultListAttributes,
      {
        [HttpParamOptions.FilterPreset]: ProductFilterPresets.Shop,
        tenant_id: this.sessionQuery?.tenantId?.toString(),
      }
    );

    return this.http.get<ApiResponse<Product[]>>(`${environment.api.baseUrl}products/${productId}/variants`, options).pipe(
      setLoading(this.productsStore),
      catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productsStore)),
      tap(({data: products}) => this.productsStore.upsertMany(products)),
    );
  }

  updateFavoriteStatusForProduct(productId: Product['id'], isFavorite: Product['is_favorite']) {
    const body = {
      product_id: productId.toString(),
    };

    const options = getHttpOptionsWithIncludeAndAttributes(
      ProductsService.includes,
      ProductsService.defaultAttributes,
      {
        tenant_id: this.sessionQuery.tenantId.toString(),
      }
    );

    // Add or Remove product to/from user favorites
    const requestEndpoint = environment.api.baseUrl + 'products/';
    const request = !isFavorite
      ? this.http.delete<ApiResponse<Product>>(requestEndpoint + productId + '/custom/favorites', options)
      : this.http.post<ApiResponse<Product>>(requestEndpoint + 'custom/favorites', body, options);

    return request.pipe(
      setLoading(this.productsStore),
      catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productsStore)),
      tap(() => {
        this.productsStore.update(productId, {is_favorite: isFavorite});
      }),
    );
  }

  /** Provide a collection of requests required for selecting a supplier product for a product. */
  getRequestsForSupplierProductSelectionDialog({
                                                 id,
                                                 supplier_product_count,
                                                 variant_count,
                                                 is_virtual
                                               }: Product) {
    const requests: Observable<unknown>[] = [];

    if (is_virtual && variant_count > 0) {
      // Fetch all variants for product
      requests.push(
        this.getVariantsForProduct(id),
        this.variantAttributesService.getForProduct(id),
        this.supplierProductsService.getForProduct(id),
        this.productAttributeTemplatesService.get(),
      );
    } else if (!is_virtual && supplier_product_count >= 1) {
      // Fetch product and fill supplier products based on include
      requests.push(
        this.supplierProductsService.getForProduct(id),
        this.suppliersService.get(),
      );
    }

    return forkJoin(requests);
  }

  setActiveProduct(id: Product['id']) {
    this.productsStore.setActive(id);
  }
}
