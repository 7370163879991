import {
  ProductCategoryApproversQuery
} from '@/shared/state/product-category-approvers/product-category-approvers.query';
import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {DqnSingleOption} from '@dqn/components/combobox';
import {map} from 'rxjs/operators';
import {ProductCategoriesState, ProductCategoriesStore} from './product-categories.store';
import {ProductCategory} from './product-category.model';

@Injectable({providedIn: 'root'})
export class ProductCategoriesQuery extends QueryEntity<ProductCategoriesState> {
  productCategories$ = this.selectAll();

  productCategoriesAsDqnOptions$ = this.productCategories$.pipe(
    map(productCategories => productCategories.map(({id, display_name}) => ({
        value: id,
        title: display_name,
      } as DqnSingleOption))
    ),
  );

  constructor(
    protected store: ProductCategoriesStore,
    private productCategoryApproversQuery: ProductCategoryApproversQuery
  ) {
    super(store);
  }

  selectProductCategory(id: number) {
    return this.selectEntity(id);
  }

  selectProductCategoryApproversOfProductCategoryById(id: ProductCategory['id']) {
    return this.productCategoryApproversQuery.selectAll({
      filterBy: ({product_category_id}) => product_category_id == id
    });
  }
}
