import {Component, Input, OnInit} from '@angular/core';
import {MatCardAlertMode} from '../../enums/mat-card-alert-mode.enum';

@Component({
  selector: 'app-mat-card-alert',
  templateUrl: './mat-card-alert.component.html',
  styleUrls: ['./mat-card-alert.component.scss']
})
export class MatCardAlertComponent implements OnInit {
  @Input() icon: string;
  @Input() text: string;
  @Input() mode: MatCardAlertMode;
  @Input() cssClasses: string;
  cssDefaultClasses = 'mat-elevation-z0';
  allCssClasses: string[] = [];
  color = '';
  test = 'red';

  ngOnInit(): void {
    if(this.cssClasses) {
      this.cssDefaultClasses = this.cssDefaultClasses + ' ' + this.cssClasses;
    }
    this.allCssClasses.push(this.cssDefaultClasses);
    switch (this.mode) {
      case MatCardAlertMode.basic: this.allCssClasses.push('mat-card-basic');
        break;
      case MatCardAlertMode.primary: this.allCssClasses.push('mat-card-primary');
        break;
      case MatCardAlertMode.accent: this.allCssClasses.push('mat-card-accent');
        break;
      case MatCardAlertMode.success: this.allCssClasses.push('mat-card-success');
        break;
      case MatCardAlertMode.danger: this.allCssClasses.push('mat-card-danger');
        break;
      case MatCardAlertMode.warn: this.allCssClasses.push('mat-card-warn');
        break;
    }
  }
}
