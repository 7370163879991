import {Pipe, PipeTransform} from '@angular/core';
import {StatusNames} from '../state/status/enums/status-names.enum';
import {getStatusDisplayNameForName} from '../state/status/helpers/functions/get-status-display-name-for-name';

@Pipe({
  name: 'statusDisplayNameForName'
})
export class StatusDisplayNameForNamePipe implements PipeTransform {
  transform(statusName: StatusNames) {
    return getStatusDisplayNameForName(statusName);
  }
}
