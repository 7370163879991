import {Injectable} from '@angular/core';
import {ID} from '@datorama/akita';
import {UserAddressesStore} from '../user-addresses/user-addresses.store';
import {UserAddress} from '../user-addresses/user-address.model';
import {Address} from './address.model';
import {UserAddressesService} from '../user-addresses/user-addresses.service';
import {of} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AddressesService {
  constructor(
    private userShippingAddressesStore: UserAddressesStore,
    private userShippingAddressesService: UserAddressesService,
  ) {
  }

  getAddresses() {
    return this.userShippingAddressesService.getAddressesForCurrentUser();
  }

  deleteOnDb(address: Address) {
    switch (address.type) {
      case 'user':
        return this.userShippingAddressesService.deleteOnDbForCurrentUser(address.id);
      default:
        return of(null);
    }
  }

  add(tenantShippingAddress: UserAddress) {
    this.userShippingAddressesStore.add(tenantShippingAddress);
  }

  upsert(id, tenantShippingAddress: Partial<UserAddress>) {
    this.userShippingAddressesStore.upsert(id, tenantShippingAddress);
  }

  remove(id: ID) {
    this.userShippingAddressesStore.remove(id);
  }
}
