import {SleekplanQuery} from '@/core/sleekplan/state/sleekplan.query';
import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  readonly sleekplanQuery = inject(SleekplanQuery);

  currentYear = moment().year();
}
