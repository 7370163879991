import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {map} from 'rxjs/operators';
import {ApproversQuery} from '../approvers/approvers.query';
import {CostCenter} from './cost-center.model';
import {CostCentersState, CostCentersStore} from './cost-centers.store';
import {convertCostCenterToDqnOption} from './helpers/convert-cost-center-to-dqn-option';

@Injectable({providedIn: 'root'})
export class CostCentersQuery extends QueryEntity<CostCentersState> {
  isLoading$ = this.selectLoading();

  costCenters$ = this.selectAll();

  costCentersAsDqnOptions$ = this.costCenters$.pipe(
    map(costCenters => costCenters.map(convertCostCenterToDqnOption)),
  );

  constructor(
    protected store: CostCentersStore,
    protected approversQuery: ApproversQuery) {
    super(store);
  }

  get costCenters() {
    return this.getAll();
  }

  selectCostCenter(id: CostCenter['id']) {
    return this.selectEntity(id);
  }

  selectCostCentersByIds(ids: CostCenter['id'][]) {
    return this.selectAll().pipe(
      map(costCenters => {
        return costCenters.filter(costCenter => ids.includes(costCenter.id));
      })
    )
  }

  selectApproversOfCostCenterById(id: number) {
    return this.approversQuery.selectAll({
      filterBy: ({cost_center_id}) => cost_center_id === id
    });
  }

  getApproversOfCostCenterById(id: number) {
    return this.approversQuery.getAll({
      filterBy: ({cost_center_id}) => cost_center_id === id
    });
  }
}
