import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'priceNetToGross'
})
export class PriceNetToGrossPipe implements PipeTransform {
  /**
   * Transforms net price to gross price and rounds to 2 decimal places.
   *
   * @param netPrice Net price
   * @param args
   */
  transform(netPrice: number): number {
    return netPrice ? Math.round(netPrice * 119) / 100 : 0;
  }
}
