import {OrderItemsUpdatedData} from '../types/order-items-updated-data';

export const isOrderItemsUpdatedData = (data: unknown): data is OrderItemsUpdatedData => {
  if (typeof data !== 'object' || data === null) {
    return false;
  }

  const orderItemsUpdatedData = data as OrderItemsUpdatedData;
  const hasAddedProducts = orderItemsUpdatedData.added_order_items && Array.isArray(orderItemsUpdatedData.added_order_items);
  const hasUpdatedProducts = orderItemsUpdatedData.updated_order_items && Array.isArray(orderItemsUpdatedData.updated_order_items);

  return hasAddedProducts || hasUpdatedProducts;
}
