import {createPermission} from '@/core/session/state/permissions/permission.model';
import {PermissionsQuery} from '@/core/session/state/permissions/permissions.query';
import {SessionQuery} from '@/core/session/state/session.query';
import {ManagementRouteNames} from '@/management/constants/routes.constants';
import {ProductRouteNames} from '@/management/product/constants/routes.constants';
import {ProductImage} from '@/management/product/models/product-image';
import {isProductWithVariants} from '@/management/product/state/helpers/functions/is-product-with-variants';
import {
  ProductAttributeTemplatesQuery
} from '@/management/product/state/product-attribute-templates/product-attribute-templates.query';
import {
  ProductAttributeTemplatesService
} from '@/management/product/state/product-attribute-templates/product-attribute-templates.service';
import {ProductCategoriesQuery} from '@/management/product/state/product-categories/product-categories.query';
import {ProductCategoriesService} from '@/management/product/state/product-categories/product-categories.service';
import {createProduct, Product} from '@/management/product/state/product.model';
import {ProductsQuery} from '@/management/product/state/products.query';
import {ProductsService} from '@/management/product/state/products.service';
import {QuantityUnitsQuery} from '@/management/product/state/quantity-units/quantity-units.query';
import {QuantityUnitsService} from '@/management/product/state/quantity-units/quantity-units.service';
import {VariantAttributesService} from '@/management/product/state/variant-attributes/variant-attributes.service';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {filterNilValue} from '@datorama/akita';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {combineLatest, forkJoin, of} from 'rxjs';
import {distinctUntilChanged, filter, map, switchMap, tap} from 'rxjs/operators';
import {iMaskDecimalInput} from '../../constants/imasks.constants';
import {FormModeDirective, FormModeHostDirective} from '../../directives/form-mode.directive';
import {FormMode} from '../../enums/form-mode.enum';
import {
  ProductAttributesManagementQuery,
  provideProductAttributesManagement
} from './components/product-attributes-management/product-attributes-management.state';
import {
  provideSupplierProductManagement,
  SupplierProductManagementQuery
} from './components/supplier-product-management/supplier-product-management.state';
import {
  provideVariantAttributesManagement,
  VariantAttributesManagementQuery
} from './components/variant-attributes-management/variant-attributes-management.state';
import {
  provideVariantManagement,
  VariantManagementQuery
} from './components/variant-management/variant-management.state';
import {ProductForm} from './types/product-form';

@UntilDestroy()
@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    // Product Attributes Management
    provideProductAttributesManagement(),
    // Supplier Products Management
    provideSupplierProductManagement(),
    // Variant Management
    provideVariantManagement(),
    provideVariantAttributesManagement(),
  ],
  hostDirectives: [
    FormModeHostDirective,
  ]
})
export class ProductFormComponent implements OnInit {
  readonly formMode = inject(FormModeDirective, {self: true});
  readonly productsQuery = inject(ProductsQuery);
  readonly productCategoriesQuery = inject(ProductCategoriesQuery);
  readonly productAttributeTemplatesQuery = inject(ProductAttributeTemplatesQuery);

  private readonly formBuilder = inject(UntypedFormBuilder);
  private readonly cd = inject(ChangeDetectorRef);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly productCategoriesService = inject(ProductCategoriesService);
  private readonly sessionQuery = inject(SessionQuery);
  private readonly quantityUnitsService = inject(QuantityUnitsService);
  private readonly quantityUnitsQuery = inject(QuantityUnitsQuery);
  private readonly permissionsQuery = inject(PermissionsQuery);
  private readonly productsService = inject(ProductsService);
  private readonly productAttributesManagementQuery = inject(ProductAttributesManagementQuery);
  private readonly supplierProductManagementQuery = inject(SupplierProductManagementQuery);
  private readonly productAttributeTemplatesService = inject(ProductAttributeTemplatesService);
  private readonly variantAttributesManagementQuery = inject(VariantAttributesManagementQuery);
  private readonly variantAttributesService = inject(VariantAttributesService);
  private readonly variantManagementQuery = inject(VariantManagementQuery);

  @Input() set disabled(disabled: boolean) {
    if (!this.productForm) {
      return;
    }

    if (disabled) {
      this.productForm.disable();
    } else {
      this.productForm.enable();
    }
  }

  @Output() submitForm = new EventEmitter<ProductForm>();

  get isSubmitButtonEnabled() {
    return this.productForm.valid;
  }

  get isVirtualProduct() {
    return this.productForm.controls.isVirtual.value === true;
  }

  FormMode = FormMode;

  quantityUnits$ = this.quantityUnitsQuery.quantityUnits$;
  activeProduct$ = this.productsQuery.activeProduct$;

  productEditRouterLink$ = this.activeProduct$.pipe(
    filterNilValue(),
    map(({id}) => ([
      '/',
      ManagementRouteNames.MANAGEMENT,
      ProductRouteNames.PRODUCT,
      id.toString(),
      'edit'
    ])),
  );

  tooltipForIsVirtual$ = combineLatest([
    this.supplierProductManagementQuery.hasActiveOrInitialSupplierProducts$,
    this.variantManagementQuery.hasActiveOrInitialVariants$,
  ]).pipe(
    map(([hasSupplierProducts, hasVariants]) => {
      if (hasSupplierProducts) {
        return 'Dieser Artikel hat oder hatte Bezugsquellen. Deshalb kann der Artikel nicht virtuell sein.';
      } else if (hasVariants) {
        return 'Dieser Artikel hat oder hatte Varianten. Deshalb kann der Artikel nur virtuell sein.';
      }

      return 'Ein virtueller Artikel kann genutzt werden, um Varianten zu verwenden.';
    }),
  );

  productForm: UntypedFormGroup;
  productImage: ProductImage | null = null;

  productListRouterLink = [
    '/',
    ManagementRouteNames.MANAGEMENT,
    ProductRouteNames.PRODUCT,
    ProductRouteNames.LIST,
  ];

  readonly iMaskDecimalInput = iMaskDecimalInput;

  ngOnInit() {
    this.initializeForm();

    this.productCategoriesService.get().subscribe();
    this.quantityUnitsService.get().subscribe();
    this.productAttributeTemplatesService.get().subscribe();

    // Fetch product based on productId in route parameters
    this.activatedRoute.paramMap.pipe(
      map(paramMap => +paramMap.get('productId')),
      tap(productId => this.productsService.setActiveProduct(productId || null)),
      filter(productId => productId > 0),
      switchMap(productId => this.productsService.getById(productId)),
      tap(({data: product}) => {
        // Product information
        this.fillFormWithProductInformation(product);
      }),
      // Product is either virtual product or normal product
      switchMap(response => this.getDetailsForProduct(response.data)),
      untilDestroyed(this),
    ).subscribe();

    this.initializeSyncingWithFormControls();
  }

  updateProductCategoryIdControl(productCategoryId: number) {
    this.productForm.get('productCategoryId').setValue(productCategoryId);
  }

  updateProductImage(images: FileList) {
    const uploadedImage = images[0];

    if (!uploadedImage) {
      return;
    }

    // Update current uploaded image with the new file and its base64 representation
    const reader = new FileReader();
    reader.readAsDataURL(uploadedImage);
    reader.onload = () => {
      this.productImage = {file: uploadedImage, asBase64: reader.result as string};
      this.cd.markForCheck();
    };
  }

  submit() {
    if (!this.productForm.valid) {
      return;
    }

    const product = createProduct({
      id: this.formMode.isEdit ? this.productsQuery.getActive()?.id : null,
    });

    // HINT: Permission check should be handled in service
    if (this.isAttributeAllowed('tenantId')) {
      product.tenant_id = this.sessionQuery.tenantId;
    }
    if (this.isAttributeAllowed('productCategoryId')) {
      product.product_category_id = this.productForm.get(
        'productCategoryId'
      ).value;
    }
    if (this.isAttributeAllowed('name')) {
      product.name = this.productForm.get('name').value;
    }
    if (this.isAttributeAllowed('description')) {
      product.description = this.productForm.get('description').value;
    }
    if (this.isAttributeAllowed('descriptionLong')) {
      product.description_long = this.productForm.get('descriptionLong').value;
    }
    if (this.isAttributeAllowed('eanGtin')) {
      product.ean_gtin = this.productForm.get('eanGtin').value;
    }
    if (this.isAttributeAllowed('keywords')) {
      product.keywords = this.productForm.get('keywords').value;
    }
    if (this.isAttributeAllowed('quantityUnitId')) {
      product.quantity_unit_id = this.productForm.get('quantityUnitId').value;
    }
    if (this.isAttributeAllowed('scaleUnitId')) {
      product.scale_unit_id = this.productForm.get('scaleUnitId').value;
    }
    if (this.isAttributeAllowed('basicQuantity')) {
      const basicQuantityValue = this.productForm.get('basicQuantity').value;
      product.basic_quantity = basicQuantityValue ? Number(basicQuantityValue) : null;
    }
    if (this.isAttributeAllowed('sellingQuantity')) {
      const sellingQuantityValue = this.productForm.get('sellingQuantity').value;
      product.selling_quantity = sellingQuantityValue ? Number(sellingQuantityValue) : null;
    }
    if (this.isAttributeAllowed('requiresApproval')) {
      product.requires_approval = this.productForm.get('requiresApproval').value;
    }
    if (this.isAttributeAllowed('isVirtual')) {
      product.is_virtual = this.productForm.get('isVirtual').value;
    }

    this.submitForm.emit({
      product,
      productImage: this.productImage,
      productAttributes: {
        added: this.productAttributesManagementQuery.getNewProductAttributes(),
        removed: this.productAttributesManagementQuery.getDeletedProductAttributes(),
        updated: this.productAttributesManagementQuery.getUpdatedProductAttributes(),
      },
      variantConfiguration: {
        added: this.variantAttributesManagementQuery.getNewVariantAttributes(),
        updated: this.variantAttributesManagementQuery.getUpdatedProductAttributes(),
      },
      variants: {
        added: this.variantManagementQuery.getNewVariants(),
        removed: this.variantManagementQuery.getDeletedVariants(),
      },
      supplierProducts: {
        added: this.supplierProductManagementQuery.getNewSupplierProducts(),
        removed: this.supplierProductManagementQuery.getDeletedSupplierProducts(),
        updated: this.supplierProductManagementQuery.getUpdatedSupplierProducts(),
      }
    });
  }

  private syncFormControlsWithPermissions(formControlsToIgnore: string[] = []) {
    if (this.formMode.isReadonly) {
      return;
    }

    for (const key of Object.keys(this.productForm.controls)) {
      if (formControlsToIgnore.includes(key)) {
        continue;
      }

      this.syncFormControlWithPermissions(key);
    }
  }

  private syncFormControlsForVariant() {
    if (this.formMode.isReadonly) {
      return;
    }

    // Only enable name field and contents fields for variants
    this.productForm.disable();
    this.syncFormControlWithPermissions('name');
    this.syncFormControlWithPermissions('sellingQuantity');
    this.syncFormControlWithPermissions('basicQuantity');
    this.syncFormControlWithPermissions('scaleUnitId');
    this.syncFormControlWithPermissions('quantityUnitId');
  }

  private initializeForm() {
    this.productForm = this.formBuilder.group({
      productCategoryId: [{value: null, disabled: true}, [Validators.required]],
      name: [{value: '', disabled: true}, [Validators.required]],
      description: [{value: '', disabled: true}, [Validators.maxLength(1000)]],
      descriptionLong: [{
        value: '',
        disabled: true
      }, [Validators.maxLength(1200)]],
      eanGtin: [{value: '', disabled: true}],
      keywords: [{value: '', disabled: true}],
      quantityUnitId: [{value: '', disabled: true}, [Validators.required]],
      scaleUnitId: [{value: '', disabled: true}],
      sellingQuantity: [{value: '', disabled: true}],
      basicQuantity: [{value: '', disabled: true}],
      requiresApproval: [{value: false, disabled: true}],
      isVirtual: [{value: false, disabled: true}]
    });

    // Override controls without required validator for proposal
    if (this.formMode.isProposal) {
      this.productForm.setControl(
        'productCategoryId',
        this.formBuilder.control(null)
      );
    }

    this.productForm.disable();
  }

  private fillFormWithProductInformation(product: Product) {
    this.productForm.patchValue({
      productCategoryId: product?.product_category_id,
      name: product?.name,
      description: product?.description,
      descriptionLong: product?.description_long,
      eanGtin: product?.ean_gtin,
      keywords: product?.keywords,
      quantityUnitId: product?.quantity_unit_id,
      scaleUnitId: product?.scale_unit_id,
      basicQuantity: product?.basic_quantity,
      sellingQuantity: product?.selling_quantity,
      requiresApproval: product?.requires_approval,
      isVirtual: product?.is_virtual,
    });
  }

  /**
   * Will check if a permission exists for the specified attribute.
   * Depending on the form mode a different action for the permission will be used to check.
   */
  private isAttributeAllowed(attribute: string) {
    return this.permissionsQuery.getHasOneOrMorePermissionToInStore(
      createPermission({
        model: 'product',
        attribute,
        action: this.formMode.isEdit ? 'update' : 'create',
      })
    );
  }

  private syncFormControlWithPermissions(controlName: string) {
    const formControl = this.productForm.get(controlName);

    if (!formControl) {
      return;
    }

    if (this.isAttributeAllowed(controlName)) {
      formControl.enable();
    } else {
      formControl.disable();
    }
  }

  private getDetailsForProduct(product: Product) {
    // Check if product is variant (= parentId is filled)
    if (product.parent_id) {
      return this.productsService.getById(product.parent_id).pipe(
        // If initial product had a parent product, we already know the parent will be a virtual product
        switchMap(({data: virtualProduct}) => forkJoin([
            this.variantAttributesService.getForProduct(virtualProduct.id),
          ]).pipe(
            map(() => product),
          ),
        ),
      );
    } else if (isProductWithVariants(product)) {
      return forkJoin({
        variants: this.productsService.getVariantsForProduct(product.id),
        variantAttributes: this.variantAttributesService.getForProduct(product.id),
      }).pipe(
        map(() => product),
      )
    }

    return of(product);
  }

  private initializeSyncingWithFormControls() {
    // User can't change is virtual at all if product is a variant or there are active variants or supplier products
    const canChangeIsVirtual$ = combineLatest([
      this.productsQuery.activeProduct$.pipe(map(product => !!product?.parent_id)),
      this.supplierProductManagementQuery.hasActiveOrInitialSupplierProducts$,
      this.variantManagementQuery.hasActiveOrInitialVariants$,
    ]).pipe(
      map(([hasParent, hasSupplierProducts, hasVariants]) => !hasParent && !hasSupplierProducts && !hasVariants),
      distinctUntilChanged(),
    );

    // Update form controls based on permissions and attributes related to variants
    combineLatest([
      this.permissionsQuery.permissions$.pipe(filterNilValue()),
      this.productsQuery.activeProduct$,
      canChangeIsVirtual$,
    ]).pipe(
      tap(([, product, canChangeIsVirtual]) => {
        if (product?.parent_id) {
          this.syncFormControlsForVariant();
        } else if (!canChangeIsVirtual) {
          // isVirtual will always be disabled if it can't be changed
          this.productForm.get('isVirtual').disable();
          this.syncFormControlsWithPermissions(['isVirtual']);
        } else {
          this.syncFormControlsWithPermissions();
        }
      }),
      untilDestroyed(this),
    ).subscribe();
  }
}
