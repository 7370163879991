import {DqnSingleOption} from '@dqn/components/combobox';
import {OperatorFunction} from 'rxjs';
import {map} from 'rxjs/operators';
import {MicrosoftGraphUser} from '../microsoft-graph-user.model';

const mapGraphUsersToDqnOptionsSync = (graphUsers: MicrosoftGraphUser[]) => graphUsers.map(graphUser => ({
  value: graphUser.id,
  title: graphUser.display_name,
  attributes: [
    graphUser.user_principal_name,
  ],
} as DqnSingleOption));


export const mapGraphUsersToDqnOptions = (): OperatorFunction<MicrosoftGraphUser[], DqnSingleOption[]> => map(e => mapGraphUsersToDqnOptionsSync(e));
