import {Address} from '@/shop/shopping-cart/state/address/address.model';
import {AddressesService} from '@/shop/shopping-cart/state/address/addresses.service';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {
  ConfirmDeleteDialogComponent,
  ConfirmDeleteDialogOutputData
} from '../../../confirm-delete-dialog/confirm-delete-dialog.component';
import {ConfirmDialogOption} from '../../../confirm-delete-dialog/enums/confirm-dialog-options.enum';

@Component({
  selector: 'app-address-list-item',
  templateUrl: './address-list-item.component.html',
  styleUrls: ['./address-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressListItemComponent {
  @Input() address: Address;
  @Input() isSelected: boolean | null;

  @Output() selectAddress = new EventEmitter<void>();

  constructor(private addressesService: AddressesService, private dialog: MatDialog) {
  }

  emitSelectAddress() {
    this.selectAddress.emit();
  }

  deleteAddress() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '600px';
    this.dialog
      .open<ConfirmDeleteDialogComponent, ConfirmDeleteDialogOutputData>(
        ConfirmDeleteDialogComponent,
        dialogConfig
      )
      .afterClosed()
      .subscribe(result => {
        if (result?.selection === ConfirmDialogOption.confirmed) {
          this.addressesService.deleteOnDb(this.address).subscribe();
        }
      });
  }
}
