{
  "address": "Adresse",
  "age": "Alter",
  "available": "verfügbar",
  "city": "Stadt",
  "content": "Inhalt",
  "country": "Land",
  "current_password": "Derzeitiges Passwort",
  "date": "Datum",
  "day": "Tag",
  "description": "Beschreibung",
  "email": "E-Mail Adresse",
  "excerpt": "Auszug",
  "first_name": "Vorname",
  "gender": "Geschlecht",
  "hour": "Stunde",
  "last_name": "Nachname",
  "minute": "Minute",
  "mobile": "Handynummer",
  "month": "Monat",
  "name": "Name",
  "password": "Passwort",
  "password_confirmation": "Passwort Bestätigung",
  "phone": "Telefonnummer",
  "second": "Sekunde",
  "sex": "Geschlecht",
  "size": "Größe",
  "time": "Uhrzeit",
  "title": "Titel",
  "username": "Benutzername",
  "year": "Jahr",
  "expires": "Läuft ab",
  "external_client_id": "Externe Kundenkennung",
  "key": "Schlüssel",
  "tenant_id": "Mandanten ID",
  "level": "Level",
  "approved": "Genehmigt",
  "id": "ID",
  "object_id": "Objekt ID",
  "external_id": "Externe ID",
  "external_tenant_id": "Externe Mandanten ID",
  "street": "Straße",
  "house_number": "Hausnummer",
  "additional_address_information": "Adresszusatz",
  "postal_code": "Postleitzahl",
  "navigation_url": "Navigationsurl",
  "country_code_id": "Ländercode",
  "default_role_id": "Standard Rollen ID",
  "display_name": "Anzeigename",
  "value": "Wert",
  "image": "Bild",
  "status_id": "Status ID",
  "unit_price": "Einzelpreis",
  "currency_code_id": "Währungscode ID",
  "product_category_id": "Produktkategorie",
  "supplier_id": "Lieferanten ID",
  "quantity_unit_id": "Mengeneinheit ID",
  "description_long": "Langbeschreibung ",
  "ean_gtin": "EAN/GTIN",
  "supplier_order_number": "Artikelnummer",
  "keywords": "Keywords",
  "requires_approval": "Benötigt Genehmigung",
  "billing_frequency_price": "Abrechnungspreis",
  "billing_frequency_currency_code_id": "Abrechnungspreis Währungscode ID",
  "billing_frequency_id": "Abrechnungsintervall",
  "legal_entity": "Juristische Person",
  "cost_center_number": "Kostenstellennummer",
  "approver_level_1_object_id": "Genehmiger Level 1 Object ID",
  "approver_level_2_object_id": "Genehmiger Level 2 Object ID",
  "allowance_for_unit_price": "Freibetrag für Einzelpreis",
  "allowance_for_unit_price_currency_code_id": "Freibetrag für Einzelpreis Währung",
  "allowance_for_billing_frequency_price": "Freibetrag für Abrechnungspreis",
  "allowance_for_billing_frequency_price_currency_code_id": "Freibetrag für Abrechnungspreis Währung",
  "allowance_for_billing_frequency_price_billing_frequency_id": "Abrechnungsintervall",
  "allow_unit_price": "Freibetrag für Einzelpreis",
  "allow_unit_price_currency_code_id": "Freibetrag für Einzelpreis Währung",
  "allow_freq_price": "Freibetrag für Abrechnungspreis",
  "allow_freq_price_currency_code_id": "Freibetrag für Abrechnungspreis Währung",
  "allow_freq_price_billing_frequency_id": "Abrechnungsintervall",
  "allow_interv_billing_frequency_id": "Abrechnungsintervall",
  "allow_interv_unit_price": "Freibetrag für Einzelpreis",
  "allow_interv_unit_price_currency_code_id": "Freibetrag für Einzelpreis Währung",
  "allow_interv_freq_price": "Freibetrag für Abrechnungspreis",
  "allow_interv_freq_price_currency_code_id": "Freibetrag für Abrechnungspreis Währung",
  "allow_interv_user_billing_frequency_id": "Abrechnungsintervall",
  "allow_interv_user_unit_price": "Freibetrag für Einzelpreis",
  "allow_interv_user_unit_price_currency_code_id": "Freibetrag für Einzelpreis Währung",
  "allow_interv_user_freq_price": "Freibetrag für Abrechnungspreis",
  "allow_interv_user_freq_price_currency_code_id": "Freibetrag für Abrechnungspreis Währung",
  "alternatives": "Alternativen",
  "include": "Einschließung",
  "cost_center_id": "Kostenstelle ID",
  "order_items": "Bestellitems",
  "supplier_product_id": "Produkt ID",
  "quantity": "Menge",
  "filter": "Filter",
  "scope": "Geltungsbereich",
  "confirmed": "Bestätigt",
  "file": "Datei",
  "created": "Erstellt",
  "offer_unit_price": "Angebot Einzelpreis",
  "offer_unit_price_currency_code_id": "Angebot Einzelpreis Währung",
  "offer_billing_frequency_price": "Angebot Abrechnungspreis",
  "offer_billing_frequency_currency_code_id": "Angebot Abrechnungspreis Währung",
  "offer_billing_frequency_id": "Angebot Abrechnungspreis Abrechnungsintervall",
  "product_attribute_template_id": "Produktattribut Vorlage ID",
  "product_attribute_type_id": "Produktattribut Typ ID",
  "mat_icon": "Material Icon",
  "parent_id": "Übergeordnete ID",
  "priority": "Priorität",
  "abbreviation": "Abkürzung",
  "display_abbreviation": "Anzeige Abkürzung",
  "ext_id": "Externe ID",
  "name_2": "Name 2",
  "account_number": "Kundennummer",
  "language_code_id": "Sprachcode ID",
  "gln": "GLN",
  "homepage": "Homepage"
}
