import {DqnSingleOption} from '@dqn/components/combobox';
import {CostCenter} from '../cost-center.model';

export const convertCostCenterToDqnOption = (costCenter: CostCenter) => ({
  value: costCenter.id,
  title: costCenter.name,
  attributes: [
    costCenter.cost_center_number,
  ],
} satisfies DqnSingleOption);
