import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {map} from 'rxjs/operators';
import {mapGraphUsersToDqnOptions} from './helpers/map-graph-users-to-dqn-options';
import {MicrosoftGraphUser} from './microsoft-graph-user.model';
import {MicrosoftGraphUsersState, MicrosoftGraphUsersStore} from './microsoft-graph-users.store';

@Injectable({providedIn: 'root'})
export class MicrosoftGraphUsersQuery extends QueryEntity<MicrosoftGraphUsersState> {
  isLoading$ = this.selectLoading();

  microsoftGraphUsers$ = this.selectAll();

  microsoftGraphUsersWithEmail$ = this.microsoftGraphUsers$.pipe(
    map(microsoftGraphUsers => microsoftGraphUsers.filter(({mail}) => !!mail)),
  );

  microsoftGraphUsersForTenantAsDqnOption$ = this.microsoftGraphUsers$.pipe(
    mapGraphUsersToDqnOptions(),
  );

  constructor(protected store: MicrosoftGraphUsersStore) {
    super(store);
  }

  getMicrosoftGraphUserDisplayNameById(id: number): string {
    return this.getEntity(id)?.display_name;
  }

  getMicrosoftGraphUserById(id: MicrosoftGraphUser['id']) {
    return this.getEntity(id);
  }
}
