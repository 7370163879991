import {Address} from '@/shop/shopping-cart/state/address/address.model';
import {AddressesQuery} from '@/shop/shopping-cart/state/address/addresses.query';
import {AddressesService} from '@/shop/shopping-cart/state/address/addresses.service';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {filterNilValue} from '@datorama/akita';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {map, shareReplay, tap} from 'rxjs/operators';
import {CountryCode} from '../../state/country-codes/country-code.model';
import {CountryCodesQuery} from '../../state/country-codes/country-codes.query';
import {CountryCodesService} from '../../state/country-codes/country-codes.service';
import {getMatDialogConfig} from '../../utils/functions/mat-dialog';
import {AddressSelectionDialogComponent} from './dialogs/address-selection-dialog/address-selection-dialog.component';
import {
  AddressSelectionDialogData
} from './dialogs/address-selection-dialog/models/address-selection-dialog-data.model';
import {
  AddressSelectionDialogOutputData
} from './dialogs/address-selection-dialog/models/address-selection-dialog-output-data.model';
import {AddressType} from './enums/address-type.enum';
import {getAddressPreviewList} from './helpers/functions/get-address-preview-list';
import {AddressForm} from './models/address-form.model';

@UntilDestroy()
@Component({
  selector: 'app-address-selection',
  templateUrl: './address-selection.component.html',
  styleUrls: ['./address-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressSelectionComponent implements OnInit {
  static readonly previewAmount = 3;
  @Input() addressForm: AbstractControl;
  @Input() addressType: AddressType = AddressType.Address;
  @Input() loadAddresses = true;

  @Output() addressSelect = new EventEmitter<AddressForm>();

  countryCodes$: Observable<CountryCode[]>;
  addresses$: Observable<Address[]>;
  addressesPreview$: Observable<Address[]>;
  selectedAddress$: Observable<Address | null>;
  useNewAddress$: Observable<boolean>;
  hasAdditionalShippingAddresses$: Observable<boolean>;
  isAddressesLoading$: Observable<boolean>;

  private selectedAddressSubject: BehaviorSubject<Address | null>;

  get addressFormAsFormGroup() {
    return this.addressForm as UntypedFormGroup;
  }

  get addressCompanyOrganizationControl() {
    return this.addressForm?.get('company_organization');
  }

  get addressRecipientControl() {
    return this.addressForm?.get('recipient');
  }

  get addressStreetControl() {
    return this.addressForm?.get('street');
  }

  get addressHouseNumberControl() {
    return this.addressForm?.get('houseNumber');
  }

  get addressAdditionalAddressInformationControl() {
    return this.addressForm?.get('additionalAddressInformation');
  }

  get addressPostalCodeControl() {
    return this.addressForm?.get('postalCode');
  }

  get addressCityControl() {
    return this.addressForm?.get('city');
  }

  get addressCountryControl() {
    return this.addressForm?.get('country');
  }

  get addressLabelByType() {
    // TODO: Use translations
    switch (this.addressType) {
      case 'billing-address':
        return 'Rechnungsadresse';
      case 'shipping-address':
        return 'Lieferadresse';
      default:
        return 'Adresse';
    }
  }

  constructor(
    private countryCodesService: CountryCodesService,
    private countryCodesQuery: CountryCodesQuery,
    private addressesService: AddressesService,
    private addressesQuery: AddressesQuery,
    private matDialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.initialize();

    this.countryCodesService.get().subscribe();

    if (this.loadAddresses) {
      this.addressesService.getAddresses().subscribe();
    }

    // Reset selected and form if it has been deleted
    combineLatest([
      this.selectedAddress$.pipe(filterNilValue()),
      this.addresses$,
    ]).pipe(
      tap(([selectedAddress, addresses]) => {
        // TODO: Find better way to notify if selected address has been deleted
        if (selectedAddress && !addresses.some(address => address.id === selectedAddress.id)) {
          this.updateSelectedAddress(null);
          this.addressForm.reset();
        }
      }),
      untilDestroyed(this),
    ).subscribe();
  }

  selectAdditionalShippingAddress() {
    const matDialogConfig = getMatDialogConfig<AddressSelectionDialogData>({
      panelClass: 'mat-dialog-with-scrollable-content',
      minWidth: '450px',
      data: {
        selectedAddress: this.selectedAddressSubject.value,
      },
      autoFocus: 'dialog',
    });

    this.matDialog.open<AddressSelectionDialogComponent, AddressSelectionDialogData, AddressSelectionDialogOutputData>(
      AddressSelectionDialogComponent,
      matDialogConfig
    )
      .afterClosed()
      .subscribe(dialogOutput => {
          if (!dialogOutput) {
            return;
          }

          this.addressSelected(dialogOutput.selectedAddress);
        }
      );
  }

  addressSelected(address: Address | null) {
    this.updateSelectedAddress(address);

    // Reset or patch form with new value
    if (address !== null) {
      const shippingAddressFormValue: AddressForm = {
        company_organization: address?.company_organization ?? null,
        recipient: address?.recipient ?? null,
        street: address?.street ?? null,
        houseNumber: address?.house_number ?? null,
        additionalAddressInformation: address?.additional_address_information ?? null,
        postalCode: address?.postal_code ?? null,
        city: address?.city ?? null,
        country: address?.country_code_id ?? null,
      };
      this.addressForm.patchValue(shippingAddressFormValue);
    }
  }

  private initialize() {
    this.selectedAddressSubject = new BehaviorSubject<Address | null>(null);

    this.initializeObservables();
  }

  private initializeObservables() {
    this.selectedAddress$ = this.selectedAddressSubject.asObservable();
    this.useNewAddress$ = this.selectedAddress$.pipe(
      map(selectedShippingAddress => selectedShippingAddress === null),
    );

    this.isAddressesLoading$ = this.addressesQuery.isLoading$;
    this.countryCodes$ = this.countryCodesQuery.countryCodes$;
    this.addresses$ = this.addressesQuery.addresses$;

    this.addressesPreview$ = combineLatest([
      this.selectedAddress$,
      this.addresses$,
    ]).pipe(
      // TODO: Use scan operator to keep order after selecting in preview while previously having selected an option not inside the preview
      map(getAddressPreviewList),
      shareReplay({refCount: true, bufferSize: 1}),
    );

    this.hasAdditionalShippingAddresses$ = combineLatest([
      this.addressesPreview$,
      this.addresses$,
    ]).pipe(
      map(([shippingAddressesPreview, shippingAddresses]) => {
        if (!shippingAddressesPreview || !shippingAddresses) {
          return false;
        }

        return shippingAddresses.length - shippingAddressesPreview.length > 0;
      }),
      shareReplay({refCount: true, bufferSize: 1}),
    );
  }

  private updateSelectedAddress(address: Address | null) {
    this.selectedAddressSubject.next(address);
  }
}
