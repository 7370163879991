import {ProductCategoriesQuery} from '@/shop/product/state/product-categories/product-categories.query';
import {ProductCategory} from '@/shop/product/state/product-categories/product-category.model';
import {inject, Pipe, PipeTransform} from '@angular/core';
import {Observable} from 'rxjs';

@Pipe({
  name: 'childCategoriesByParentId$'
})
export class ChildCategoriesByParentIdPipe implements PipeTransform {
  private readonly productCategoriesQuery = inject(ProductCategoriesQuery);

  transform(productCategoryId: number): Observable<ProductCategory[]> {
    return this.productCategoriesQuery.selectChildCategoriesOfParent(productCategoryId);
  }
}
