import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {map} from 'rxjs/operators';
import {UserAddressesState, UserAddressesStore} from './user-addresses.store';
import {addTypeToAddresses} from '../utils/functions/add-type-to-address';

@Injectable({providedIn: 'root'})
export class UserAddressesQuery extends QueryEntity<UserAddressesState> {
  isLoading$ = this.selectLoading();

  userAddresses$ = this.selectAll().pipe(
    map(addresses => addTypeToAddresses(addresses, 'user')),
  );

  constructor(protected store: UserAddressesStore) {
    super(store);
  }

  selectAddressesForUserId(userId: number) {
    return this.userAddresses$.pipe(
      map(userShippingAddresses =>
        userShippingAddresses.filter(({user_id}) => user_id === userId)),
    );
  }
}
