<li class="shipping-address-selection-item dqn-radio-button-container">
    <div class="dqn-radio-button-content py-2">
        <div
          class="dqn-radio"
          (click)="emitSelectAddress()"
          tabindex="0">
            <ng-container *ngIf="isSelected !== undefined">
                <div
                  [class.dqn-radio-button-selected]="isSelected"
                  class="dqn-radio-button">
                </div>
            </ng-container>

            <div class="pl-5">
                <ng-container *ngIf="address.company_organization">
                    {{ address.company_organization }} <br />
                </ng-container>
                <ng-container *ngIf="address.recipient">
                    {{ address.recipient }} <br />
                </ng-container>
                <ng-container *ngIf="address.additional_address_information">
                    {{ address.additional_address_information }} <br />
                </ng-container>
                {{ address.street }} {{ address.house_number }} <br />
                {{ address.postal_code }} {{ address.city }}
            </div>
        </div>

        <div
          *ngIf="address.type === 'user'"
          class="dqn-radio-actions">
            <button
              mat-icon-button
              matTooltip="Persönliche Addresse löschen"
              (click)="deleteAddress()">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>

    <mat-divider></mat-divider>
</li>
