import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {TenantAddressesState, TenantAddressesStore} from './tenant-addresses.store';
import {map} from 'rxjs/operators';
import {addTypeToAddresses} from '../utils/functions/add-type-to-address';

@Injectable({providedIn: 'root'})
export class TenantAddressesQuery extends QueryEntity<TenantAddressesState> {
  isLoading$ = this.selectLoading();

  tenantAddresses$ = this.selectAll().pipe(
    map(addresses => addTypeToAddresses(addresses, 'tenant')),
  );

  constructor(protected store: TenantAddressesStore) {
    super(store);
  }

  selectAddressesForTenantId(tenantId: number) {
    return this.tenantAddresses$.pipe(
      map(legalEntityShippingAddresses =>
        legalEntityShippingAddresses.filter(({tenant_id}) => tenant_id === tenantId)),
    );
  }
}
