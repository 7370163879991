import {MatDialogConfig} from '@angular/material/dialog';

export const getMatDialogConfig = <D = unknown>(config: Partial<MatDialogConfig<D>>) => {
  const dialogConfig = new MatDialogConfig<D>();

  for (const [key, value] of Object.entries(config)) {
    dialogConfig[key] = value;
  }

  return dialogConfig;
};
