import {CostCenter} from '@/shared/state/cost-centers/cost-center.model';
import {SupplierProduct} from '../../../product/state/supplier-products/supplier-product.model';
import {OrderApprovalWorkflowFormData} from '../order.model';

export const addOrUpdateOrderItemInIndividualCostCenterMappings = ({
                                                                     costCenterId,
                                                                     supplierProductId,
                                                                     existingApprovalWorkflowData,
                                                                   }: {
  costCenterId: CostCenter['id'];
  supplierProductId: SupplierProduct['id'];
  existingApprovalWorkflowData: OrderApprovalWorkflowFormData;
}) => {
  let costCenterMappings = existingApprovalWorkflowData?.individual_cost_center_mapping ?? [];

  // Remove supplierProduct from existing mappings
  costCenterMappings = costCenterMappings.map(mapping => ({
    cost_center_id: mapping.cost_center_id,
    supplier_product_ids: mapping.supplier_product_ids.filter(id => id !== supplierProductId)
  }));

  if (costCenterId) { // Don't add mapping if costCenterId is null
    // Add map entry for cost center if not exists
    if (!costCenterMappings.some(mapping => mapping.cost_center_id === costCenterId)) {
      costCenterMappings = [
        ...costCenterMappings,
        {
          cost_center_id: costCenterId,
          supplier_product_ids: []
        }
      ];
    }

    // Add supplierProduct to new costCenter mapping
    costCenterMappings = costCenterMappings.map(mapping => {
      if (mapping.cost_center_id == costCenterId) {
        mapping.supplier_product_ids = [
          ...mapping.supplier_product_ids,
          supplierProductId
        ];
      }

      return mapping;
    });
  }
  // Remove empty mappings
  costCenterMappings = costCenterMappings.filter(mapping => mapping?.supplier_product_ids.length);

  return {
    ...existingApprovalWorkflowData,
    // Individual cost center mappings can't be an empty array but rather need to be null instead
    individual_cost_center_mapping: costCenterMappings?.length > 0 ? costCenterMappings : null,
  };
}
