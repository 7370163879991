import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';
import {SleekplanSdk, SleekplanUserData} from './types/sleekplan-sdk';

export interface SleekplanState {
  instance: SleekplanSdk,
  ssoToken: string;
  loading: boolean;
  widgetOpen: boolean;
}

export function createInitialState(): SleekplanState {
  return {
    instance: undefined,
    ssoToken: '',
    loading: false,
    widgetOpen: false,
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({
  name: 'sleekplan',
})
export class SleekplanStore extends Store<SleekplanState> {
  get instance() {
    return this.getValue().instance;
  }

  constructor() {
    super(createInitialState());
  }

  setUserOnInstance(token: SleekplanUserData['token']) {
    this.instance.setUser({token: token});
  }
}
