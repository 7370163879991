<mat-card>
    <mat-card-content class="flex flex-col gap-3">
        <form [formGroup]="productForm">
            <!--Main information-->
            <mat-expansion-panel
              class="mat-elevation-z0"
              [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title class="mt-2">
                        <div class="mat-headline-6">Allgemein</div>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div>
                    <div>
                        <dqn-form-field *ngIf="!formMode.isProposal">
                            <dqn-label>Kategorie</dqn-label>

                            <dqn-combobox
                              [options]="(productCategoriesQuery.productCategoriesAsDqnOptions$ | async) ?? []"
                              formControlName="productCategoryId"
                              required />
                        </dqn-form-field>
                    </div>
                    <div>
                        <dqn-form-field>
                            <dqn-label>Name</dqn-label>
                            <input
                              dqnInput
                              formControlName="name"
                              required />
                        </dqn-form-field>
                    </div>
                </div>
            </mat-expansion-panel>

            <!--Product information-->
            <div class="mt-2">
                <mat-expansion-panel
                  class="mat-elevation-z0"
                  [expanded]="formMode.isCreate">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="mt-2">
                            <div class="mat-headline-6">Artikelinformationen</div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div
                      class="flex flex-col gap-0.5">
                        <div class="default-image">
                            <ng-container *ngIf="formMode.isReadonly">
                                <img
                                  [src]="(activeProduct$ | async)?.images | productDefaultImageUrl"
                                  alt="Produktbild" />
                            </ng-container>

                            <ng-container *ngIf="!formMode.isReadonly">
                                <div class="mat-subtitle-2">Bitte Artikel-Bild auswählen</div>

                                <app-file-upload
                                  [backgroundImage]="productImage
                                    ? productImage?.asBase64
                                    : ((activeProduct$ | async)?.images | productDefaultImageUrl)"
                                  (uploadFiles)="updateProductImage($event)">
                                </app-file-upload>
                            </ng-container>
                        </div>
                        <div>
                            <dqn-form-field hintTextEnd="{{ description.value.length }} / 1000 Zeichen">
                                <dqn-label>Kurzbeschreibung</dqn-label>
                                <input
                                  dqnInput
                                  #description
                                  formControlName="description"
                                  maxlength="1000" />
                            </dqn-form-field>
                        </div>
                        <div>
                            <dqn-form-field hintTextEnd="{{ descriptionLong.value.length }} / 1200 Zeichen">
                                <dqn-label>Beschreibung</dqn-label>
                                <textarea
                                  dqnInput
                                  #descriptionLong
                                  formControlName="descriptionLong"
                                  maxlength="1200">
                                </textarea>
                            </dqn-form-field>
                        </div>
                        <div>
                            <dqn-form-field hintText="Keywords durch Kommata und Leerzeichen getrennt">
                                <dqn-label>Keywords</dqn-label>
                                <input
                                  dqnInput
                                  formControlName="keywords" />
                            </dqn-form-field>
                        </div>
                        <div>
                            <dqn-form-field>
                                <dqn-label>EAN/GTIN</dqn-label>
                                <input
                                  dqnInput
                                  formControlName="eanGtin" />
                            </dqn-form-field>
                        </div>
                        <div class="product-information-row">
                            <dqn-form-field>
                                <dqn-label>Verpackungseinheit</dqn-label>
                                <dqn-dropdown
                                  formControlName="quantityUnitId"
                                  required>
                                    <dqn-option
                                      *ngFor="let quantityUnit of quantityUnits$ | async"
                                      [value]="quantityUnit.id">
                                        {{ quantityUnit.display_name }}
                                    </dqn-option>
                                </dqn-dropdown>
                            </dqn-form-field>
                        </div>

                        <div class="product-information-row">
                            <dqn-form-field class="flex-1 min-w-0">
                                <dqn-label>Verkaufsmenge</dqn-label>
                                <input
                                  dqnInput
                                  type="text"
                                  formControlName="sellingQuantity"
                                  [imask]="iMaskDecimalInput"
                                  [unmask]="true" />
                            </dqn-form-field>

                            <dqn-form-field class="flex-1 min-w-0">
                                <dqn-label>Basismenge</dqn-label>
                                <input
                                  dqnInput
                                  type="text"
                                  formControlName="basicQuantity"
                                  [imask]="iMaskDecimalInput"
                                  [unmask]="true" />
                            </dqn-form-field>

                            <dqn-form-field class="flex-1 min-w-0">
                                <dqn-label>Maßeinheit</dqn-label>
                                <dqn-dropdown formControlName="scaleUnitId">
                                    <dqn-option [value]="null">Keine</dqn-option>
                                    <dqn-option
                                      *ngFor="let quantityUnit of quantityUnits$ | async"
                                      [value]="quantityUnit.id">
                                        {{ quantityUnit.display_name }}
                                    </dqn-option>
                                </dqn-dropdown>
                            </dqn-form-field>
                        </div>
                    </div>
                </mat-expansion-panel>
            </div>

            <!--Status-->
            <div
              *ngIf="!formMode.isProposal"
              class="mt-2">
                <mat-expansion-panel
                  class="mat-elevation-z0"
                  [expanded]="formMode.isCreate">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="mt-2">
                            <div class="mat-headline-6">Status</div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div>
                        <mat-slide-toggle
                          color="primary"
                          formControlName="requiresApproval">Genehmigungspflichtig
                        </mat-slide-toggle>
                    </div>

                    <div>
                        <mat-slide-toggle
                          color="primary"
                          formControlName="isVirtual"
                          [dqnTooltip]="(tooltipForIsVirtual$ | async)">
                            Virtuell
                        </mat-slide-toggle>
                    </div>
                </mat-expansion-panel>
            </div>

            <!--Product specific information-->
            <div class="mt-2">
                <mat-expansion-panel class="mat-elevation-z0">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="mt-2">
                            <div class="mat-headline-6">Artikelmerkmale</div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div
                      class="flex flex-col gap-1">
                        <div
                          *ngIf="(productsQuery.activeProduct$ | async)?.parent_id"
                          class="flex flex-col gap-1">
                            <div class="mat-subtitle-2">Variantenattribute</div>

                            <app-product-attributes-table
                              [formMode]="FormMode.details"
                              [productAttributes]="productsQuery.productAttributesWithOnlyVariantAttributesForActiveProduct$ | async" />
                        </div>

                        <div class="flex flex-col gap-1">
                            <div
                              *ngIf="(productsQuery.activeProduct$ | async)?.parent_id"
                              class="mat-subtitle-2">Artikelmerkmale
                            </div>
                            <app-product-attributes-management
                              [formMode]="formMode.mode"
                              [productAttributes]="productsQuery.productAttributesWithoutVariantAttributesForActiveProduct$ | async" />
                        </div>
                    </div>
                </mat-expansion-panel>
            </div>

            <!--Supplier Product information-->
            <div
              *ngIf="!formMode.isProposal && !isVirtualProduct"
              class="mt-2">
                <mat-expansion-panel
                  class="mat-elevation-z0"
                  [expanded]="!formMode.isCreate">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="mt-2">
                            <div class="mat-headline-6">Bezugsquellen</div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <app-supplier-product-management
                      [formMode]="formMode.mode"
                      [product]="activeProduct$ | async" />
                </mat-expansion-panel>
            </div>

            <!--Variants -->
            <div
              *ngIf="!formMode.isProposal && isVirtualProduct"
              class="mt-2">
                <mat-expansion-panel
                  class="mat-elevation-z0"
                  [expanded]="!formMode.isCreate">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="mt-2">
                            <div class="mat-headline-6">Varianten</div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="flex flex-col gap-2">
                        <div class="flex flex-col gap-1">
                            <div class="mat-subtitle-2">Variantenkonfiguration</div>
                            <app-variant-attributes-management
                              [formMode]="formMode.mode"
                              [variantAttributes]="productsQuery.variantAttributesAsProductAttributesForActiveProduct$ | async"
                              [productAttributeTemplates]="productAttributeTemplatesQuery.productAttributeTemplates$ | async" />
                        </div>

                        <app-variant-management
                          [formMode]="formMode.mode"
                          [variants]="productsQuery.variantsForActiveProduct$ | async" />
                    </div>
                </mat-expansion-panel>
            </div>
        </form>
    </mat-card-content>

    <mat-card-actions align="end">
        <ng-container *ngIf="formMode.isProposal">
            <a
              mat-raised-button
              color="primary"
              (click)="submit()"
              [disabled]="!isSubmitButtonEnabled">
                Produkt anlegen
            </a>
        </ng-container>

        <ng-container *ngIf="!formMode.isProposal">
            <a
              mat-button
              [routerLink]="productListRouterLink"
              color="primary">Zurück zur Übersicht</a>

            <ng-container *ngIf="formMode.isDetails">
                <a
                  mat-raised-button
                  color="primary"
                  [routerLink]="productEditRouterLink$ | async">
                    Bearbeiten
                </a>
            </ng-container>

            <ng-container *ngIf="!formMode.isReadonly">
                <button
                  mat-flat-button
                  color="primary"
                  (click)="submit()"
                  [disabled]="!isSubmitButtonEnabled">
                    {{ formMode.isCreate ? 'Erstellen' : 'Speichern' }}
                </button>
            </ng-container>
        </ng-container>
    </mat-card-actions>
</mat-card>
