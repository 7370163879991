import {SessionQuery} from '@/core/session/state/session.query';
import {ApiResponse} from '@/shared/types/api/api-response';
import {handleError} from '@/shared/utils';
import {getHttpOptionsWithParams} from '@/shared/utils/functions/http-params';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {setLoading} from '@datorama/akita';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {VariantAttribute} from '../variant-attributes/variant-attribute.model';
import {BulkUpdateVariantAttributesRequestItem} from './types/bulk-update-variant-attributes-request-item';
import {VariantAttributeOption} from './variant-attribute-option.model';
import {VariantAttributeOptionsStore} from './variant-attribute-options.store';

@Injectable({providedIn: 'root'})
export class VariantAttributeOptionsService {
  private readonly variantAttributeOptionsStore = inject(VariantAttributeOptionsStore);
  private readonly sessionQuery = inject(SessionQuery);
  private readonly http = inject(HttpClient);
  private readonly snackBar = inject(MatSnackBar);

  get() {
    const options = getHttpOptionsWithParams({
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http
      .get<ApiResponse<VariantAttributeOption[]>>(environment.api.baseUrl + 'variant-attribute-options', options)
      .pipe(
        setLoading(this.variantAttributeOptionsStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.variantAttributeOptionsStore)),
        tap((response) => {
          this.variantAttributeOptionsStore.set(response['data']);
        }),
      );
  }

  bulkAddVariantAttributeOptions(bulkUpdateVariantAttributesRequestItems: BulkUpdateVariantAttributesRequestItem[]) {
    const options = getHttpOptionsWithParams({
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    const body = {variant_attribute_options: bulkUpdateVariantAttributesRequestItems};

    return this.http
      .post<ApiResponse<VariantAttribute[]>>(environment.api.baseUrl + 'variant-attribute-options/bulk-create', body, options)
      .pipe(
        setLoading(this.variantAttributeOptionsStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.variantAttributeOptionsStore)),
        // TODO: Set to store as soon as the API returns the created variant attributes
        // tap(({data}) => {
        // this.variantAttributesStore.upsertMany(data);
        // }),
      );
  }
}
