<ng-container *ngIf="(isAddressesLoading$ | async) === false; else addressesLoading">
    <ng-container *ngIf="addresses$ | async as shippingAddresses; else addressesLoading">

        <div class="flex mt-2 justify-between items-center">
            <div class="mat-headline-6">{{ addressLabelByType }} auswählen</div>
            <ng-container *ngIf="hasAdditionalShippingAddresses$ | async">
                <div
                  class="select-additional-address"
                  (click)="selectAdditionalShippingAddress()"
                  tabindex="0">
                    Weitere Adressen
                </div>
            </ng-container>
        </div>

        <div class="mat-elevation-z0 no-border billing-address">
            <ul class="shipping-address-selection-list">

                <app-address-list-item
                  *ngFor="let shippingAddress of addressesPreview$ | async"
                  (selectAddress)="addressSelected(shippingAddress)"
                  [address]="shippingAddress"
                  [isSelected]="shippingAddress.id === (selectedAddress$ | async)?.id">
                </app-address-list-item>

                <ng-container *ngIf="shippingAddresses?.length > 0">
                    <li
                      class="dqn-radio-button-container"
                      (click)="addressSelected(null)"
                      tabindex="0">
                        <div class="py-2 flex">
                            <div
                              [class.dqn-radio-button-selected]="(selectedAddress$ | async) === null"
                              class="dqn-radio-button"></div>
                            <span class="pl-5">Sonstige {{ addressLabelByType }}</span>
                        </div>
                    </li>
                </ng-container>
            </ul>

            <ng-container *ngIf="useNewAddress$ | async">
                <form
                  [formGroup]="addressFormAsFormGroup"
                  class="mt-3">
                    <div
                      *ngIf="addressCompanyOrganizationControl"
                      class="flex">
                        <dqn-form-field class="flex-1">
                            <dqn-label>Firma</dqn-label>
                            <input
                              formControlName="company_organization"
                              dqnInput
                              maxlength="255"
                              type="text"
                              [required]="addressCompanyOrganizationControl | isFormControlRequired" />

                            <dqn-error
                              *ngIf="addressCompanyOrganizationControl.touched && addressCompanyOrganizationControl.hasError('required')"
                              class="mat-small">
                                Bitte geben Sie eine Firma ein.
                            </dqn-error>
                        </dqn-form-field>
                    </div>

                    <div
                      *ngIf="addressRecipientControl"
                      class="flex">
                        <dqn-form-field class="flex-1">
                            <dqn-label>Empfänger</dqn-label>
                            <input
                              formControlName="recipient"
                              dqnInput
                              maxlength="255"
                              type="text"
                              [required]="addressRecipientControl | isFormControlRequired" />
                            <dqn-error
                              *ngIf="addressRecipientControl.touched && addressRecipientControl.hasError('required')"
                              class="mat-small">
                                Bitte geben Sie einen Empfänger ein.
                            </dqn-error>
                        </dqn-form-field>
                    </div>

                    <div class="flex items-start gap-2">
                        <dqn-form-field class="flex flex-1">
                            <dqn-label>Straße</dqn-label>
                            <input
                              formControlName="street"
                              dqnInput
                              maxlength="100"
                              type="text"
                              required />
                            <dqn-error
                              *ngIf="addressStreetControl.touched && addressStreetControl.hasError('required')"
                              class="mat-small">
                                Bitte geben Sie eine Straße ein.
                            </dqn-error>
                            <dqn-error
                              *ngIf="addressStreetControl.hasError('maxlength')"
                              class="mat-small">
                                Die Straße darf nicht mehr als 100 Zeichen lang sein.
                            </dqn-error>
                        </dqn-form-field>

                        <dqn-form-field class="w-[30%]">
                            <dqn-label>Hausnummer</dqn-label>
                            <input
                              formControlName="houseNumber"
                              dqnInput
                              maxlength="10"
                              type="text"
                              required />
                            <dqn-error
                              *ngIf="addressHouseNumberControl.touched && addressHouseNumberControl.hasError('required')"
                              class="mat-small">
                                Bitte geben Sie eine Hausnummer ein.
                            </dqn-error>
                            <dqn-error
                              *ngIf="addressHouseNumberControl.hasError('maxlength')"
                              class="mat-small">
                                Es sind max. 10 Zeichen erlaubt.
                            </dqn-error>
                        </dqn-form-field>
                    </div>

                    <div class="flex">
                        <dqn-form-field class="flex flex-1">
                            <dqn-label>Adresszusatz</dqn-label>
                            <input
                              formControlName="additionalAddressInformation"
                              dqnInput
                              maxlength="100"
                              type="text" />
                            <dqn-error
                              *ngIf="addressAdditionalAddressInformationControl.hasError('maxlength')"
                              class="mat-small">
                                Der Adresszusatz darf nicht mehr als 100 Zeichen lang sein.
                            </dqn-error>
                        </dqn-form-field>
                    </div>

                    <div class="flex items-start gap-2">
                        <dqn-form-field class="w-[30%]">
                            <dqn-label>PLZ</dqn-label>
                            <input
                              formControlName="postalCode"
                              dqnInput
                              maxlength="20"
                              type="text"
                              required />
                            <dqn-error
                              *ngIf="addressPostalCodeControl.touched && addressPostalCodeControl.hasError('required')"
                              class="mat-small">
                                Bitte geben Sie eine PLZ ein.
                            </dqn-error>
                            <dqn-error
                              *ngIf="addressPostalCodeControl.hasError('maxlength')"
                              class="mat-small">
                                Die PLZ darf nicht mehr als 20 Zeichen lang sein.
                            </dqn-error>
                        </dqn-form-field>

                        <dqn-form-field class="flex flex-1">
                            <dqn-label>Stadt</dqn-label>
                            <input
                              formControlName="city"
                              dqnInput
                              maxlength="50"
                              type="text"
                              required />
                            <dqn-error
                              *ngIf="addressCityControl.touched && addressCityControl.hasError('required')"
                              class="mat-small">
                                Bitte geben Sie eine Stadt ein.
                            </dqn-error>
                            <dqn-error
                              *ngIf="addressCityControl.hasError('maxlength')"
                              class="mat-small">
                                Die Stadt darf nicht mehr als 50 Zeichen lang sein.
                            </dqn-error>
                        </dqn-form-field>
                    </div>

                    <div class="flex">
                        <dqn-form-field class="flex flex-1">
                            <dqn-label>Land</dqn-label>
                            <dqn-dropdown
                              required
                              formControlName="country">
                                <dqn-option
                                  *ngFor="let countryCode of countryCodes$ | async"
                                  [value]="countryCode.id">
                                    {{ countryCode.display_name }}
                                </dqn-option>
                            </dqn-dropdown>

                            <dqn-error
                              *ngIf="addressCountryControl.hasError('required')">
                                Bitte wählen Sie ein Land aus.
                            </dqn-error>
                        </dqn-form-field>
                    </div>
                </form>
            </ng-container>
        </div>

    </ng-container>
</ng-container>

<ng-template #addressesLoading>
    Adressen werden geladen
</ng-template>
