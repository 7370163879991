import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ApprovalDialogResult} from './models/approval-dialog-result.model';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-approval-dialog',
  templateUrl: './approval-dialog.component.html',
  styleUrls: ['./approval-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApprovalDialogComponent implements OnInit {
  approvalForm: UntypedFormGroup;
  approved: boolean;

  get commentControl() {
    return this.approvalForm.get('comment');
  }

  constructor(
    private dialogRef: MatDialogRef<ApprovalDialogComponent>
  ) {
  }

  ngOnInit() {
    this.initialize();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  save(): void {
    const response: ApprovalDialogResult = {
      approved: this.approved,
      comment: this.commentControl.value
    };
    this.dialogRef.close(response);
  }

  private initialize() {
    this.approvalForm = new UntypedFormGroup({
      comment: new UntypedFormControl('', [Validators.maxLength(255)])
    });
  }
}
