import {Injectable} from '@angular/core';
import {combineQueries, ID, QueryEntity} from '@datorama/akita';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {ProductCategoriesQuery} from '../product-categories/product-categories.query';
import {Product} from '../product.model';
import {
  createProductWithInformationFromSupplierProduct
} from './helpers/create-product-with-information-from-supplier-product';
import {createSupplierProduct, SupplierProduct} from './supplier-product.model';
import {SupplierProductsState, SupplierProductsStore} from './supplier-products.store';

@Injectable({providedIn: 'root'})
export class SupplierProductsQuery extends QueryEntity<SupplierProductsState> {
  isLoading$ = this.selectLoading();

  activeSupplierProduct$ = this.selectActive();

  supplierProducts$ = this.selectAll();

  promotedSupplierProducts$ = this.supplierProducts$.pipe(
    map(supplierProducts => supplierProducts
      .filter(supplierProduct => supplierProduct.promoted_order_number != null)
      .sort((supplierProductA, supplierProductB) => {
          return supplierProductA.promoted_order_number - supplierProductB.promoted_order_number;
        }
      )
      .map(supplierProduct => createSupplierProduct({
        ...supplierProduct,
        product: createProductWithInformationFromSupplierProduct(supplierProduct),
      })),
    )
  );

  supplierProductsWithoutDuplicates$ = this.supplierProducts$.pipe(
    map(supplierProducts => supplierProducts.reduce((filteredSupplierProducts, supplierProduct) =>
      // Filter out supplier products with same product
      filteredSupplierProducts.some(filteredSupplierProduct => filteredSupplierProduct.product?.id === supplierProduct.product?.id)
        ? filteredSupplierProducts
        : [
          ...filteredSupplierProducts,
          supplierProduct,
        ], [] as SupplierProduct[])
    ),
  );

  supplierProductsForActiveProductCategory$ = combineQueries([
    this.productCategoriesQuery.selectActive(),
    this.selectAll(),
  ]).pipe(
    map(([productCategory, supplierProducts]) =>
      supplierProducts.filter((supplierProduct) => supplierProduct.product?.product_category_id === productCategory?.id)
    ),
  );

  hasSupplierProductsForActiveProductCategory$ = this.supplierProductsForActiveProductCategory$.pipe(
    map(supplierProducts => supplierProducts?.length > 0),
  );

  supplierProductsForActiveProductCategoryWithoutDuplicates$ = this.supplierProductsForActiveProductCategory$.pipe(
    map(supplierProducts => supplierProducts.reduce((filteredSupplierProducts, supplierProduct) =>
      // Filter out supplier products with same product
      filteredSupplierProducts.some(filteredSupplierProduct => filteredSupplierProduct.product?.id === supplierProduct.product?.id)
        ? filteredSupplierProducts
        : [
          ...filteredSupplierProducts,
          supplierProduct,
        ], [] as SupplierProduct[])),
  );

  areSupplierProductsForActiveProductCategoryLoading$ = combineQueries([
    this.hasSupplierProductsForActiveProductCategory$,
    this.isLoading$,
  ]).pipe(
    map(([hasSupplierProductsForActiveCategory, isLoading]) => isLoading && !hasSupplierProductsForActiveCategory),
  );

  supplierProductsWithSameBaseProductForActiveSupplierProduct$: Observable<SupplierProduct[]> = this.activeSupplierProduct$.pipe(
    switchMap(supplierProduct => supplierProduct
      ? this.selectSupplierProductsWithSameBaseProduct(supplierProduct.product_id)
      : of([])
    ),
  );

  hasSupplierProducts$ = this.supplierProducts$.pipe(
    map(supplierProducts => supplierProducts?.length > 0),
  );

  areSupplierProductsLoading$ = combineQueries([
    this.hasSupplierProducts$,
    this.isLoading$,
  ]).pipe(
    map(([hasSupplierProducts, isLoading]) => isLoading && !hasSupplierProducts),
  );

  constructor(protected store: SupplierProductsStore, private productCategoriesQuery: ProductCategoriesQuery) {
    super(store);
  }

  selectById(id: SupplierProduct['id']) {
    return this.selectEntity(id);
  }

  getSupplierProductById(id: SupplierProduct['id']) {
    return this.getEntity(id);
  }

  // TODO Optimize performance (create API endpoint for all supplier products filtered by product category id)
  getSupplierProductsByProductCategoryId(id: ID) {
    return this.selectAll({
      filterBy: (supplierProduct) => supplierProduct.product?.product_category_id === id
    });
  }

  getSupplierProductsWithSameBaseProduct(productId: Product['id']) {
    return this.getAll({
      filterBy: entity => entity.product_id === productId
    });
  }

  selectSupplierProductsWithSameBaseProduct(productId: number) {
    return this.supplierProducts$.pipe(
      map(supplierProducts => supplierProducts.filter(({product_id}) => product_id === productId)),
    );
  }
}
