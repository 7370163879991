import {
  ProductAttributeTemplate
} from '@/management/product/state/product-attribute-templates/product-attribute-template.model';
import {VariantAttribute} from '@/management/product/state/variant-attributes/variant-attribute.model';
import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {filterNilValue} from '@datorama/akita';
import {DqnTableHeader} from '@dqn/components/table';
import {combineLatest} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {FormModeDirective, FormModeHostDirective} from '../../../../directives/form-mode.directive';
import {getMatDialogConfig} from '../../../../utils/functions/mat-dialog';
import {
  VariantAttributesAddDialogComponent,
  VariantAttributesAddDialogData,
  VariantAttributesAddDialogOutput
} from '../variant-attributes-add-dialog/variant-attributes-add-dialog.component';
import {VariantManagementQuery} from '../variant-management/variant-management.state';

import {
  VariantAttributesManagementQuery,
  VariantAttributesManagementService
} from './variant-attributes-management.state';

@Component({
  selector: 'app-variant-attributes-management',
  templateUrl: './variant-attributes-management.component.html',
  styleUrls: ['./variant-attributes-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [
    FormModeHostDirective,
  ]
})
export class VariantAttributesManagementComponent {
  readonly formMode = inject(FormModeDirective, {self: true});
  readonly query = inject(VariantAttributesManagementQuery);
  readonly variantManagementQuery = inject(VariantManagementQuery);

  private readonly dialog = inject(MatDialog);
  private readonly service = inject(VariantAttributesManagementService);

  @Input() set variantAttributes(variantAttributes: VariantAttribute[]) {
    this.service.setInitialVariantAttributes(variantAttributes ?? []);
  }

  @Input({required: true}) productAttributeTemplates: ProductAttributeTemplate[];

  editVariantConfigurationButtonTooltipText$ = combineLatest([
    this.variantManagementQuery.hasVariants$,
    this.query.limitOfVariantAttributesReached$,
  ]).pipe(
    map(([hasVariants, limitReached]) => {
      if (hasVariants) {
        // TODO: Once regenerating variants is possible, this hint and disable can be removed
        return 'Es können keine neuen Variantenattribute ergänzt werden, wenn bereits Varianten vorhanden sind';
      }

      if (limitReached) {
        return 'Maximale Anzahl an Variantenattributen erreicht';
      }

      return '';
    }),
  );

  variantAttributeHeaders: DqnTableHeader[] = [
    {key: 'name', title: 'Attribut'},
    {key: 'options', title: 'Optionen'},
  ];

  openVariantAttributesAddDialog(selectedTemplateId: VariantAttribute['product_attribute_template_id'] = null) {
    const dialogConfig = getMatDialogConfig<VariantAttributesAddDialogData>({
      data: {
        productAttributeTemplates: this.productAttributeTemplates,
        variantAttributes: this.query.variantAttributes,
        selectedProductAttributeTemplateId: selectedTemplateId,
      },
      autoFocus: false,
      minWidth: '26.25rem',
    });

    this.dialog.open<VariantAttributesAddDialogComponent, unknown, VariantAttributesAddDialogOutput>(VariantAttributesAddDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(
        filterNilValue(),
        tap(({productAttributeTemplateId, variantAttributeOptions}) => this.service.addVariantAttributeOptions(
          productAttributeTemplateId,
          variantAttributeOptions
        )),
      )
      .subscribe();
  }
}
