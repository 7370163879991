import {SessionQuery} from '@/core/session/state/session.query';
import {ProductAttributeGroup} from '@/management/product/state/product-attribute-groups/product-attribute-group.model';
import {
  ProductAttributeGroupsQuery
} from '@/management/product/state/product-attribute-groups/product-attribute-groups.query';
import {
  ProductAttributeGroupsService
} from '@/management/product/state/product-attribute-groups/product-attribute-groups.service';
import {
  ProductAttributeTemplate
} from '@/management/product/state/product-attribute-templates/product-attribute-template.model';
import {
  ProductAttributeTemplatesQuery
} from '@/management/product/state/product-attribute-templates/product-attribute-templates.query';
import {
  ProductAttributeTemplatesService
} from '@/management/product/state/product-attribute-templates/product-attribute-templates.service';
import {ProductAttribute} from '@/management/product/state/product-attributes/product-attribute.model';
import {HttpClient} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {filter, switchMap, tap} from 'rxjs/operators';
import {environment} from '../../../../../../environments/environment';
import {MatCardAlertMode} from '../../../../enums/mat-card-alert-mode.enum';
import {ApiResponse} from '../../../../types/api/api-response';
import {getHttpOptionsWithInclude} from '../../../../utils/functions/http-params';

@Component({
  selector: 'app-product-attributes-add-dialog',
  templateUrl: './product-attributes-add-dialog.component.html',
  styleUrls: ['./product-attributes-add-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductAttributesAddDialogComponent implements OnInit {
  readonly productAttributeGroupsQuery = inject(ProductAttributeGroupsQuery);
  readonly productAttributeTemplatesQuery = inject(ProductAttributeTemplatesQuery);

  private readonly data = inject(MAT_DIALOG_DATA);
  private readonly dialogRef = inject(MatDialogRef<ProductAttributesAddDialogComponent>);
  private readonly productAttributeGroupsService = inject(ProductAttributeGroupsService);
  private readonly productAttributeTemplatesService = inject(ProductAttributeTemplatesService);
  private readonly sessionQuery = inject(SessionQuery);
  private readonly http = inject(HttpClient);
  private readonly destroyRef = inject(DestroyRef);

  matCardAlertMode = MatCardAlertMode;

  selectedProductAttributeTemplatesDataSource: MatTableDataSource<ProductAttributeTemplate> = new MatTableDataSource<ProductAttributeTemplate>([]);
  displayedColumns: string[] = [
    'productAttributeTemplateName',
    'quantityUnitDisplayName',
    'quantityUnitDisplayAbbreviation',
    'actions',
  ];

  productId: number = this.data.productId;
  tenantId: number = this.data.tenantId;

  selectedProductAttributeGroupControl: FormControl<ProductAttributeGroup['id']> = new FormControl();
  selectedProductAttributeTemplateControl: FormControl<ProductAttributeTemplate['id']> = new FormControl();

  ngOnInit(): void {
    this.productAttributeGroupsService.get().subscribe();
    this.productAttributeTemplatesService.get().subscribe();

    this.initializeListeningToForm();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  getProductAttributeTemplateById(id: ProductAttributeTemplate['id']) {
    const options = getHttpOptionsWithInclude('productAttributeType,quantityUnit', {
      tenant_id: this.sessionQuery?.tenantId?.toString(),
    });

    return this.http.get<ApiResponse<ProductAttributeTemplate>>(
      environment.api.baseUrl + 'product-attribute-templates/' + id,
      options
    );
  }

  getProductAttributeTemplatesByProductAttributeGroupId(id: ProductAttributeGroup['id']) {
    const options = getHttpOptionsWithInclude('productAttributeType,quantityUnit', {
      tenant_id: this.sessionQuery?.tenantId?.toString(),
    });

    return this.http.get<ApiResponse<ProductAttributeTemplate[]>>(
      environment.api.baseUrl +
      'product-attribute-groups/' +
      id +
      '/product-attribute-templates',
      options
    );
  }

  removeSelectedAttributeTemplate(id: number): void {
    this.selectedProductAttributeTemplatesDataSource.data = this.selectedProductAttributeTemplatesDataSource.data.filter(
      selectedProductAttributeTemplate => selectedProductAttributeTemplate.id !== id
    );
    this.selectedProductAttributeTemplatesDataSource._updateChangeSubscription();
  }

  add(): void {
    const productAttributes: ProductAttribute[] = [];
    this.selectedProductAttributeTemplatesDataSource.data.forEach(selectedProductAttributeTemplate => {
      productAttributes.push({
        id: null,
        value: '',
        tenant_id: this.tenantId,
        product_id: this.productId,
        product_attribute_template_id: selectedProductAttributeTemplate.id,
        product_attribute_template: selectedProductAttributeTemplate,
      } as ProductAttribute);
    });
    this.dialogRef.close(productAttributes);
  }

  private initializeListeningToForm() {
    // Listen to updates of the group of templates
    this.selectedProductAttributeGroupControl.valueChanges.pipe(
      filter(selectedProductAttributeGroup => !!selectedProductAttributeGroup),
      switchMap(selectedProductAttributeGroupId => this.getProductAttributeTemplatesByProductAttributeGroupId(selectedProductAttributeGroupId).pipe(
        tap(response => {
          const productAttributeTemplates = response.data;

          if (productAttributeTemplates) {
            productAttributeTemplates.forEach((productAttributeTemplate) => {
              if (
                !this.selectedProductAttributeTemplatesDataSource.data.find(
                  (selectedProductAttributeTemplate) => selectedProductAttributeTemplate.id === productAttributeTemplate.id
                )
              ) {
                this.selectedProductAttributeTemplatesDataSource.data.push(productAttributeTemplate);
                this.selectedProductAttributeTemplatesDataSource._updateChangeSubscription();
              }
            });
          }

          this.selectedProductAttributeGroupControl.reset();
        }),
      )),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();

    // Listen to updates of the template
    this.selectedProductAttributeTemplateControl.valueChanges.pipe(
      filter(selectedProductAttributeTemplateId => !!selectedProductAttributeTemplateId),
      switchMap(selectedProductAttributeTemplateId => this.getProductAttributeTemplateById(selectedProductAttributeTemplateId).pipe(
          tap(response => {
            const responseProductAttributeTemplate = response.data;

            if (
              responseProductAttributeTemplate &&
              !this.selectedProductAttributeTemplatesDataSource.data.find(
                (selectedProductAttributeTemplate) => selectedProductAttributeTemplate.id === responseProductAttributeTemplate.id
              )
            ) {
              this.selectedProductAttributeTemplatesDataSource.data.push(responseProductAttributeTemplate);
              this.selectedProductAttributeTemplatesDataSource._updateChangeSubscription();
            }

            this.selectedProductAttributeTemplateControl.reset();
          }),
        )
      ),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
  }
}
