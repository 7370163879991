import {AddressSelectionComponent} from '../../address-selection.component';

export const getAddressPreviewList = ([selectedAddress, addresses]) => {
  const partialAddresses = addresses
    .slice(0, AddressSelectionComponent.previewAmount);

  if (selectedAddress === null) {
    return [...partialAddresses];
  }

  const isSelectedAddressInPartialAddresses = partialAddresses.find(shippingAddress => shippingAddress.id === selectedAddress.id);

  if (isSelectedAddressInPartialAddresses) {
    return [...partialAddresses];
  }

  // Add the selected shipping address to the beginning of the list
  return [
    selectedAddress,
    ...partialAddresses.slice(0, AddressSelectionComponent.previewAmount - 1),
  ];
};
