import {inject, Pipe, PipeTransform} from '@angular/core';
import {CostCentersQuery} from '../state/cost-centers/cost-centers.query';

@Pipe({
  name: 'costCenterById$'
})
export class CostCenterByIdPipe implements PipeTransform {
  private readonly costCentersQuery = inject(CostCentersQuery);

  transform(costCenterId: number) {
    return this.costCentersQuery.selectCostCenter(costCenterId);
  }
}
