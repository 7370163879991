import {handleError} from '@/shared';
import {ApiResponse} from '@/shared/types/api/api-response';
import {backoffRetry} from '@/shared/utils/functions/rxjs-operators';
import {DOCUMENT} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {DestroyRef, inject, Injectable, RendererFactory2} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatSnackBar} from '@angular/material/snack-bar';
import {fromEvent,} from 'rxjs';
import {catchError, first, switchMap, tap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {SleekplanStore} from './sleekplan.store';
import {SleekplanSdk} from './types/sleekplan-sdk';
import {TokenApiResponse} from './types/token-api-response';

// Add typings for global $sleek object on window
declare global {
  interface Window {
    $sleek: SleekplanSdk;
    SLEEK_PRODUCT_ID: number;
  }
}

@Injectable({providedIn: 'root'})
export class SleekplanService {
  private readonly rendererFactory = inject(RendererFactory2);
  private readonly document = inject(DOCUMENT);
  private readonly http = inject(HttpClient);
  private readonly destroyRef = inject(DestroyRef);
  private readonly sleekplanStore = inject(SleekplanStore);
  private readonly snackbar = inject(MatSnackBar);

  private get isSleekplanActive() {
    return !!environment.sleekplan?.appUrl && !!environment.sleekplan?.productId;
  }

  private renderer = this.rendererFactory.createRenderer(null, null);

  openFeedback() {
    this.sleekplanStore.instance?.open();
  }

  openCreateFeedback() {
    this.sleekplanStore.instance?.open('feedback.add');
  }

  initializeSleekplan() {
    if (!this.isSleekplanActive) {
      return;
    }

    // Default values needed for sleekplan sdk (based on documentation)
    window.$sleek = [] as never;
    window.SLEEK_PRODUCT_ID = environment.sleekplan.productId;

    // Initialize listeners & user after sdk has been initialized
    fromEvent(this.document, 'sleek:init').pipe(
      first(),
      tap(() => this.sleekplanStore.update({instance: window.$sleek})),
      tap(() => this.initializeListenersOnInstance()),
      switchMap(() => this.initializeUser()),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();

    this.loadSleekplanSdk();
  }

  destroySleekplan() {
    this.sleekplanStore.instance?.shutdown();
  }

  private initializeUser() {
    return this.getSleekplanSSOToken().pipe(
      tap(({data}) => this.sleekplanStore.setUserOnInstance(data.token)),
    );
  }

  private getSleekplanSSOToken() {
    return this.http.get<ApiResponse<TokenApiResponse>>(environment.api.baseUrl + 'custom/apps/sleekplan/token').pipe(
      backoffRetry({count: 3, delay: 2000}),
      tap(({data}) => this.sleekplanStore.update({ssoToken: data.token})),
      catchError(error => handleError(error, this.snackbar, this.sleekplanStore)),
    );
  }

  private loadSleekplanSdk() {
    const script: HTMLScriptElement = this.renderer.createElement('script');
    this.renderer.setAttribute(script, 'src', environment.sleekplan.scriptUrl);
    this.renderer.setAttribute(script, 'async', '1');
    this.renderer.appendChild(this.document.head, script);
  }

  private initializeListenersOnInstance() {
    this.sleekplanStore.instance.on('widget_init', () => this.sleekplanStore.update({loading: true}));
    this.sleekplanStore.instance.on('open', () => this.sleekplanStore.update({
      widgetOpen: true,
      loading: false,
    }));
    this.sleekplanStore.instance.on('close', () => this.sleekplanStore.update({widgetOpen: false}));
  }
}
