<div
  class="dropzone flex flex-col justify-center items-center"
  [style.background-image]="'url(' + backgroundImage + ')'"
  (click)="uploadFilesInput.click()"
  appDragAndDrop
  (fileDrop)="uploadFile($event)"
  tabindex="0">
    <div class="icon-container">
        <mat-icon>cloud_upload</mat-icon>
    </div>
    <div class="mat-subtitle-2">Drag & drop Datei hier</div>
    <div class="mat-subtitle-2">oder</div>

    <button
      class="upload-files-button"
      mat-flat-button
      color="primary">
        <span>Dateien auswählen</span>
        <input
          class="upload-files-input"
          #uploadFilesInput
          type="file"
          (change)="uploadFile($event.target.files)" />
    </button>
</div>
