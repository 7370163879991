<div matDialogTitle>
    Bestellung freigeben oder ablehnen.
</div>

<mat-dialog-content>
    <form [formGroup]="approvalForm">
        <div>
            <div class="flex justify-center">
                <div class="flex-[0_0_55%]">
                    <mat-card
                      (click)="approved = true;"
                      [class]="(approved === true ? 'option-selected-approved ' : '') + 'm-2 mat-elevation-z0'">
                        <mat-card-header class="flex">
                            <div>
                                <mat-card-title>Freigabe</mat-card-title>
                                <mat-card-subtitle>Geben Sie diese Bestellung frei.</mat-card-subtitle>
                            </div>

                            <div class="flex flex-[1_0_0%] justify-end items-center">
                                <mat-icon>check_circle_outline</mat-icon>
                            </div>
                        </mat-card-header>
                    </mat-card>

                    <mat-card
                      (click)="approved = false;"
                      [class]="(approved === false? 'option-selected-rejected ' : '') + 'm-2 mat-elevation-z0'">
                        <mat-card-header class="flex">
                            <div>
                                <mat-card-title>Ablehnen</mat-card-title>
                                <mat-card-subtitle>Lehnen Sie diese Bestellung ab.</mat-card-subtitle>
                            </div>
                            <div class="flex flex-[1_0_0%] justify-end items-center">
                                <mat-icon>highlight_off</mat-icon>
                            </div>
                        </mat-card-header>
                    </mat-card>

                    <div class="flex justify-center items-center m-2 mat-elevation-z0">
                        <dqn-form-field
                          class="w-full"
                          hintText="Maximale Länge: 255 Zeichen"
                          hintTextEnd="{{ descriptionInput.value?.length || 0 }}/255">
                            <dqn-label>Kommentar</dqn-label>
                            <textarea
                              #descriptionInput
                              dqnInput
                              formControlName="comment"
                              rows="4"
                              maxlength="255"></textarea>
                        </dqn-form-field>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div
      class="my-2 flex justify-center">
        <div class="mat-small approval-dialog-info">
            <div class="mat-body-strong">Wichtig:</div>
            <div>
                Nachdem Sie Ihre Auswahl gespeichert haben, können Sie diese persönlich nicht mehr
                rückgängig machen.
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions
  align="center"
  class="flex justify-center items-center gap-2">
    <button
      (click)="save()"
      [disabled]="approved === undefined"
      color="accent"
      class="flex-[0_0_60%]"
      mat-flat-button>
        Speichern
    </button>
    <button
      (click)="cancel()"
      class="flex-[0_0_60%] !ml-0"
      color="accent"
      mat-button>Abbrechen
    </button>
</mat-dialog-actions>
