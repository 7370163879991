import {Injectable} from '@angular/core';
import {UiStore} from './ui.store';

@Injectable({providedIn: 'root'})
export class UiService {

  constructor(private uiStore: UiStore) {
  }

  toggleSidenavState(sidenavState) {
    this.uiStore.update({isSidenavOpen: sidenavState});
  }

  updateNavigationMode(value) {
    this.uiStore.update({navigationMode: value});
  }
}
