<div
  class="mat-headline-5 mat-typography"
  mat-dialog-title>Varianten erstellen
</div>

<mat-dialog-content>
    <div class="mat-subtitle-1">Welche Varianten sollen erstellt werden?</div>

    <dqn-table
      size="small"
      [rows]="data.variants"
      [headers]="variantHeaders"
      selectionMode="multi"
      (selectionChange)="updateSelection($event)">
        <td
          *dqnTableCellTemplate="'name'; let variant"
          dqnTableCell>
            {{ variant.product_attributes | nameFromProductAttributes }}
    </dqn-table>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button
      mat-button
      color="primary"
      (click)="cancel()">Abbrechen
    </button>

    <button
      mat-raised-button
      color="primary"
      [disabled]="false"
      (click)="save()">
        Anlegen
    </button>
</mat-dialog-actions>
