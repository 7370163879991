import {Pipe, PipeTransform} from '@angular/core';
import {AbstractControl, UntypedFormControl, Validators} from '@angular/forms';

@Pipe({
  name: 'isFormControlRequired'
})
export class IsFormControlRequiredPipe implements PipeTransform {
  transform(formControl: UntypedFormControl | AbstractControl) {
    return formControl.hasValidator(Validators.required);
  }
}
