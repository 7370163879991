import {SessionQuery} from '@/core/session/state/session.query';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ID, setLoading} from '@datorama/akita';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {ApiResponse} from '../../types/api/api-response';
import {handleError} from '../../utils';
import {getHttpOptionsWithParams} from '../../utils/functions/http-params';
import {BillingFrequenciesStore} from './billing-frequencies.store';
import {BillingFrequency} from './billing-frequency.model';

@Injectable({providedIn: 'root'})
export class BillingFrequenciesService {

  private readonly billingFrequenciesStore = inject(BillingFrequenciesStore);
  private readonly sessionQuery = inject(SessionQuery);
  private readonly http = inject(HttpClient);
  private readonly snackBar = inject(MatSnackBar);

  get() {
    const options = getHttpOptionsWithParams({
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http
      .get<ApiResponse<BillingFrequency[]>>(environment.api.baseUrl + 'billing-frequencies', options)
      .pipe(
        setLoading(this.billingFrequenciesStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.billingFrequenciesStore)),
        tap((response) => {
          this.billingFrequenciesStore.set(response['data']);
        }),
      );
  }

  add(billingFrequency: BillingFrequency) {
    this.billingFrequenciesStore.add(billingFrequency);
  }

  update(id, billingFrequency: Partial<BillingFrequency>) {
    this.billingFrequenciesStore.update(id, billingFrequency);
  }

  remove(id: ID) {
    this.billingFrequenciesStore.remove(id);
  }

}
