import {SessionQuery} from '@/core/session/state/session.query';
import {ApiResponse} from '@/shared/types/api/api-response';
import {handleError} from '@/shared/utils';
import {
  createHttpIncludes,
  getHttpOptionsWithInclude,
  getHttpOptionsWithParams
} from '@/shared/utils/functions/http-params';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {setLoading} from '@datorama/akita';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {
  CostCenterProductCategory,
  CostCenterProductCategoryPivot,
  ProductCategoryCostCenter
} from './cost-center-product-category-pivot.model';
import {CostCenterProductCategoryPivotsQuery} from './cost-center-product-category-pivots.query';
import {CostCenterProductCategoryPivotsStore} from './cost-center-product-category-pivots.store';

@Injectable({providedIn: 'root'})
export class CostCenterProductCategoryPivotsService {
  static readonly includes = createHttpIncludes([
    'productCategory',
    'costCenter',
  ]);

  private readonly costCenterProductCategoriesQuery = inject(CostCenterProductCategoryPivotsQuery);
  private readonly costCenterProductCategoriesStore = inject(CostCenterProductCategoryPivotsStore);
  private readonly sessionQuery = inject(SessionQuery);
  private readonly http = inject(HttpClient);
  private readonly snackBar = inject(MatSnackBar);

  get() {
    throw new Error('Not implemented!');
  }

  add(costCenterProductCategory: CostCenterProductCategoryPivot) {
    const options = getHttpOptionsWithInclude(CostCenterProductCategoryPivotsService.includes, {
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http.post<ApiResponse<CostCenterProductCategory>>(
      environment.api.baseUrl + 'cost-center-product-categories',
      costCenterProductCategory,
      options
    ).pipe(
      setLoading(this.costCenterProductCategoriesStore),
      catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.costCenterProductCategoriesStore)),
      tap(({data}) => {
        this.costCenterProductCategoriesStore.upsert(costCenterProductCategory.id, data.pivot);
      }),
    );
  }

  update(costCenterProductCategory: CostCenterProductCategoryPivot) {
    const options = getHttpOptionsWithInclude(CostCenterProductCategoryPivotsService.includes, {
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http.put<ApiResponse<CostCenterProductCategory>>(
      environment.api.baseUrl + 'cost-center-product-categories/' + costCenterProductCategory.id,
      costCenterProductCategory,
      options
    ).pipe(
      setLoading(this.costCenterProductCategoriesStore),
      catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.costCenterProductCategoriesStore)),
      tap(({data}) => {
        this.costCenterProductCategoriesStore.upsert(costCenterProductCategory.id, data.pivot);
      }),
    );
  }

  remove(costCenterProductCategoryId: CostCenterProductCategoryPivot['id']) {
    const options = getHttpOptionsWithParams({
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http.delete<ApiResponse>(
      environment.api.baseUrl + 'cost-center-product-categories/' + costCenterProductCategoryId,
      options
    ).pipe(
      setLoading(this.costCenterProductCategoriesStore),
      catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.costCenterProductCategoriesStore)),
      tap(() => {
        // Remove cost center product category
        if (this.costCenterProductCategoriesQuery.hasEntity(costCenterProductCategoryId)) {
          this.costCenterProductCategoriesStore.remove(costCenterProductCategoryId);
        }
      }),
    );
  }

  setFromCostCenterProductCategories(costCenterProductCategories: CostCenterProductCategory[]) {
    this.costCenterProductCategoriesStore.set(costCenterProductCategories.map(costCenterProductCategory => costCenterProductCategory.pivot));
  }

  setFromProductCategoryCostCenters(productCategoryCostCenters: ProductCategoryCostCenter[]) {
    this.costCenterProductCategoriesStore.set(productCategoryCostCenters.map(productCategoryCostCenter => productCategoryCostCenter.pivot));
  }
}
