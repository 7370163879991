import {ProductAttribute} from '@/management/product/state/product-attributes/product-attribute.model';
import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {DqnTableHeader} from '@dqn/components/table';
import {FormModeDirective, FormModeHostDirective} from '../../../../directives/form-mode.directive';

@Component({
  selector: 'app-product-attributes-table',
  templateUrl: './product-attributes-table.component.html',
  styleUrls: ['./product-attributes-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [
    FormModeHostDirective,
  ]
})
export class ProductAttributesTableComponent {
  readonly formMode = inject(FormModeDirective, {self: true});

  @Input({required: true}) productAttributes: ProductAttribute;

  @Output() removeProductAttribute = new EventEmitter<ProductAttribute['product_attribute_template_id']>();
  @Output() updateProductAttribute = new EventEmitter<Pick<ProductAttribute, 'value' | 'product_attribute_template_id'>>();

  productAttributeHeaders: DqnTableHeader[] = [
    {key: 'productAttributeTemplateName', title: 'Name'},
    {key: 'value', title: 'Wert'},
    {key: 'quantityUnitDisplayName', title: 'Mengeneinheit'},
    {key: 'quantityUnitDisplayAbbreviation', title: 'Mengeneinheit-Abkürzung'},
  ];
}
