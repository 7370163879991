export enum OrderActivityName {
  Created = 'Created',
  Canceled = 'Canceled',
  Rejected = 'Rejected',
  Approved = 'Approved',
  Finished = 'Finished',
  Failed = 'Failed',
  Started = 'Started',
  ApprovalWorkflowStarted = 'approval-workflow-started',
  ApprovalWorkflowActivity = 'approval-workflow-activity',
  ApprovalWorkflowFinished = 'approval-workflow-finished',
  ItemsUpdated = 'items-updated',
}
