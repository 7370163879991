import {ProductCategory} from '@/shop/product/state/product-categories/product-category.model';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-sidenav-category-tree',
  templateUrl: './sidenav-category-tree.component.html',
  styleUrls: ['./sidenav-category-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavCategoryTreeComponent {
  @Input() indentation?: number;
  @Input() childProductCategories: ProductCategory[];

  @Output() productCategoryClick = new EventEmitter<ProductCategory['id']>();
}
