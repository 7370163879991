// eslint-disable-next-line @typescript-eslint/no-explicit-any
import {defaultPlaceholderImageUrl} from '@/core/constants/assets';

// TODO: Add generic type for images across models (e.g. ProductImage, OrderItemImage)
export const getDefaultImageUrl = (images: any) => {
  let imageUrl = defaultPlaceholderImageUrl;

  if (images) {
    for (const image of images) {
      if (image.name === 'default') {
        imageUrl = image.urls.original;
      }
    }
  }

  return imageUrl;
};
