import {Address} from '@/shop/shopping-cart/state/address/address.model';
import {AddressesQuery} from '@/shop/shopping-cart/state/address/addresses.query';
import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material/dialog';
import {combineLatest, Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {AddressSelectionDialogData} from './models/address-selection-dialog-data.model';
import {AddressSelectionDialogOutputData} from './models/address-selection-dialog-output-data.model';

@Component({
  selector: 'app-address-selection-dialog',
  templateUrl: './address-selection-dialog.component.html',
  styleUrls: ['./address-selection-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressSelectionDialogComponent implements OnInit {
  addressSearchControl: UntypedFormControl;

  addresses$: Observable<Address[]>;
  filteredAddresses$: Observable<Address[]>;

  get selectedAddress() {
    return this.data.selectedAddress;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: AddressSelectionDialogData,
    private matDialogRef: MatDialogRef<AddressSelectionDialogComponent>,
    private shippingAddressesQuery: AddressesQuery,
  ) {
  }

  ngOnInit() {
    this.initialize();
  }

  selectAddress(address: Address) {
    this.matDialogRef.close({selectedAddress: address} as AddressSelectionDialogOutputData);
  }

  private initialize() {
    this.addressSearchControl = new UntypedFormControl('');
    this.addresses$ = this.shippingAddressesQuery.addresses$;

    this.filteredAddresses$ = combineLatest([
      this.addresses$,
      this.addressSearchControl.valueChanges.pipe(
        startWith(''),
      ),
    ]).pipe(
      map(([addresses, searchTerm]) => addresses.filter(address =>
        address.street.includes(searchTerm)
        || address.recipient?.includes(searchTerm)
        || address.company_organization?.includes(searchTerm)
        || address.city?.includes(searchTerm)
        || address.house_number?.includes(searchTerm)
        || address.postal_code?.includes(searchTerm)
        || address.additional_address_information?.includes(searchTerm)))
    );
  }
}
