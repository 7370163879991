import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  EventEmitter,
  Injector,
  Input,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {ApprovalWorkflowComponentInterface} from '../../interfaces/approval-workflow-component.interface';
import {ApprovalWorkflow} from '../../state/approval-workflows/approval-workflow.model';
import {
  NoApprovalApprovalWorkflowComponent
} from './components/no-approval-approval-workflow/no-approval-approval-workflow.component';
import {
  ProductCategoriesAndCostCenterApprovalWorkflowComponent
} from './components/product-categories-and-cost-center/product-categories-and-cost-center-approval-workflow.component';
import {
  ProductCategoriesAndCostCentersApprovalWorkflowComponent
} from './components/product-categories-and-cost-centers/product-categories-and-cost-centers-approval-workflow.component';

type ApprovalWorkflowComponentFactory =
  ComponentFactory<
    ProductCategoriesAndCostCentersApprovalWorkflowComponent |
    ProductCategoriesAndCostCenterApprovalWorkflowComponent |
    NoApprovalApprovalWorkflowComponent
  > | null;
export type ApprovalWorkflowData = ProductCategoriesAndCostCenterApprovalWorkflowComponent['approvalWorkflowData'] | NoApprovalApprovalWorkflowComponent['approvalWorkflowData'];

@Component({
  selector: 'app-approval-workflow-container',
  templateUrl: './approval-workflow-container.component.html',
  styleUrls: ['./approval-workflow-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApprovalWorkflowContainerComponent implements AfterViewInit, ApprovalWorkflowComponentInterface {
  @Input() approvalWorkflow: ApprovalWorkflow;
  @Input() approvalWorkflowData: ApprovalWorkflowData;
  @Input() showTitle = false;

  @Output() approvalWorkflowDataChange = new EventEmitter<ApprovalWorkflowData>();
  @Output() validityChange = new EventEmitter<boolean>();

  @ViewChild('approvalWorkflowOutlet', {read: ViewContainerRef}) approvalWorkflowOutlet: ViewContainerRef;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private cd: ChangeDetectorRef
  ) {
  }

  ngAfterViewInit(): void {
    if (!this.approvalWorkflow) {
      return;
    }

    this.instantiateApprovalWorkflowComponent();
  }

  private instantiateApprovalWorkflowComponent(): void {
    const approvalWorkflowComponentFactory = this.selectApprovalWorkflowComponentFactory();

    if (approvalWorkflowComponentFactory !== null) {
      this.approvalWorkflowOutlet.detach();

      const approvalWorkflowComponentInstance = approvalWorkflowComponentFactory.create(this.injector);
      approvalWorkflowComponentInstance.instance.showTitle = this.showTitle;
      approvalWorkflowComponentInstance.instance.approvalWorkflowData = this.approvalWorkflowData;
      approvalWorkflowComponentInstance.instance.approvalWorkflowDataChange.subscribe(
        (data) => this.approvalWorkflowDataChange.emit(data)
      );
      approvalWorkflowComponentInstance.instance.validityChange.subscribe(
        (validity) => this.validityChange.emit(validity)
      );

      this.approvalWorkflowOutlet.insert(approvalWorkflowComponentInstance.hostView);
      this.cd.detectChanges();
    }
  }

  private selectApprovalWorkflowComponentFactory(): ApprovalWorkflowComponentFactory {
    switch (this.approvalWorkflow.name) {
      case NoApprovalApprovalWorkflowComponent.approvalWorkflowName:
        return this.componentFactoryResolver.resolveComponentFactory(NoApprovalApprovalWorkflowComponent);
      case ProductCategoriesAndCostCenterApprovalWorkflowComponent.approvalWorkflowName:
        return this.componentFactoryResolver.resolveComponentFactory(ProductCategoriesAndCostCenterApprovalWorkflowComponent);
      case ProductCategoriesAndCostCentersApprovalWorkflowComponent.approvalWorkflowName:
        return this.componentFactoryResolver.resolveComponentFactory(ProductCategoriesAndCostCentersApprovalWorkflowComponent);
      default:
        return null;
    }
  }
}
