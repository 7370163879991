<div class="flex flex-col gap-1">
    <div
      *ngIf="!formMode.isReadonly"
      class="flex justify-end">
        <button
          mat-raised-button
          color="primary"
          (click)="openSupplierProductAddDialog()">
            Bezugsquelle hinzufügen
        </button>
    </div>

    <dqn-table
      size="small"
      [headers]="supplierProductHeaders"
      [rows]="(supplierProducts$ | async) ?? []">
        <td
          dqnTableCell
          *dqnTableCellTemplate="'name'; let supplierProduct">
            {{ (supplierProduct.supplier_id | supplierById | async)?.name }}
        </td>

        <td
          dqnTableCell
          *dqnTableCellTemplate="'order-number'; let supplierProduct">
            {{ supplierProduct.supplier_order_number }}
        </td>

        <td
          dqnTableCell
          *dqnTableCellTemplate="'unit-price'; let supplierProduct">
            {{
            supplierProduct.unit_price
              ? (supplierProduct.unit_price | priceToHighestCurrencyValue | currency: (supplierProduct.currency_code_id | currencyCodeById | async)?.name)
              : '-'
            }}
        </td>

        <td
          dqnTableCell
          *dqnTableCellTemplate="'billing-frequency-price'; let supplierProduct">
            {{
            supplierProduct?.billing_frequency_price
              ? (supplierProduct?.billing_frequency_price | priceToHighestCurrencyValue | currency: (supplierProduct?.billing_frequency_currency_code_id | currencyCodeById | async)?.name)
              : '-'
            }}
        </td>

        <td
          dqnTableCell
          *dqnTableCellTemplate="'status'; let supplierProduct">
            <mat-chip-list
              *ngIf="supplierProduct.status_id | supplierProductStatusById | async as supplierProductStatus"
              class="status">
                <mat-chip
                  [class]="supplierProductStatus.status?.name"
                  [selected]="supplierProductStatus.status?.name === 'active' || supplierProductStatus.status?.name === 'inactive'">
                    {{ supplierProductStatus.status?.display_name }}
                </mat-chip>
            </mat-chip-list>
        </td>

        <td
          dqnTableCell
          *dqnTableCellTemplate="'promoted'; let supplierProduct">
            <mat-slide-toggle
              [checked]="!!supplierProduct.promoted_order_number"
              color="primary"
              disabled>
            </mat-slide-toggle>
        </td>

        <ng-container *ngIf="!formMode.isReadonly">
            <dqn-table-row-actions *dqnTableRowAction="let supplierProduct">
                <dqn-table-row-action-edit
                  text="Bearbeiten"
                  (click)="openSupplierProductEditDialog(supplierProduct)" />
                <dqn-table-row-action-delete
                  text="Löschen"
                  (click)="deleteSupplierProduct(supplierProduct)" />
            </dqn-table-row-actions>
        </ng-container>
    </dqn-table>
</div>
