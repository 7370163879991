import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {
  addOrUpdateOrderItemInIndividualCostCenterMappings
} from '../../order/state/helpers/add-or-update-order-item-in-individual-cost-center-mappings';
import {OrderApprovalWorkflowFormData} from '../../order/state/order.model';
import {SupplierProduct} from '../../product/state/supplier-products/supplier-product.model';
import {ShoppingCartItem} from './shopping-cart.model';

export interface ShoppingCartState extends EntityState<ShoppingCartItem> {
  comments: string;
  approvalWorkflowData: OrderApprovalWorkflowFormData;
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'shoppingCart', idKey: 'supplier_product_id', resettable: true})
export class ShoppingCartStore extends EntityStore<ShoppingCartState> {

  constructor() {
    super({
      loading: false,
      comments: '',
      approvalWorkflowData: null
    });
  }

  updateQuantity(productId: SupplierProduct['id'], quantity: number) {
    this.update(productId, entity => {
      return {
        ...entity,
        quantity: quantity
      };
    });
  }

  updateApprovalWorkflow(productId: SupplierProduct['id'], costCenterId: ShoppingCartItem['cost_center_id']) {
    this.update(productId, entity => {
      return {
        ...entity,
        cost_center_id: costCenterId
      };
    });

    this.updateApprovalWorkflowMapping(productId, costCenterId);
  }

  private updateApprovalWorkflowMapping(productId: SupplierProduct['id'], costCenterId: ShoppingCartItem['cost_center_id']) {
    this.update(state => ({
      approvalWorkflowData: addOrUpdateOrderItemInIndividualCostCenterMappings({
        costCenterId,
        supplierProductId: productId,
        existingApprovalWorkflowData: state.approvalWorkflowData,
      }),
    }));
  }
}
