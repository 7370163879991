<div class="mat-headline-5">Impressum</div>

<mat-divider class="my-2"></mat-divider>
<p>
    <span class="organization-title">enthus Managed Services GmbH</span>
</p>
<p>
    Felix-Wankel-Straße 4<br/>
    D-97526 Sennfeld
</p>
<p>
    Telefon: <a href="tel:+49 9721 675 94 10"> +49 9721 675 94 10 </a><br/>
    Telefax: <a href="tel:+49 9721 675 94 11"> +49 9721 675 94 11 </a><br/>
    E-Mail: <a href="mailto:sales.services@enthus.de"> sales.services&#64;enthus.de </a>
</p>
<p>
    Geschäftsführer: Christian Krug, Dr. Dominik Englert, Christian Uhl
</p>
<p>
    HRB 5013 Amtsgericht Schweinfurt<br/>
    UstID DE250526064
</p>
