<div matDialogTitle>
    <div class="flex justify-between items-start gap-1">
        <div class="mat-headline-5">
            Artikelmerkmale hinzufügen
        </div>
        <img
          src="../../../../assets/lightbulb_moment.svg"
          alt="" />
    </div>
</div>

<mat-dialog-content>
    <div class="mb-4 mt-2">
        <app-mat-card-alert
          text="Ergänzen Sie hier alle relevanten Artikelmerkmale. Diese Artikelmerkmale beschreiben den Artikel genauer und liefern die Grundlage für eine Kaufentscheidung."
          icon="info"
          [mode]="matCardAlertMode.accent" />
    </div>

    <div>
        <div>
            <app-title-icon
              text="Vorlage auswählen"
              icon="topic" />
            <dqn-form-field class="w-full">
                <dqn-label>Vorlage auswählen</dqn-label>

                <dqn-combobox
                  [options]="(productAttributeGroupsQuery.productAttributeGroupsAsDqnOptions$ | async) ?? []"
                  [formControl]="selectedProductAttributeGroupControl"
                  required />
            </dqn-form-field>
        </div>
        <div>
            <app-title-icon
              text="Artikelmerkmale auswählen"
              icon="add_box" />

            <dqn-form-field class="w-full">
                <dqn-label>Vorlage auswählen</dqn-label>

                <dqn-combobox
                  [options]="(productAttributeTemplatesQuery.productAttributeTemplatesAsDqnOptions$ | async) ?? []"
                  [formControl]="selectedProductAttributeTemplateControl"
                  required />
            </dqn-form-field>
        </div>

        <div>
            <app-title-icon
              text="Liste der gewählten Merkmale"
              icon="list_alt" />
            <table
              mat-table
              [dataSource]="selectedProductAttributeTemplatesDataSource">
                <ng-container matColumnDef="productAttributeTemplateName">
                    <th
                      mat-header-cell
                      *matHeaderCellDef>Name
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element">
                        {{ element?.display_name }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="quantityUnitDisplayName">
                    <th
                      mat-header-cell
                      *matHeaderCellDef>Mengeneinheit
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element">
                        {{ element?.quantity_unit?.display_name }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="quantityUnitDisplayAbbreviation">
                    <th
                      mat-header-cell
                      *matHeaderCellDef>Abkürzung
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element">
                        {{ element?.quantity_unit?.display_abbreviation }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      class="actions"></th>
                    <td
                      mat-cell
                      *matCellDef="let element">
                        <button
                          mat-icon-button
                          color="primary"
                          (click)="removeSelectedAttributeTemplate(element.id)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumns; sticky: true"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button
      mat-button
      color="primary"
      (click)="cancel()">Abbrechen
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="add()">
        Hinzufügen
    </button>
</mat-dialog-actions>
