import {SessionQuery} from '@/core/session/state/session.query';
import {ApiResponse} from '@/shared/types/api/api-response';
import {handleError} from '@/shared/utils';
import {getHttpOptionsWithParams} from '@/shared/utils/functions/http-params';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ID, setLoading} from '@datorama/akita';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {BaseAddress} from '../address/address.model';
import {UserAddressesStore} from './user-addresses.store';

@Injectable({providedIn: 'root'})
export class UserAddressesService {
  private readonly userShippingAddressesStore = inject(UserAddressesStore);
  private readonly sessionQuery = inject(SessionQuery);
  private readonly http = inject(HttpClient);
  private readonly snackBar = inject(MatSnackBar);

  getAddressesForCurrentUser() {
    return this.getShippingAddressesByUserId(this.sessionQuery.userId);
  }

  getShippingAddressesByUserId(userId: number) {
    const options = getHttpOptionsWithParams({
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http
      .get<ApiResponse<BaseAddress[]>>(environment.api.baseUrl + 'users/' + userId + '/addresses', options)
      .pipe(
        setLoading(this.userShippingAddressesStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.userShippingAddressesStore)),
        tap(({data: addresses}) => {
          this.userShippingAddressesStore.set(addresses);
        }),
      );
  }

  deleteOnDbForCurrentUser(addressId: number) {
    const options = getHttpOptionsWithParams({
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http
      .delete<ApiResponse<BaseAddress>>(environment.api.baseUrl + 'users/' + this.sessionQuery.userId + '/addresses/' + addressId, options)
      .pipe(
        setLoading(this.userShippingAddressesStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.userShippingAddressesStore)),
        tap(() => {
          this.remove(addressId);

          this.snackBar.open('Persönliche Adresse wurde erfolgreich gelöscht!', 'Schließen', {panelClass: 'snackbar-success'});
        }),
      );
  }

  add(address: BaseAddress) {
    this.userShippingAddressesStore.add(address);
  }

  upsert(id, address: Partial<BaseAddress>) {
    this.userShippingAddressesStore.upsert(id, address);
  }

  remove(id: ID) {
    this.userShippingAddressesStore.remove(id);
  }
}
