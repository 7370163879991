<div
  id="footer"
  class="container">
    <div class="column">
        <div
          class="mat-caption"
          id="upperSpace">
            <p class="light-text">Copyright © {{ currentYear }}
                <span class="normal-text">enthus Managed Services GmbH.</span> Alle Rechte
                vorbehalten
            </p>
            <div>
                <div>
                    <p class="normal-text">
                        <a
                          class="nav-link"
                          routerLink="/privacy-policy">Datenschutz</a> |
                        <a
                          class="nav-link"
                          routerLink="/imprint">Impressum</a> |
                        <a
                          class="nav-link"
                          routerLink="/shop/faq">FAQ</a>

                        <ng-container *ngIf="sleekplanQuery.isInitialized$ | async">
                            <a
                              class="nav-link"
                              [href]="sleekplanQuery.feedbackLink$ | async"
                              target="_blank"> | Feedback</a>
                            <a
                              class="nav-link"
                              [href]="sleekplanQuery.changelogLink$ | async"
                              target="_blank"> | Changelog</a>
                        </ng-container>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
