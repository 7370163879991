import {IconDefinition} from '@fortawesome/pro-duotone-svg-icons';
import {
  faArrowLeft as faArrowLeftRegular,
  faBuilding as faBuildingRegular,
  faCircleInfo as faCircleInfoRegular,
  faGrid as faGridRegular,
  faLightbulbOn as faLightbulbOnRegular,
  faLock as faLockRegular,
  faSearch as faSearchRegular,
  faSpinnerThird as faSpinnerThirdRegular,
  faUserCircle as faUserCircleRegular,
} from '@fortawesome/pro-regular-svg-icons';
import {faCrown as faCrownSolid} from '@fortawesome/pro-solid-svg-icons';

export const getFontawesomeIcons = () => [
  // Solid
  faCrownSolid,
  // Regular
  faCircleInfoRegular,
  faLightbulbOnRegular,
  faSpinnerThirdRegular,
  faUserCircleRegular,
  faBuildingRegular,
  faLockRegular,
  faGridRegular,
  faArrowLeftRegular,
  faSearchRegular,
] as IconDefinition[];
