import {Injectable} from '@angular/core';
import {combineQueries, Query} from '@datorama/akita';
import {map} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {SleekplanState, SleekplanStore} from './sleekplan.store';

@Injectable({providedIn: 'root'})
export class SleekplanQuery extends Query<SleekplanState> {
  loading$ = this.select('loading');
  widgetOpen$ = this.select('widgetOpen');

  // Don't allow direct public access to tokens or instance
  private ssoToken$ = this.select('ssoToken');
  private instance$ = this.select('instance');

  feedbackLink$ = this.ssoToken$.pipe(
    map(ssoToken => this.getFeedbackLink(ssoToken)),
  );

  changelogLink$ = this.ssoToken$.pipe(
    map(ssoToken => `${environment.sleekplan.appUrl}changelog?sso=${ssoToken}`),
  );

  isInitialized$ = combineQueries([
    this.ssoToken$,
    this.instance$,
  ]).pipe(
    map(([ssoToken, instance]) => {
      if (!instance || !instance.get || !ssoToken) {
        return false;
      }

      return !!instance.get().user?.user_id;
    })
  );

  feedbackButtonDisabled$ = combineQueries([
    this.loading$,
    this.widgetOpen$,
  ]).pipe(
    map(([loading, widgetOpen]) => loading || widgetOpen),
  )

  constructor(protected store: SleekplanStore) {
    super(store);
  }

  private getFeedbackLink(token: SleekplanState['ssoToken']) {
    if (!token) {
      return '';
    }

    return `${environment.sleekplan.appUrl}?sso=${token}`;
  }
}
