import {SessionQuery} from '@/core/session/state/session.query';
import {ApiResponse} from '@/shared/types/api/api-response';
import {handleError} from '@/shared/utils';
import {createHttpIncludes, getHttpOptionsWithInclude} from '@/shared/utils/functions/http-params';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {setLoading} from '@datorama/akita';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {SupplierProductAvailableStatus} from './supplier-product-available-status.model';
import {SupplierProductAvailableStatusesStore} from './supplier-product-available-statuses.store';

@Injectable({providedIn: 'root'})
export class SupplierProductAvailableStatusesService {
  static readonly includes = createHttpIncludes([
    'status',
  ]);

  private readonly supplierProductAvailableStatusesStore = inject(SupplierProductAvailableStatusesStore);
  private readonly sessionQuery = inject(SessionQuery);
  private readonly http = inject(HttpClient);
  private readonly snackBar = inject(MatSnackBar);

  get() {
    const options = getHttpOptionsWithInclude(SupplierProductAvailableStatusesService.includes, {
      tenant_id: this.sessionQuery?.tenantId?.toString(),
    });

    return this.http.get<ApiResponse<SupplierProductAvailableStatus[]>>(
      environment.api.baseUrl + 'supplier-product-available-statuses', options
    ).pipe(
      setLoading(this.supplierProductAvailableStatusesStore),
      catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.supplierProductAvailableStatusesStore)),
      tap((response) => {
        this.supplierProductAvailableStatusesStore.set(response['data']);
      })
    );
  }
}
