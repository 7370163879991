{
  "name": "prd-einkaufsportal-fe-ang",
  "version": "0.37.6",
  "templateversion": "1.1.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "start:dev": "ng serve -c dev",
    "start:staging": "ng serve -c staging",
    "start:prod": "ng serve -c prod",
    "start-hmr": "ng serve --hmr",
    "start-hmr:dev": "ng serve --hmr -c dev",
    "start-hmr:staging": "ng serve --hmr -c staging",
    "start-hmr:prod": "ng serve --hmr -c prod",
    "build:dev": "ng build -c dev",
    "build:staging": "ng build -c staging",
    "build:prod": "ng build -c prod",
    "lint": "ng lint",
    "lint:fix": "ng lint --fix",
    "test": "ng test",
    "e2e": "ng e2e"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.13",
    "@angular/cdk": "^18.2.14",
    "@angular/common": "^18.2.13",
    "@angular/compiler": "^18.2.13",
    "@angular/core": "^18.2.13",
    "@angular/forms": "^18.2.13",
    "@angular/material": "^18.2.14",
    "@angular/platform-browser": "^18.2.13",
    "@angular/platform-browser-dynamic": "^18.2.13",
    "@angular/router": "^18.2.13",
    "@azure/msal-angular": "^3.1.0",
    "@azure/msal-browser": "^3.27.0",
    "@ckeditor/ckeditor5-angular": "^7.0.1",
    "@ckeditor/ckeditor5-build-classic": "^37.0.1",
    "@datorama/akita": "^8.0.1",
    "@datorama/akita-ng-entity-service": "^8.0.0",
    "@dqn/components": "18.0.0",
    "@dqn/style-kit": "0.0.1",
    "@fortawesome/angular-fontawesome": "^0.15.0",
    "@fortawesome/fontawesome-svg-core": "^6.7.2",
    "@fortawesome/free-brands-svg-icons": "^6.7.2",
    "@fortawesome/pro-duotone-svg-icons": "^6.7.2",
    "@fortawesome/pro-light-svg-icons": "^6.7.2",
    "@fortawesome/pro-regular-svg-icons": "^6.7.2",
    "@fortawesome/pro-solid-svg-icons": "^6.7.2",
    "@ngneat/until-destroy": "^10.0.0",
    "@rx-angular/template": "^18.0.3",
    "@sentry/angular": "^8.52.0",
    "angular-imask": "^7.6.1",
    "ckeditor5-build-classic-dqnow": "file:packages/ckeditor5-build-classic-dqnow",
    "file-saver-es": "^2.0.5",
    "imask": "^7.6.1",
    "moment": "^2.30.1",
    "ngx-pagination": "^6.0.3",
    "rxjs": "~7.8.1",
    "tslib": "^2.3.0",
    "zone.js": "^0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.12",
    "@angular-eslint/builder": "^18.4.1",
    "@angular-eslint/eslint-plugin": "^18.4.1",
    "@angular-eslint/eslint-plugin-template": "^18.4.1",
    "@angular-eslint/schematics": "^18.4.1",
    "@angular-eslint/template-parser": "^18.4.1",
    "@angular/cli": "^18.2.12",
    "@angular/compiler-cli": "^18.2.13",
    "@datorama/akita-ng-router-store": "^7.0.0",
    "@datorama/akita-ngdevtools": "^7.0.0",
    "@sentry/cli": "^2.38.2",
    "@types/file-saver": "^2.0.7",
    "@types/jasmine": "~5.1.0",
    "@typescript-eslint/eslint-plugin": "~8.14.0",
    "@typescript-eslint/parser": "~8.14.0",
    "autoprefixer": "^10.4.17",
    "eslint": "~9.14.0",
    "jasmine-core": "~5.2.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.33",
    "tailwindcss": "^3.4.1",
    "typescript": "~5.5.4"
  },
  "overrides": {
    "@datorama/akita": {
      "tslib": "^2.3.0"
    }
  },
  "engines": {
    "node": ">= 22",
    "npm": ">= 10"
  }
}
