import {ProductAttribute} from '@/management/product/state/product-attributes/product-attribute.model';
import {Injectable} from '@angular/core';
import {Query} from '@datorama/akita';
import {ComponentStore} from '../../../../helpers/component-store/component-store';

interface State {
  initialProductAttributes: ProductAttribute[];
  productAttributes: ProductAttribute[];
}

const getInitialState = (): State => ({
  initialProductAttributes: [],
  productAttributes: [],
});

@Injectable()
export class ProductAttributesManagementStore extends ComponentStore<State> {
  constructor() {
    super(getInitialState(), 'product-attributes-management');
  }
}

@Injectable()
export class ProductAttributesManagementQuery extends Query<State> {
  selectedProductAttribute$ = this.select('productAttributes');

  constructor(protected override store: ProductAttributesManagementStore) {
    super(store);
  }

  getDeletedProductAttributes() {
    const {initialProductAttributes, productAttributes} = this.getValue();

    // Find all attributes that are in the initial attributes but not in the selected anymore
    return initialProductAttributes.filter(({id}) => !productAttributes.some(selectedProductAttribute => selectedProductAttribute.id === id));
  }

  getNewProductAttributes() {
    const {initialProductAttributes, productAttributes} = this.getValue();

    return productAttributes.filter(productAttribute =>
        productAttribute.id === null
        && !initialProductAttributes.some(({product_attribute_template_id, value}) =>
          product_attribute_template_id === productAttribute.product_attribute_template_id
          && value === productAttribute.value
        )
    );
  }

  getUpdatedProductAttributes() {
    const {initialProductAttributes, productAttributes} = this.getValue();

    return productAttributes.filter(productAttribute =>
        productAttribute.id !== null
        // Checking for difference in attributes to reduce amount of requests being sent
        && !initialProductAttributes.some(({id, product_attribute_template_id, value}) =>
          id === productAttribute.id
          && product_attribute_template_id === productAttribute.product_attribute_template_id
          && value === productAttribute.value
        )
    );
  }
}

@Injectable()
export class ProductAttributesManagementService {
  constructor(private store: ProductAttributesManagementStore) {
  }

  addProductAttributes(productAttributes: ProductAttribute[]) {
    this.store.update(({productAttributes: existingProductAttributes}) => ({
      productAttributes: productAttributes.reduce((updatedProductAttributes, productAttribute) =>
          // Add to selected product attributes if it isn't included yet
          updatedProductAttributes.some(updatedProductAttribute => updatedProductAttribute.product_attribute_template_id === productAttribute.product_attribute_template_id)
            ? updatedProductAttributes
            : [...updatedProductAttributes, productAttribute],
        existingProductAttributes)
    }));
  }

  removeProductAttribute(productAttributeTemplateId: number) {
    this.store.update(({productAttributes}) => ({
      productAttributes: productAttributes.filter(productAttribute =>
        productAttribute.product_attribute_template_id !== productAttributeTemplateId
      )
    }));
  }

  updateValueOfProductAttributeByProductAttributeTemplateId(productAttributeTemplateId: number, value: string) {
    this.store.update(({productAttributes}) => ({
      productAttributes: productAttributes.map(productAttribute =>
        productAttribute.product_attribute_template_id === productAttributeTemplateId
          ? {...productAttribute, value}
          : productAttribute
      )
    }));
  }

  setInitialProductAttributes(productAttributes: ProductAttribute[]) {
    this.store.update({initialProductAttributes: productAttributes, productAttributes});
  }
}

export const provideProductAttributesManagement = () => [
  ProductAttributesManagementStore,
  ProductAttributesManagementQuery,
  ProductAttributesManagementService,
];
