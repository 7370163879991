import {SessionQuery} from '@/core/session/state/session.query';
import {SessionService} from '@/core/session/state/session.service';
import {TenantQuery} from '@/core/session/state/tenant/tenant.query';
import {SleekplanQuery} from '@/core/sleekplan/state/sleekplan.query';
import {SleekplanService} from '@/core/sleekplan/state/sleekplan.service';
import {ShopRouteNames} from '@/shop/constants/routes.constants';
import {SEARCH_TERM} from '@/shop/search/constants/query-params';
import {SearchRouteNames} from '@/shop/search/enums/search-routes.enum';
import {ShoppingCartQuery} from '@/shop/shopping-cart/state/shopping-cart.query';
import {ShoppingCartService} from '@/shop/shopping-cart/state/shopping-cart.service';
import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {take} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {TenantImagesQuery} from '../../state/tenant-images/tenant-images.query';
import {TenantImagesService} from '../../state/tenant-images/tenant-images.service';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  readonly sleekplanService = inject(SleekplanService);
  readonly sleekplanQuery = inject(SleekplanQuery);
  readonly tenantImagesQuery = inject(TenantImagesQuery);

  private readonly sessionQuery = inject(SessionQuery);
  private readonly sessionService = inject(SessionService);
  private readonly shoppingCartQuery = inject(ShoppingCartQuery);
  private readonly shoppingCartService = inject(ShoppingCartService);
  private readonly tenantImagesService = inject(TenantImagesService);
  private readonly tenantQuery = inject(TenantQuery);
  private readonly router = inject(Router);

  @Input() isSidenavOpen: boolean;

  @Output() sideNavToggled = new EventEmitter<void>();

  environment = environment;
  title = 'Einkaufsplattform';
  userTenantNavigationUrl = this.sessionQuery.userTenantNavigationUrl;
  shoppingCartItemCount$ = this.shoppingCartQuery.selectCount();
  tenantId: number;
  tenantName: string;
  userEmail = this.sessionQuery.userEmail;
  userName = this.sessionQuery.userName;

  searchControl!: UntypedFormControl;

  ngOnInit(): void {
    this.initialize();

    this.sessionQuery.user$.pipe(
      untilDestroyed(this),
    ).subscribe(user => {
        if (user?.tenant_id) {
          this.tenantId = user?.tenant_id;
          this.tenantImagesService.getByTenantId(this.tenantId).pipe(take(1)).subscribe();
        }
      }
    );
    this.tenantQuery.selectNameById$(this.sessionQuery.tenantId).pipe(
      untilDestroyed(this),
    ).subscribe(tenantName => {
      this.tenantName = tenantName;
    });
  }

  navigateToSearchResults() {
    const searchTerm = this.searchControl.value.trim();

    if (!searchTerm) {
      return;
    }

    this.router.navigate([
      ShopRouteNames.SHOP,
      SearchRouteNames.Search,
    ], {queryParams: {[SEARCH_TERM]: searchTerm}});

    this.searchControl.patchValue('');
  }

  logout() {
    this.sessionService.logout();
  }

  navigateToShoppingCart() {
    return this.shoppingCartService.navigateToShoppingCart();
  }

  private initialize() {
    this.searchControl = new UntypedFormControl('');
  }
}
