import {Tenant} from '@/core/session/state/tenant/tenant.model';
import {User} from '@/core/session/state/user/user.model';
import {CostCenter} from '@/shared/state/cost-centers/cost-center.model';
import {Status} from '../../product/state/supplier-product-available-statuses/statuses/status.model';
import {SupplierProduct} from '../../product/state/supplier-products/supplier-product.model';
import {OrderSupplierHistory} from './models/order-supplier-history.model';
import {OrderItem} from './order-items/order-item.model';

type OrderIncludes = {
  order_status?: Status;
  tenant?: Tenant;
  approver_level_1?: User;
  approver_level_2?: User;
  created_by?: User;
  cost_center?: CostCenter;
  order_items?: OrderItem[];
  order_supplier_histories?: OrderSupplierHistory[];
}

export type OrderApprovalWorkflowFormData = {
  cost_center_id: CostCenter['id'];
  individual_cost_center_mapping?: {
    cost_center_id: CostCenter['id'];
    supplier_product_ids: SupplierProduct['id'][];
  }[];
}

export interface Order extends OrderIncludes {
  readonly id?: number;
  readonly order_number?: number;
  description?: string;
  gtc_accepted?: boolean;
  status_id?: number;
  tenant_id?: number;
  readonly created_by_id?: number;
  approval_workflow_approval_allowed?: boolean;
  approval_workflow_confirmation_allowed?: boolean;
  approval_workflow_offer_allowed?: boolean;
  approval_workflow_status?: string;
  approver_level_1_id?: number;
  readonly approver_level_1_at?: Date;
  approver_level_1_approved?: boolean;
  approver_level_2_id?: number;
  readonly approver_level_2_at?: Date;
  approver_level_2_approved?: boolean;
  billing_address_company_organization?: string;
  billing_address_recipient?: string;
  billing_address_street?: string;
  billing_address_house_number?: string;
  billing_address_additional_address_information?: string;
  billing_address_postal_code?: string;
  billing_address_city?: string;
  billing_address_country_code_id?: number;
  approval_workflow_cost_centers_which_currently_need_approval: Pick<CostCenter, 'id' | 'name'>[],
  approval_workflow_form_data: string; // JSON string can be parsed to model OrderApprovalWorkflowFormData
  shipping_address_company_organization?: string;
  shipping_address_recipient?: string;
  shipping_address_street?: string;
  shipping_address_house_number?: string;
  shipping_address_additional_address_information?: string;
  shipping_address_postal_code?: string;
  shipping_address_city?: string;
  shipping_address_country_code_id?: number;
  cost_center_id?: number;
  order_edit_allowed?: boolean;
  readonly created_at?: Date;
  readonly updated_at?: Date;
  readonly deleted_at?: Date;
}

export const createOrder = (params: Partial<Order>) => ({...params} as Order);
