import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {BaseAddress} from '../address/address.model';

export interface TenantAddressesState extends EntityState<BaseAddress> {
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'tenant-addresses'})
export class TenantAddressesStore extends EntityStore<TenantAddressesState> {
  constructor() {
    super({loading: false});
  }

}
