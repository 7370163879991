import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {SupplierProductsState, SupplierProductsStore} from './supplier-products.store';
import {map} from 'rxjs/operators';
import {SupplierProduct} from './supplier-product.model';

@Injectable({providedIn: 'root'})
export class SupplierProductsQuery extends QueryEntity<SupplierProductsState> {
  supplierProducts$ = this.selectAll();
  activeSupplierProduct$ = this.selectActive();
  activeSupplierProduct = this.getActive();
  activeSupplierProductProductAttributes$ = this.selectActive(
    ({product}) => product?.product_attributes
  );

  // TODO: Use product with the minimum price for list
  supplierProductsWithoutDuplicates$ = this.supplierProducts$.pipe(
    map(supplierProducts => supplierProducts.reduce((filteredSupplierProducts, supplierProduct) =>
      // Filter out supplier products with same product
      filteredSupplierProducts.some(filteredSupplierProduct => filteredSupplierProduct.product?.id === supplierProduct.product?.id)
        ? filteredSupplierProducts
        : [
          ...filteredSupplierProducts,
          supplierProduct,
        ], [] as SupplierProduct[])),
  );

  constructor(protected store: SupplierProductsStore) {
    super(store);
  }

  getSupplierProductsByProductCategoryId(id: number) {
    return this.selectAll({
      filterBy: (supplierProduct) => supplierProduct.product?.product_category_id === id,
    });
  }
}
