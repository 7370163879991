import {SessionQuery} from '@/core/session/state/session.query';
import {
  ProductAttributeTemplate
} from '@/management/product/state/product-attribute-templates/product-attribute-template.model';
import {ProductAttribute} from '@/management/product/state/product-attributes/product-attribute.model';
import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {filterNilValue} from '@datorama/akita';
import {tap} from 'rxjs/operators';
import {FormModeDirective, FormModeHostDirective} from '../../../../directives/form-mode.directive';
import {getMatDialogConfig} from '../../../../utils/functions/mat-dialog';
import {
  ProductAttributesAddDialogComponent
} from '../product-attributes-add-dialog/product-attributes-add-dialog.component';
import {
  ProductAttributesManagementQuery,
  ProductAttributesManagementService
} from './product-attributes-management.state';

@Component({
  selector: 'app-product-attributes-management',
  templateUrl: './product-attributes-management.component.html',
  styleUrls: ['./product-attributes-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [
    FormModeHostDirective,
  ]
})
export class ProductAttributesManagementComponent {
  readonly formMode = inject(FormModeDirective, {self: true});
  readonly query = inject(ProductAttributesManagementQuery);

  private readonly sessionQuery = inject(SessionQuery);
  private readonly dialog = inject(MatDialog);
  private readonly service = inject(ProductAttributesManagementService);

  @Input() set productAttributes(productAttributes: ProductAttribute[]) {
    this.service.setInitialProductAttributes(productAttributes ?? []);
  }

  openProductAttributesAddDialog() {
    const dialogConfig = getMatDialogConfig({
      data: {
        productId: null,
        tenantId: this.sessionQuery.tenantId,
      },
      autoFocus: false,
    });

    this.dialog.open<ProductAttributesAddDialogComponent, unknown, ProductAttribute[]>(ProductAttributesAddDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(
        filterNilValue(),
        tap(selectedProductAttributes => this.service.addProductAttributes(selectedProductAttributes))
      )
      .subscribe();
  }

  removeProductAttribute(productAttributeTemplateId: ProductAttributeTemplate['id']) {
    this.service.removeProductAttribute(productAttributeTemplateId);
  }

  updateValueOfProductAttributeByProductAttributeTemplateId({productAttributeTemplateId, value}: {
    productAttributeTemplateId: number,
    value: string
  }) {
    this.service.updateValueOfProductAttributeByProductAttributeTemplateId(productAttributeTemplateId, value);
  }
}
