import {Observable, OperatorFunction, retry, timer} from 'rxjs';
import {tap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';

export const log = <T>(message?: string): OperatorFunction<T, T> => tap(e => {
  if (environment.production) {
    return;
  }

  if (message) {
    console.log(message, e);
  } else {
    console.log(e);
  }
});

export function backoffRetry<T>({count, delay} = {count: 2, delay: 200}) {
  return (obs$: Observable<T>) => obs$.pipe(
    retry({
      count,
      delay: (_, retryIndex) => {
        const d = Math.pow(2, retryIndex - 1) * delay;
        return timer(d);
      }
    })
  );
}
