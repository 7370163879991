<form [formGroup]="approvalWorkflowForm">
    <ng-container *ngIf="showTitle">
        <p class="mat-headline-6">Kostenstelle</p>
    </ng-container>

    <dqn-form-field class="w-full">
        <dqn-label>Kostenstelle</dqn-label>

        <dqn-combobox
          formControlName="costCenterId"
          [options]="(costCentersAsDqnOptions$ | async)" />

        <dqn-error *ngIf="costCenterIdControl.touched && costCenterIdControl.hasError('required')">
            Bitte wählen Sie eine Kostenstelle aus.
        </dqn-error>
    </dqn-form-field>
</form>
