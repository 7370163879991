import {Product} from '@/management/product/state/product.model';
import {ProductsQuery} from '@/management/product/state/products.query';
import {inject, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'productById$'
})
export class ProductByIdPipe implements PipeTransform {
  private readonly productsQuery = inject(ProductsQuery);

  transform(productId: Product['id']) {
    return this.productsQuery.selectProductById(productId);
  }
}
