import {SessionQuery} from '@/core/session/state/session.query';
import {
  SupplierAvailableStatusesQuery
} from '@/management/supplier/state/supplier-available-statuses/supplier-available-statuses.query';
import {
  SupplierAvailableStatusesService
} from '@/management/supplier/state/supplier-available-statuses/supplier-available-statuses.service';
import {createSupplier, Supplier} from '@/management/supplier/state/supplier.model';
import {SuppliersQuery} from '@/management/supplier/state/suppliers.query';
import {SuppliersService} from '@/management/supplier/state/suppliers.service';
import {getFirstErrorOfProperty} from '@/shared';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {combineLatestWith, switchMap} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {FormMode} from '../../enums/form-mode.enum';
import {getFormattedTimestamp} from '../../helpers/api/get-formatted-timestamp';
import {getPriceForApi} from '../../helpers/api/get-price-for-api';
import {getPriceFromApi} from '../../helpers/api/get-price-from-api';
import {CountryCodesQuery} from '../../state/country-codes/country-codes.query';
import {CountryCodesService} from '../../state/country-codes/country-codes.service';
import {CurrencyCode} from '../../state/currency-codes/currency-code.model';
import {CurrencyCodesQuery} from '../../state/currency-codes/currency-codes.query';
import {CurrencyCodesService} from '../../state/currency-codes/currency-codes.service';
import {LanguageCodesQuery} from '../../state/language-codes/language-codes.query';
import {LanguageCodesService} from '../../state/language-codes/language-codes.service';
import {StatusNames} from '../../state/status/enums/status-names.enum';
import {emailValidator} from '../../validators/multiple-emails/email-validator';
import {SupplierFormControls} from './types/supplier-form-controls';
import {minimumOrderValueValidator} from './validators/minimum-order-value-validator';
import {shippingCostsValidator} from './validators/shipping-costs-validator';

@Component({
  selector: 'app-supplier-form',
  templateUrl: './supplier-form.component.html',
  styleUrls: ['./supplier-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplierFormComponent implements OnInit {
  readonly currencyCodesQuery = inject(CurrencyCodesQuery);

  private readonly countryCodesQuery = inject(CountryCodesQuery);
  private readonly countryCodesService = inject(CountryCodesService);
  private readonly languageCodesQuery = inject(LanguageCodesQuery);
  private readonly languageCodesService = inject(LanguageCodesService);
  private readonly currencyCodesService = inject(CurrencyCodesService);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly sessionQuery = inject(SessionQuery);
  private readonly supplierAvailableStatusesQuery = inject(SupplierAvailableStatusesQuery);
  private readonly supplierAvailableStatusesService = inject(SupplierAvailableStatusesService);
  private readonly suppliersService = inject(SuppliersService);
  private readonly suppliersQuery = inject(SuppliersQuery);
  private readonly destroyRef = inject(DestroyRef);

  @Input() formMode: FormMode;

  @Input() set disabled(disabled: boolean) {
    if (disabled) {
      this.supplierForm?.disable();
    } else {
      this.supplierForm?.enable();
    }
  }

  @Output() supplierChanged: EventEmitter<Supplier> = new EventEmitter<Supplier>();

  get isProposal() {
    return this.formMode === FormMode.proposal;
  }

  get supplierId() {
    return this.formMode === FormMode.create
      ? null
      : this.suppliersQuery.activeSupplier?.id ?? null;
  }

  get isMinimumOrderValueRequired() {
    return !!this.supplierForm.controls.minimumOrderValueSurcharge.value;
  }

  get hasMinimumOrderValueRequiredError() {
    return this.supplierForm.controls.minimumOrderValue.touched
      && this.supplierForm.hasError('minimum-order-value-required')
      && this.isMinimumOrderValueRequired;
  }

  get isMinimumOrderValueSurchargeRequired() {
    return !!this.supplierForm.controls.minimumOrderValue.value;
  }

  get hasMinimumOrderValueSurchargeRequiredError() {
    return this.supplierForm.controls.minimumOrderValueSurcharge.touched
      && this.supplierForm.hasError('minimum-order-value-required')
      && this.isMinimumOrderValueSurchargeRequired;
  }

  get isShippingCostRequired() {
    return !!this.supplierForm.controls.shippingCostsMinimumOrderValue.value;
  }

  get hasShippingCostsRequiredError() {
    return this.supplierForm.controls.shippingCosts.touched
      && this.supplierForm.hasError('shipping-costs-required')
      && this.isShippingCostRequired;
  }

  countryCodes$ = this.countryCodesQuery.countryCodes$;
  languageCodes$ = this.languageCodesQuery.languageCodes$;
  supplierAvailableStatuses$ = this.supplierAvailableStatusesQuery.supplierAvailableStatuses$;

  environment = environment;
  FormMode = FormMode;

  supplierForm: FormGroup<SupplierFormControls>;

  ngOnInit() {
    this.initializeForm();

    this.countryCodesService.get().subscribe();
    this.languageCodesService.get().subscribe();
    this.currencyCodesService.get().subscribe();
    this.supplierAvailableStatusesService.get().subscribe();

    // Fetch supplier based on supplierId in route parameters
    this.activatedRoute.paramMap.pipe(
      map(paramMap => +paramMap.get('supplierId')),
      tap(supplierId => this.suppliersService.setActiveSupplier(supplierId > 0 ? supplierId : null)),
      // Only fetch information for details & edit screens
      filter(supplierId => supplierId > 0),
      switchMap(supplierId => this.suppliersService.getById(supplierId)),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();

    // Patch form based on current active product category tenant
    this.suppliersQuery.activeSupplier$.pipe(
      combineLatestWith(this.currencyCodesQuery.eur$), // Enrich supplier with default currency code
      tap(([supplier, currencyCode]) => {
        if (this.isProposal) {
          this.fillFormWithCurrencyCode(currencyCode);
        }

        if (this.formMode != FormMode.create && supplier) {
          this.fillFormWithSupplierInformation(supplier, currencyCode)
        }
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();

    // Patch status control with initial state
    this.supplierAvailableStatusesQuery.supplierAvailableStatuses$.pipe(
      filter(() => this.formMode === FormMode.create || this.isProposal),
      filter(availableStatuses => availableStatuses?.length > 0),
      switchMap(() => this.isProposal
        ? this.supplierAvailableStatusesQuery.selectStatusByName(StatusNames.Proposal)
        : this.supplierAvailableStatusesQuery.selectStatusByName(StatusNames.Active)
      ),
      tap(status => this.supplierForm.controls.statusId.patchValue(status.status_id)),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();

    // Propagate all changes for proposal mode
    if (this.isProposal) {
      this.supplierForm.valueChanges.pipe(
        tap(() => this.propagateFormChanges()),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe();
    }
  }

  onSubmit() {
    const supplier = this.createSupplierFromForm();

    if (this.formMode === FormMode.create) {
      this.suppliersService.addToDb(supplier).subscribe(() => this.navigateToSupplierList());
    } else {
      this.suppliersService.updateOnDb(supplier).subscribe(() => this.navigateToSupplierList());
    }
  }

  // TODO: Use router links instead
  navigateToSupplierEdit(id: number): Promise<boolean> {
    return this.suppliersService.navigateToSupplierEdit(id);
  }

  navigateToSupplierList(): Promise<boolean> {
    return this.suppliersService.navigateToSupplierList();
  }

  getFirstErrorOfProperty(name: keyof SupplierFormControls) {
    return getFirstErrorOfProperty(name, [], this.supplierForm.get(name));
  }

  private initializeForm() {
    this.supplierForm = new FormGroup<SupplierFormControls>({
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      name2: new FormControl('', [
        Validators.maxLength(100),
      ]),
      street: new FormControl('', [
        Validators.required,
        Validators.maxLength(50),
      ]),
      houseNumber: new FormControl('', [
        Validators.required,
        Validators.maxLength(10)
      ]),
      additionalAddressInformation: new FormControl('', [
        Validators.maxLength(100),
      ]),
      postalCode: new FormControl('', [
        Validators.required,
        Validators.maxLength(20),
      ]),
      city: new FormControl('', [
        Validators.required,
        Validators.maxLength(50),
      ]),
      countryCodeId: new FormControl(null, Validators.required),
      languageCodeId: new FormControl(null),
      gln: new FormControl('', [
        Validators.maxLength(13),
      ]),
      accountNumber: new FormControl('', [
        Validators.maxLength(100),
      ]),
      extId: new FormControl('', [
        Validators.maxLength(100),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.maxLength(254),
        emailValidator(),
      ]),
      homepage: new FormControl('', [
        Validators.maxLength(255),
        Validators.pattern(environment.regularExpressions.websiteBaseUrl),
      ]),
      description: new FormControl('', [
        Validators.maxLength(1200),
      ]),
      statusId: new FormControl(null, Validators.required),
      minimumOrderValue: new FormControl(null),
      minimumOrderValueCurrencyCodeId: new FormControl(null),
      minimumOrderValueSurcharge: new FormControl(null),
      minimumOrderValueSurchargeCurrencyCodeId: new FormControl(null),
      shippingCostsMinimumOrderValue: new FormControl(null),
      shippingCostsMinimumOrderValueCurrencyCodeId: new FormControl(null),
      shippingCosts: new FormControl(null),
      shippingCostsCurrencyCodeId: new FormControl(null),
      tollCharge: new FormControl(null),
      tollChargeCurrencyCodeId: new FormControl(null),
      additionalCosts: new FormControl(null),
      additionalCostsCurrencyCodeId: new FormControl(null),
      additionalCostsDescription: new FormControl(null),
      createdAt: new FormControl(''),
      updatedAt: new FormControl(''),
      deletedAt: new FormControl(''),
    }, [
      minimumOrderValueValidator,
      shippingCostsValidator,
    ]);

    if (this.formMode === FormMode.details) {
      this.supplierForm.disable();
    }
  }

  private fillFormWithSupplierInformation(supplier: Supplier, currencyCode: CurrencyCode) {
    this.supplierForm.patchValue({
      name: supplier.name,
      name2: supplier.name_2,
      street: supplier.street,
      houseNumber: supplier.house_number,
      additionalAddressInformation: supplier.additional_address_information,
      postalCode: supplier.postal_code,
      city: supplier.city,
      countryCodeId: supplier.country_code_id,
      gln: supplier.gln,
      accountNumber: supplier.account_number,
      extId: supplier.ext_id,
      email: supplier.email,
      homepage: supplier.homepage,
      languageCodeId: supplier.language_code_id,
      description: supplier.description,
      statusId: supplier.status_id,

      // HINT: Using fallbacks for currency codes to allow for default euro
      minimumOrderValue: getPriceFromApi(supplier.minimum_order_value),
      minimumOrderValueSurcharge: getPriceFromApi(supplier.minimum_order_value_surcharge),
      shippingCostsMinimumOrderValue: getPriceFromApi(supplier.shipping_costs_minimum_order_value),
      shippingCosts: getPriceFromApi(supplier.shipping_costs),
      tollCharge: getPriceFromApi(supplier.toll_charge),
      additionalCosts: getPriceFromApi(supplier.additional_costs),
      additionalCostsDescription: supplier.additional_costs_description,
      createdAt: getFormattedTimestamp(supplier.created_at) + (
        supplier.created_by?.name
          ? ' (' + supplier.created_by.name + ')'
          : ''
      ),
      updatedAt: getFormattedTimestamp(supplier.updated_at),
      deletedAt: getFormattedTimestamp(supplier.deleted_at),
    });

    this.fillFormWithCurrencyCode(currencyCode, supplier);
  }

  private fillFormWithCurrencyCode(currencyCode: CurrencyCode, supplier: Supplier | null = null) {
    this.supplierForm.patchValue({
      minimumOrderValueCurrencyCodeId: supplier?.minimum_order_value_currency_code_id ?? currencyCode.id,
      minimumOrderValueSurchargeCurrencyCodeId: supplier?.minimum_order_value_surcharge_currency_code_id ?? currencyCode.id,
      shippingCostsCurrencyCodeId: supplier?.shipping_costs_currency_code_id ?? currencyCode.id,
      shippingCostsMinimumOrderValueCurrencyCodeId: supplier?.shipping_costs_minimum_order_value_currency_code_id ?? currencyCode.id,
      tollChargeCurrencyCodeId: supplier?.toll_charge_currency_code_id ?? currencyCode.id,
      additionalCostsCurrencyCodeId: supplier?.additional_costs_currency_code_id ?? currencyCode.id,
    })
  }

  private propagateFormChanges() {
    if (this.supplierForm.valid) {
      this.supplierChanged.emit(this.createSupplierFromForm());
    } else {
      this.supplierChanged.emit(null);
    }
  }

  private createSupplierFromForm() {
    return createSupplier({
      id: this.supplierId,
      tenant_id: this.sessionQuery.userTenantId,
      status_id: this.supplierForm.value.statusId,
      name: this.supplierForm.value.name,
      name_2: this.supplierForm.value.name2,
      gln: this.supplierForm.value.gln,
      account_number: this.supplierForm.value.accountNumber,
      ext_id: this.supplierForm.value.extId,
      street: this.supplierForm.value.street,
      house_number: this.supplierForm.value.houseNumber,
      additional_address_information: this.supplierForm.value.additionalAddressInformation,
      postal_code: this.supplierForm.value.postalCode,
      city: this.supplierForm.value.city,
      country_code_id: this.supplierForm.value.countryCodeId,
      language_code_id: this.supplierForm.value.languageCodeId,
      email: this.supplierForm.value.email,
      homepage: this.supplierForm.value.homepage,
      description: this.supplierForm.value.description,
      // Using raw value for currency codes as they will always be disabled
      minimum_order_value: getPriceForApi(this.supplierForm.value.minimumOrderValue),
      minimum_order_value_currency_code_id: this.supplierForm.getRawValue().minimumOrderValueCurrencyCodeId,
      minimum_order_value_surcharge: getPriceForApi(this.supplierForm.value.minimumOrderValueSurcharge),
      minimum_order_value_surcharge_currency_code_id: this.supplierForm.getRawValue().minimumOrderValueSurchargeCurrencyCodeId,
      shipping_costs_minimum_order_value: getPriceForApi(this.supplierForm.value.shippingCostsMinimumOrderValue),
      shipping_costs_minimum_order_value_currency_code_id: this.supplierForm.getRawValue().shippingCostsMinimumOrderValueCurrencyCodeId,
      shipping_costs: getPriceForApi(this.supplierForm.value.shippingCosts),
      shipping_costs_currency_code_id: this.supplierForm.getRawValue().shippingCostsCurrencyCodeId,
      toll_charge: getPriceForApi(this.supplierForm.value.tollCharge),
      toll_charge_currency_code_id: this.supplierForm.getRawValue().tollChargeCurrencyCodeId,
      additional_costs: getPriceForApi(this.supplierForm.value.additionalCosts),
      additional_costs_currency_code_id: this.supplierForm.getRawValue().additionalCostsCurrencyCodeId,
      additional_costs_description: this.supplierForm.value.additionalCostsDescription,
    });
  }
}
