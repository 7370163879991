import {ProductVariant} from '@/management/product/state/product.model';
import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DqnTableHeader} from '@dqn/components/table';

export type VariantsAddDialogData = {
  // variantAttributes: VariantAttribute[]; // TODO: Add filter for variant attributes back
  variants: ProductVariant[];
};

export type VariantsAddDialogOutput = ProductVariant[] | undefined;

@Component({
  selector: 'app-variants-add-dialog',
  templateUrl: './variants-add-dialog.component.html',
  styleUrls: ['./variants-add-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VariantsAddDialogComponent {
  readonly data: VariantsAddDialogData = inject(MAT_DIALOG_DATA);

  private readonly dialogRef = inject(MatDialogRef<VariantsAddDialogComponent, VariantsAddDialogOutput>);

  selection: number[] = [];

  variantHeaders: DqnTableHeader[] = [
    {key: 'name', title: 'Name'},
  ];

  cancel() {
    this.dialogRef.close();
  }

  save() {
    if (!this.selection) {
      return;
    }

    this.dialogRef.close(
      this.data.variants.filter(variant => this.selection.includes(variant.id)),
    );
  }

  updateSelection(selection: number[]) {
    this.selection = selection;
  }
}
