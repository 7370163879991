import {SessionQuery} from '@/core/session/state/session.query';
import {ApiResponse} from '@/shared/types/api/api-response';
import {handleError} from '@/shared/utils';
import {getHttpOptionsWithParams} from '@/shared/utils/functions/http-params';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {setLoading} from '@datorama/akita';
import {forkJoin} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {ProductCategoriesService} from '../product-categories/product-categories.service';
import {ProductCategoryTenant} from './product-category-tenant.model';
import {ProductCategoryTenantsStore} from './product-category-tenants.store';

@Injectable({providedIn: 'root'})
export class ProductCategoryTenantsService {
  private readonly productCategoryTenantsStore = inject(ProductCategoryTenantsStore);
  private readonly productCategoriesService = inject(ProductCategoriesService);
  private readonly sessionQuery = inject(SessionQuery);
  private readonly http = inject(HttpClient);
  private readonly snackBar = inject(MatSnackBar);

  getProductCategoriesAndProductCategoryTenants() {
    return forkJoin([
      this.get(),
      this.productCategoriesService.get(),
    ]);
  }

  get() {
    const options = getHttpOptionsWithParams({
      tenant_id: this.sessionQuery?.tenantId?.toString(),
    });

    return this.http
      .get<ApiResponse<ProductCategoryTenant[]>>(environment.api.baseUrl + 'product-category-tenants', options)
      .pipe(
        setLoading(this.productCategoryTenantsStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productCategoryTenantsStore)),
        tap(({data: productCategoryTenants}) => {
          this.productCategoryTenantsStore.set(productCategoryTenants);
        }),
      );
  }

  getById(id: ProductCategoryTenant['id']) {
    const options = getHttpOptionsWithParams({
      tenant_id: this.sessionQuery?.tenantId?.toString(),
    });

    return this.http.get<ApiResponse<ProductCategoryTenant>>(
      environment.api.baseUrl + 'product-category-tenants/' + id,
      options
    ).pipe(
      setLoading(this.productCategoryTenantsStore),
      catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productCategoryTenantsStore)),
      tap(({data: productCategoryTenant}) => {
        this.productCategoryTenantsStore.upsert(productCategoryTenant.id, productCategoryTenant);
      }),
    );
  }

  update(productCategoryTenant: ProductCategoryTenant) {
    const options = getHttpOptionsWithParams({
      tenant_id: this.sessionQuery?.tenantId?.toString(),
    });

    return this.http.put<ApiResponse<ProductCategoryTenant>>(
      environment.api.baseUrl + 'product-category-tenants/' + productCategoryTenant.id,
      productCategoryTenant,
      options
    ).pipe(
      setLoading(this.productCategoryTenantsStore),
      catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productCategoryTenantsStore)),
      tap(({data}) => this.productCategoryTenantsStore.update(data)),
    );
  }

  setActiveProductCategoryTenant(id: ProductCategoryTenant['id'] | null) {
    this.productCategoryTenantsStore.setActive(id);
  }
}
