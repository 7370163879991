<dqn-form-field class="w-full">
    <dqn-label *ngIf="label">{{ label }}</dqn-label>
    <input
      [required]="required"
      dqnInput
      [formControl]="valueControl"
      (focus)="touch.emit()">

    <dqn-error *ngIf="error">
        {{ error }}
    </dqn-error>
</dqn-form-field>
