import {
  SupplierProductAvailableStatusesQuery
} from '@/management/product/state/supplier-product-available-statuses/supplier-product-available-statuses.query';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'supplierProductStatusById'
})
export class SupplierProductStatusByIdPipe implements PipeTransform {
  constructor(private supplierProductAvailableStatusesQuery: SupplierProductAvailableStatusesQuery) {
  }

  transform(statusId: number) {
    return this.supplierProductAvailableStatusesQuery.selectStatusById(statusId);
  }
}
