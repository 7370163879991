import {SessionQuery} from '@/core/session/state/session.query';
import {ApiResponse} from '@/shared/types/api/api-response';
import {handleError} from '@/shared/utils';
import {getHttpOptionsWithParams} from '@/shared/utils/functions/http-params';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {setLoading} from '@datorama/akita';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {ProductAttributeGroup} from './product-attribute-group.model';
import {ProductAttributeGroupsStore} from './product-attribute-groups.store';

@Injectable({providedIn: 'root'})
export class ProductAttributeGroupsService {
  private readonly productAttributeGroupsStore = inject(ProductAttributeGroupsStore);
  private readonly sessionQuery = inject(SessionQuery);
  private readonly http = inject(HttpClient);
  private readonly snackBar = inject(MatSnackBar);

  get() {
    const options = getHttpOptionsWithParams({
      tenant_id: this.sessionQuery?.tenantId?.toString(),
    });

    return this.http.get<ApiResponse<ProductAttributeGroup[]>>(environment.api.baseUrl + 'product-attribute-groups', options)
      .pipe(
        setLoading(this.productAttributeGroupsStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productAttributeGroupsStore)),
        tap(({data: productAttributeGroups}) => {
          this.productAttributeGroupsStore.set(productAttributeGroups);
        })
      );
  }
}
