import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DqnSingleOption} from '@dqn/components/combobox';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ApprovalWorkflowComponentInterface} from '../../../../interfaces/approval-workflow-component.interface';
import {CostCentersQuery} from '../../../../state/cost-centers/cost-centers.query';
import {CostCentersService} from '../../../../state/cost-centers/cost-centers.service';
import {ApprovalWorkflows} from '../../enums/approval-workflows.enum';

export interface ApprovalWorkflowProductCategoriesAndCostCenterConfig {
}

// HINT Snake case is used because of easier API handling
export interface ApprovalWorkflowProductCategoriesAndCostCenterData {
  cost_center_id: number;
}

@UntilDestroy()
@Component({
  selector: 'app-product-categories-and-cost-center-approval-workflow',
  templateUrl: './product-categories-and-cost-center-approval-workflow.component.html',
  styleUrls: ['./product-categories-and-cost-center-approval-workflow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductCategoriesAndCostCenterApprovalWorkflowComponent implements OnInit, OnChanges, ApprovalWorkflowComponentInterface {
  static readonly approvalWorkflowName = ApprovalWorkflows.productCategoriesAndCostCenter;

  @Input() showTitle = false;
  @Input() approvalWorkflowData: ApprovalWorkflowProductCategoriesAndCostCenterData;
  @Input() approvalWorkflowConfig: ApprovalWorkflowProductCategoriesAndCostCenterConfig;

  @Output() approvalWorkflowDataChange = new EventEmitter<ApprovalWorkflowProductCategoriesAndCostCenterData>();
  @Output() validityChange = new EventEmitter<boolean>();

  approvalWorkflowForm: UntypedFormGroup;

  costCentersAsDqnOptions$: Observable<DqnSingleOption[]>;

  get costCenterIdControl() {
    return this.approvalWorkflowForm?.get('costCenterId');
  }

  constructor(
    private costCentersService: CostCentersService,
    private costCentersQuery: CostCentersQuery,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.approvalWorkflowData.currentValue?.cost_center_id && !changes.approvalWorkflowData.firstChange) {
      this.setInitialFormValuesByDataOrConfig();
    }
  }

  ngOnInit(): void {
    this.initialize();
    this.initializeObservables();

    this.costCentersService.get().subscribe();

    this.setInitialFormValuesByDataOrConfig();
  }

  private setInitialFormValuesByDataOrConfig() {
    if (this.approvalWorkflowData?.cost_center_id) {
      // Set initial cost center and emit value/validity if already present in data
      this.costCentersService.getById(this.approvalWorkflowData.cost_center_id)
        .pipe(
          tap(() => {
            this.costCenterIdControl.setValue(this.approvalWorkflowData.cost_center_id);
          })
        ).subscribe();
    }
  }

  private initializeObservables() {
    // Emit approval workflow data change on every change in cost center id control
    this.costCenterIdControl.valueChanges.pipe(
      untilDestroyed(this),
      tap(id => {
        this.emitApprovalWorkflowDataChange(id);
      })
    ).subscribe();

    this.costCentersAsDqnOptions$ = this.costCentersQuery.costCentersAsDqnOptions$;
  }

  private emitApprovalWorkflowDataChange(costCenterId: number): void {
    this.emitValidityChange(this.costCenterIdControl.valid);

    const approvalWorkflowData = {
      cost_center_id: costCenterId,
    } as ApprovalWorkflowProductCategoriesAndCostCenterData;

    this.approvalWorkflowDataChange.emit(approvalWorkflowData);
  }

  private emitValidityChange(validity: boolean): void {
    this.validityChange.emit(validity);
  }

  private initialize() {
    this.initializeForm();
  }

  private initializeForm() {
    this.approvalWorkflowForm = new UntypedFormGroup({
      costCenterId: new UntypedFormControl(
        {value: null, disabled: false},
        [Validators.required]
      ),
    });
  }
}
