import {Pipe, PipeTransform} from '@angular/core';
import {CurrencyCodesQuery} from '../../../../../state/currency-codes/currency-codes.query';

@Pipe({
  name: 'currencyCodeById'
})
export class CurrencyCodeByIdPipe implements PipeTransform {

  constructor(private currencyQuery: CurrencyCodesQuery) {
  }

  transform(currencyCodeId: number) {
    return this.currencyQuery.selectCurrencyCodeById(currencyCodeId);
  }
}
