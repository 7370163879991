import {Pipe, PipeTransform} from '@angular/core';
import {getPriceFromApi} from '../helpers/api/get-price-from-api';

@Pipe({
  name: 'priceForAllowance'
})
export class PriceForAllowancePipe implements PipeTransform {
  transform(price: number) {
    return getPriceFromApi(price);
  }
}
