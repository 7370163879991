import {Product} from '@/management/product/state/product.model';
import {
  SupplierProductAvailableStatusesService
} from '@/management/product/state/supplier-product-available-statuses/supplier-product-available-statuses.service';
import {SupplierProduct} from '@/management/product/state/supplier-products/supplier-product.model';
import {SuppliersService} from '@/management/supplier/state/suppliers.service';
import {ChangeDetectionStrategy, Component, inject, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {filterNilValue} from '@datorama/akita';
import {DqnTableHeader} from '@dqn/components/table';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {FormModeDirective, FormModeHostDirective} from '../../../../directives/form-mode.directive';
import {getMatDialogConfig} from '../../../../utils/functions/mat-dialog';
import {
  SupplierProductFormDialogComponent,
  SupplierProductFormDialogData,
  SupplierProductFormDialogOutput
} from '../supplier-product-form-dialog/supplier-product-form-dialog.component';
import {SupplierProductManagementQuery, SupplierProductManagementService} from './supplier-product-management.state';

@Component({
  selector: 'app-supplier-product-management',
  templateUrl: './supplier-product-management.component.html',
  styleUrls: ['./supplier-product-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [
    FormModeHostDirective,
  ]
})
export class SupplierProductManagementComponent implements OnInit {
  readonly formMode = inject(FormModeDirective, {self: true});

  @Input() set product(product: Product) {
    if (!product) {
      this.service.setInitialSupplierProducts([]);
      return;
    }

    const supplierProducts = product.suppliers?.map(supplier => supplier.pivot);
    this.service.setInitialSupplierProducts(supplierProducts);
  }

  supplierProductHeaders: DqnTableHeader[] = [
    {key: 'name', title: 'Name'},
    {key: 'order-number', title: 'Artikelnummer'},
    {key: 'unit-price', title: 'Einmalpreis'},
    {key: 'billing-frequency-price', title: 'Abrechnungspreis'},
    {key: 'status', title: 'Status'},
    {key: 'promoted', title: 'Angesagt'},
  ];

  supplierProducts$!: Observable<SupplierProduct[]>;

  constructor(
    private dialog: MatDialog,
    private service: SupplierProductManagementService,
    private query: SupplierProductManagementQuery,
    private supplierProductAvailableStatusesService: SupplierProductAvailableStatusesService,
    private suppliersService: SuppliersService,
  ) {
  }

  ngOnInit() {
    this.initialize();

    this.suppliersService.get().subscribe();
    this.supplierProductAvailableStatusesService.get().subscribe();
  }

  openSupplierProductAddDialog() {
    const dialogConfig = getMatDialogConfig<SupplierProductFormDialogData>({
      autoFocus: false,
      data: {
        supplierProduct: null,
      }
    });

    this.dialog.open<SupplierProductFormDialogComponent, unknown, SupplierProductFormDialogOutput>(SupplierProductFormDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(
        filterNilValue(),
        tap(supplierProduct => this.service.addSupplierProduct(supplierProduct)),
      )
      .subscribe();
  }

  openSupplierProductEditDialog(supplierProduct: SupplierProduct) {
    const dialogConfig = getMatDialogConfig<SupplierProductFormDialogData>({
      autoFocus: false,
      data: {
        supplierProduct,
      }
    });

    this.dialog.open<SupplierProductFormDialogComponent, unknown, SupplierProductFormDialogOutput>(SupplierProductFormDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(
        filterNilValue(),
        tap(updatedSupplierProduct => this.service.updateSupplierProduct(updatedSupplierProduct)),
      )
      .subscribe();
  }

  deleteSupplierProduct(supplierProduct: SupplierProduct) {
    this.service.removeSupplierProduct(supplierProduct.id);
  }

  private initialize() {
    this.supplierProducts$ = this.query.supplierProducts$;
  }
}
