<div
  class="mat-headline-5"
  mat-dialog-title>{{ title }}
</div>

<mat-dialog-content>
    {{ informationText }}

    <form [formGroup]="confirmForm">
        <div mat-dialog-content>
            @if (showComment) {
                <div class="m-2 mat-elevation-z0 flex justify-center items-center">
                    <dqn-form-field
                      class="w-full"
                      hintText="Maximale Länge: 255 Zeichen"
                      hintTextEnd="{{commentInput.value?.length || 0}}/255">
                        <dqn-label>Kommentar</dqn-label>
                        <textarea
                          #commentInput
                          dqnInput
                          formControlName="comment"
                          rows="4"
                          maxlength="255"></textarea>
                    </dqn-form-field>
                </div>
            }
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button
      mat-button
      (click)="cancel()">Abbrechen
    </button>
    <button
      mat-flat-button
      color="primary"
      (click)="confirm()">
        {{ confirmText }}
    </button>
</mat-dialog-actions>
