import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadComponent {
  @Output() uploadFiles = new EventEmitter<unknown>();
  _backgroundImage = '';

  @Input()
  set backgroundImage(value: string) {
    if (value) {
      this._backgroundImage = value;
    }
  }

  get backgroundImage() {
    return this._backgroundImage;
  }

  uploadFile(event: unknown) {
    this.uploadFiles.emit(event);
  }
}
