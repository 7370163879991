import {inject, Injectable} from '@angular/core';
import {combineQueries, filterNilValue, QueryEntity} from '@datorama/akita';
import {DqnFilterCategory} from '@dqn/components/filter';
import {Observable, of, withLatestFrom} from 'rxjs';
import {distinctUntilChanged, filter, map, startWith, switchMap} from 'rxjs/operators';
import {isProductWithVariants} from './helpers/functions/is-product-with-variants';
import {isVariantOfProduct} from './helpers/functions/is-variant-of-product';
import {mergeProductAttributesForVariant} from './helpers/functions/merge-product-attributes-for-variant';
import {ProductAttribute} from './product-attributes/product-attribute.model';
import {ProductCategoriesQuery} from './product-categories/product-categories.query';
import {ProductCategory} from './product-categories/product-category.model';
import {Product, ProductVariant} from './product.model';
import {ProductsState, ProductsStore} from './products.store';
import {SupplierProductsQuery} from './supplier-products/supplier-products.query';
import {VariantAttributesQuery} from './variant-attributes/variant-attributes.query';

@Injectable({providedIn: 'root'})
export class ProductsQuery extends QueryEntity<ProductsState> {
  private readonly supplierProductsQuery = inject(SupplierProductsQuery);
  private readonly variantAttributesQuery = inject(VariantAttributesQuery);

  isLoading$ = this.selectLoading();

  /** Usage of {@link topLevelProducts$} should be preferred as it only includes top level products. */
  products$ = this.selectAll();

  activeProduct$ = this.selectActive();

  topLevelProducts$ = this.products$.pipe(
    map(products => products.filter(product => product.parent_id === null)),
  );

  productsForActiveProductCategory$ = this.productCategoriesQuery.selectActive().pipe(
    filterNilValue(),
    switchMap(productCategory => this.selectTopLevelProductsForCategory(productCategory.id)),
  );

  hasProductsForActiveProductCategory$ = this.productsForActiveProductCategory$.pipe(
    map(products => products.length > 0),
    startWith(false),
  );

  productsForActiveProductCategoryWithoutActiveProduct$ = combineQueries([
    this.productsForActiveProductCategory$,
    this.activeProduct$,
  ]).pipe(
    map(([products, activeProduct]) => activeProduct
      ? products.filter(product => product.id !== activeProduct.id)
      : products
    ),
  );

  areProductsForActiveProductCategoryLoading$ = combineQueries([
    this.hasProductsForActiveProductCategory$,
    this.isLoading$,
  ]).pipe(
    map(([hasProductsForActiveCategory, isLoading]) => isLoading && !hasProductsForActiveCategory),
  );

  activeProductOrVariantProduct$ = this.supplierProductsQuery.activeSupplierProduct$.pipe(
    map(supplierProduct => supplierProduct?.product_id ?? null),
    distinctUntilChanged(),
    switchMap(productId => this.selectProductById(productId)),
  );

  /** Merged collection of product attributes based on the active product and, in case of variants, the variant product. */
  productAttributesForActiveProduct$ = this.activeProductOrVariantProduct$.pipe(
    switchMap(product => this.selectProductAttributesForProduct(product)),
    // Sort attributes by template id to prevent jumping in list
    map(productAttributes => [...productAttributes].sort(
      ({product_attribute_template_id: idA}, {product_attribute_template_id: idB}) => idA - idB)
    ),
  );

  variantsForActiveProduct$: Observable<ProductVariant[]> = this.activeProduct$.pipe(
    filter(isProductWithVariants),
    switchMap(activeProduct => activeProduct ? this.selectVariantsForProduct(activeProduct.id) : of([])),
  );

  preferredVariantForActiveProduct$ = this.activeProduct$.pipe(
    withLatestFrom(this.variantsForActiveProduct$),
    filter(([product, variants]) => isProductWithVariants(product) && variants?.length > 0),
    switchMap(([, variants]) => this.supplierProductsQuery.activeSupplierProduct$.pipe(
      filterNilValue(),
      map((supplierProduct) => variants.find(({id}) => id === supplierProduct?.product_id)),
    )),
  );

  variantAttributesForActiveProduct$ = this.activeProduct$.pipe(
    switchMap(product => this.variantAttributesQuery.selectVariantAttributesForProduct(product?.id)),
  );

  hasActiveProductVariants$ = this.activeProduct$.pipe(
    map(isProductWithVariants),
  );

  areSearchResultsLoading$ = combineQueries([
    this.topLevelProducts$,
    this.isLoading$,
  ]).pipe(
    map(([products, isLoading]) => isLoading && products.length <= 0),
  );

  favoriteProducts$ = this.products$.pipe(
    map(products => products.filter(product => product.is_favorite)),
  );
  hasFavoriteProducts$ = this.favoriteProducts$.pipe(
    map(supplierProducts => supplierProducts?.length > 0),
  );

  areProductFavoritesLoading$ = combineQueries([
    this.hasFavoriteProducts$,
    this.isLoading$,
  ]).pipe(
    map(([hasFavoriteProducts, isLoading]) => isLoading && !hasFavoriteProducts),
  );

  availableCategoryFiltersForFavoriteProductsAsDqnFilterCategory$ = this.favoriteProducts$.pipe(
    map(products => products
      .filter(product => product?.product_category_id && product?.product_category)
      .reduce((categories, product) =>
        categories.some(category => category.id === product?.product_category_id)
          ? categories
          : [
            ...categories,
            product?.product_category,
          ], [] as ProductCategory[])
    ),
    map(categories => ([
      {
        title: 'Kategorie',
        filterObjectKey: 'activeCategories',
        multiselect: true,
        multiselectAllLabel: 'Alle',
        options: categories.map(({id, display_name}) => ({
          label: display_name,
          value: id,
        }))
      } as DqnFilterCategory,
    ]))
  );

  constructor(
    protected store: ProductsStore,
    private productCategoriesQuery: ProductCategoriesQuery,
  ) {
    super(store);
  }

  getProductById(id: Product['id']) {
    return this.getEntity(id);
  }

  getVariantsForProduct(productId: Product['id']): ProductVariant[] {
    return this.getAll().filter(product => isVariantOfProduct(product, productId));
  }

  selectVariantsForProduct(productId: Product['id']) {
    return this.products$.pipe(
      map(products => products.filter(product => isVariantOfProduct(product, productId))),
    );
  }

  selectProductById(id: Product['id']) {
    return this.selectEntity(id);
  }

  selectTopLevelProductsForCategory(categoryId: ProductCategory['id']) {
    return this.topLevelProducts$.pipe(
      map(products => products.filter(product => product.product_category_id === categoryId)),
    );
  }

  selectProductIsLoading(id: Product['id']) {
    return combineQueries([
      this.isLoading$,
      this.activeProduct$,
      this.supplierProductsQuery.isLoading$,
    ]).pipe(
      map(([isLoading, activeProduct, isSuppliersLoading]) =>
        (isLoading || isSuppliersLoading) && activeProduct?.id === id
      ),
    );
  }

  private selectProductAttributesForProduct(product: Product): Observable<ProductAttribute[]> {
    if (!product) {
      return of([]);
    }

    // For variant products we want to merge the attributes of the virtual product with the attributes of the variant
    if (product.parent_id) {
      return this.selectProductById(product.parent_id).pipe(
        map(parentProduct => mergeProductAttributesForVariant(parentProduct, product)),
      );
    }

    return of(product.product_attributes);
  }
}
