import {SessionQuery} from '@/core/session/state/session.query';
import {User} from '@/core/session/state/user/user.model';
import {HttpParamOptions} from '@/shared/enums/api/http-param-options';
import {ProductCategoryApprover} from '@/shared/state/product-category-approvers/product-category-approver.model';
import {
  ProductCategoryApproversQuery
} from '@/shared/state/product-category-approvers/product-category-approvers.query';
import {
  ProductCategoryApproversService
} from '@/shared/state/product-category-approvers/product-category-approvers.service';
import {ApiResponse} from '@/shared/types/api/api-response';
import {handleError} from '@/shared/utils';
import {
  createHttpIncludes,
  getHttpOptionsWithInclude,
  getHttpOptionsWithParams
} from '@/shared/utils/functions/http-params';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {arrayAdd, setLoading} from '@datorama/akita';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {ShopRouteNames} from '../../../constants/routes.constants';
import {ProductCategoryRouteNames} from '../../../product-category/constants';
import {
  CostCenterProductCategoryPivotsService
} from '../cost-center-product-category/cost-center-product-category-pivots.service';
import {ProductCategoryFilterPresets} from './enums/product-category-filter-presets';
import {ProductCategoriesQuery} from './product-categories.query';
import {ProductCategoriesStore} from './product-categories.store';
import {ProductCategory} from './product-category.model';

@Injectable({providedIn: 'root'})
export class ProductCategoriesService {
  static readonly includes = createHttpIncludes([
    'children',
    'parent',
    'costCenters',
  ]);

  private readonly productCategoriesQuery = inject(ProductCategoriesQuery);
  private readonly productCategoriesStore = inject(ProductCategoriesStore);
  private readonly costCenterProductCategoryPivotsService = inject(CostCenterProductCategoryPivotsService);
  private readonly productCategoryApproversService = inject(ProductCategoryApproversService);
  private readonly productCategoryApproversQuery = inject(ProductCategoryApproversQuery);
  private readonly sessionQuery = inject(SessionQuery);
  private readonly http = inject(HttpClient);
  private readonly router = inject(Router);
  private readonly snackBar = inject(MatSnackBar);

  get() {
    const options = getHttpOptionsWithInclude(ProductCategoriesService.includes, {
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http
      .get<ApiResponse<ProductCategory[]>>(environment.api.baseUrl + 'product-categories', options)
      .pipe(
        setLoading(this.productCategoriesStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productCategoriesStore)),
        tap(({data: productCategories}) => {
          this.productCategoriesStore.set(productCategories);
        }),
      );
  }

  getById(id: number) {
    const options = getHttpOptionsWithInclude(ProductCategoriesService.includes, {
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http
      .get<ApiResponse<ProductCategory>>(environment.api.baseUrl + 'product-categories/' + id, options)
      .pipe(
        setLoading(this.productCategoriesStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productCategoriesStore)),
        tap(({data: productCategory}) => {
          const {cost_centers, ...remainingProductCategory} = productCategory;

          this.productCategoriesStore.add(remainingProductCategory);
          this.costCenterProductCategoryPivotsService.setFromCostCenterProductCategories(cost_centers);
        }),
      );
  }

  update(productCategory: ProductCategory) {
    const options = getHttpOptionsWithInclude(ProductCategoriesService.includes, {
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http.put<ApiResponse<ProductCategory>>(environment.api.baseUrl + 'product-categories/' + productCategory.id, productCategory, options)
      .pipe(
        setLoading(this.productCategoriesStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productCategoriesStore)),
        tap(({data}) => this.productCategoriesStore.update(data)),
      );
  }

  getProductCategoryApproversOfProductCategoryById(id: number) {
    const options = getHttpOptionsWithInclude(ProductCategoryApproversService.includes, {
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http
      .get<ApiResponse<ProductCategoryApprover[]>>(environment.api.baseUrl + 'product-categories/' + id + '/approvers', options)
      .pipe(
        setLoading(this.productCategoriesStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productCategoriesStore)),
        tap((response) => {
          if (response?.data) {
            for (const productCategoryApprover of response.data) {
              this.productCategoryApproversService.upsert(productCategoryApprover.id, productCategoryApprover);
            }
          }
        }),
      );
  }

  getForApprover(userId: User['id']) {
    const options = getHttpOptionsWithParams({
      tenant_id: this.sessionQuery.tenantId.toString(),
      [HttpParamOptions.FilterPreset]: ProductCategoryFilterPresets.ProductCategoryApprover,
    });

    return this.http
      .get<ApiResponse<ProductCategory[]>>(environment.api.baseUrl + `users/${userId}/product-categories`, options)
      .pipe(
        setLoading(this.productCategoriesStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productCategoriesStore)),
        tap(response => this.productCategoriesStore.upsertMany(response.data)),
      );
  }

  setActiveProductCategory(id: number) {
    if (!this.productCategoriesQuery.hasEntity(id)) {
      this.getById(id).subscribe({
        next: (response) => {
          this.productCategoriesStore.setActive(response['data']?.id);
        }
      });
    } else {
      this.productCategoriesStore.setActive(id);
    }
  }

  // TODO: Move to separate product category approvers service
  addProductCategoryApproverToProductCategoryOnDb(id: number, productCategoryApprover: ProductCategoryApprover) {
    const options = getHttpOptionsWithInclude(ProductCategoryApproversService.includes, {
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http.post<ApiResponse<ProductCategoryApprover>>(
      environment.api.baseUrl + 'product-categories/' + id + '/approvers',
      productCategoryApprover,
      options
    )
      .pipe(
        setLoading(this.productCategoriesStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productCategoriesStore)),
        tap((response) => {
          // HINT: Validate 'duplicate' usage of product category approvers in product category store
          // HINT: Validate only 'adding' product category approvers to product category store (no updates or deletes)
          // Add product category approver to existing product category in store
          this.productCategoriesStore.update(
            id,
            ({productCategoryApprovers}) => ({
              productCategoryApprovers: arrayAdd(
                productCategoryApprovers,
                response.data
              )
            })
          );

          // Add product category approver to product category approvers store
          this.productCategoryApproversService.add(response.data);
        })
      );
  }

  updateProductCategoryApproverFromProductCategoryOnDb(id: number, productCategoryApprover: ProductCategoryApprover) {
    const options = getHttpOptionsWithInclude(ProductCategoryApproversService.includes, {
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http.put<ApiResponse<ProductCategoryApprover>>(
      environment.api.baseUrl + 'product-categories/' + id + '/approvers/' + productCategoryApprover.id,
      productCategoryApprover,
      options
    )
      .pipe(
        setLoading(this.productCategoriesStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productCategoriesStore)),
        tap((response) => {
          // Update product category approver to product category approvers store
          if (response?.data) {
            this.productCategoryApproversService.upsert(response.data.id, response.data);
          }
        })
      );
  }

  removeProductCategoryApproverFromProductCategoryOnDb(id: number, productCategoryApproverId: number) {
    const options = getHttpOptionsWithParams({
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http.delete<ApiResponse<ProductCategoryApprover>>(
      environment.api.baseUrl + 'product-categories/' + id + '/approvers/' + productCategoryApproverId,
      options
    ).pipe(
      setLoading(this.productCategoriesStore),
      catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productCategoriesStore)),
      tap(() => {
        // Remove product category approver from product category approvers
        if (this.productCategoryApproversQuery.hasEntity(productCategoryApproverId)) {
          this.productCategoryApproversService.remove(productCategoryApproverId);
        }
      })
    );
  }

  navigateToProductCategoryDetails(id: number): Promise<boolean> {
    if (id !== this.productCategoriesQuery.getActiveId()) {
      this.setActiveProductCategory(null);
    }
    return this.router.navigate([
      ShopRouteNames.SHOP + '/' + ProductCategoryRouteNames.PRODUCT_CATEGORY,
      id
    ]);
  }
}
