import {OrderLogEntry} from '../../../../components/order-log/order-log.component';
import {OrderLogEntryTitle} from '../../../../enums/order-log-entry-title';
import {LogEntryStates} from '../../enums/log-entry-states.enum';
import {OrderActivityName} from '../../enums/order-activity-names.enum';
import {OrderActivity} from '../../order-activity.model';
import {getAdditionalDataForItemsUpdated} from '../get-additional-data-for-items-updated';
import {isOrderItemsUpdatedData} from '../is-order-items-updated-data';

export const transformOrderActivitiesForUi = (orderActivities: OrderActivity[]) => {
  let result: OrderLogEntry[] = [];

  // Check if order is rejected, failed or finished
  const orderEndStatus = orderActivities.reverse().find(orderActivity => (
    orderActivity.name === OrderActivityName.Rejected
    || orderActivity.name === OrderActivityName.Failed
    || orderActivity.name === OrderActivityName.Finished
    || orderActivity.name === OrderActivityName.Canceled
  ));
  // Check if approval workflow is finished
  const orderApprovalWorkflowEndFinished = orderActivities.some(orderActivity => orderActivity.name === OrderActivityName.ApprovalWorkflowFinished);

  for (const orderActivity of orderActivities.reverse()) {
    const transformOrderActivity: OrderLogEntry = {
      text: orderActivity.display_text
    };

    switch (orderActivity?.name) {
      case OrderActivityName.Created:
        transformOrderActivity.status = LogEntryStates.Ok;
        transformOrderActivity.title = OrderLogEntryTitle.Created;
        break;
      case OrderActivityName.ApprovalWorkflowStarted: {
        transformOrderActivity.listEntries = [];

        transformOrderActivity.title = OrderLogEntryTitle.ApprovalWorkflowStarted;

        if (orderEndStatus?.name === OrderActivityName.Rejected || orderEndStatus?.name === OrderActivityName.Canceled) {
          transformOrderActivity.status = LogEntryStates.Error;
        } else if (orderApprovalWorkflowEndFinished) {
          transformOrderActivity.status = LogEntryStates.Ok;
        } else {
          transformOrderActivity.status = LogEntryStates.Pending;
        }
        break;
      }
      case OrderActivityName.ApprovalWorkflowActivity: {
        // TODO: Create/move interface for activity data(s)
        const approvalWorkflowActivityData: {
          approverComment: string;
          approverApproved: boolean;
          offerCreated?: boolean;
          confirmed?: boolean
        } = orderActivity?.data as {
          approverComment: string;
          approverApproved: boolean;
          offerCreated?: boolean;
          confirmed?: boolean
        };
        if (approvalWorkflowActivityData && (approvalWorkflowActivityData?.approverApproved === true || approvalWorkflowActivityData?.offerCreated === true || approvalWorkflowActivityData?.confirmed === true)) {
          transformOrderActivity.status = LogEntryStates.Ok;
        } else {
          transformOrderActivity.status = LogEntryStates.Error;
        }

        if (approvalWorkflowActivityData?.approverComment) {
          transformOrderActivity.additionalInformation = [{
            title: 'Kommentar',
            text: approvalWorkflowActivityData?.approverComment
          }];
        }

        result = addOrderActivityToApprovalWorkflowLogs(result, transformOrderActivity);
        continue;
      }
      case OrderActivityName.ApprovalWorkflowFinished: {
        transformOrderActivity.title = OrderLogEntryTitle.ApprovalWorkflowFinished;

        if (orderEndStatus?.name === OrderActivityName.Rejected) {
          transformOrderActivity.status = LogEntryStates.Error;
        } else {
          transformOrderActivity.status = LogEntryStates.Ok;
        }
        break;
      }
      case OrderActivityName.Rejected: {
        transformOrderActivity.status = LogEntryStates.Error;
        transformOrderActivity.title = OrderLogEntryTitle.Rejected;
        break;
      }
      case OrderActivityName.Started: {
        transformOrderActivity.title = OrderLogEntryTitle.Started;

        if (orderEndStatus?.name === OrderActivityName.Finished) {
          transformOrderActivity.status = LogEntryStates.Ok;
        } else if (orderEndStatus?.name === OrderActivityName.Failed) {
          transformOrderActivity.status = LogEntryStates.Error;
        } else {
          transformOrderActivity.status = LogEntryStates.Pending;
        }
        break;
      }
      case OrderActivityName.Failed: {
        transformOrderActivity.status = LogEntryStates.Error;
        transformOrderActivity.title = OrderLogEntryTitle.Failed;
        break;
      }
      case OrderActivityName.Canceled: {
        transformOrderActivity.status = LogEntryStates.Error;
        transformOrderActivity.title = OrderLogEntryTitle.Canceled;

        const canceledActivityData: { cancelReason: string; cancelReasonText: string } = orderActivity?.data as {
          cancelReason: string;
          cancelReasonText: string
        };
        transformOrderActivity.additionalInformation = [{
          title: 'Grund',
          text: canceledActivityData?.cancelReasonText
        }];
        break;
      }
      case OrderActivityName.Finished: {
        transformOrderActivity.status = LogEntryStates.Ok;
        transformOrderActivity.title = OrderLogEntryTitle.Finished;
        break;
      }
      case OrderActivityName.ItemsUpdated: {
        transformOrderActivity.status = LogEntryStates.Ok;
        transformOrderActivity.title = OrderLogEntryTitle.ItemsUpdated;

        if (isOrderItemsUpdatedData(orderActivity.data)) {
          transformOrderActivity.additionalInformation = getAdditionalDataForItemsUpdated(orderActivity.data);
        }

        result = addOrderActivityToApprovalWorkflowLogs(result, transformOrderActivity);
        continue;
      }
      default: {
        continue;
      }
    }
    result = [...result, transformOrderActivity];
  }

  return result.reverse();
};

const addOrderActivityToApprovalWorkflowLogs = (existingOrderLogs: OrderLogEntry[], orderActivity: OrderLogEntry) => {
  return existingOrderLogs.map(orderLogEntry => {
    if (orderLogEntry.title === OrderLogEntryTitle.ApprovalWorkflowStarted) {
      orderLogEntry.listEntries = [orderActivity, ...orderLogEntry.listEntries];
    }
    return orderLogEntry;
  });
}
