<div class="mat-typography">
    <div>
        <div
          class="mat-headline-5"
          mat-dialog-title>Möchten Sie wirklich mit dem Löschen fortfahren?
        </div>
    </div>
    <mat-dialog-actions align="end">
        <button
          mat-button
          (click)="cancel()">Abbrechen
        </button>
        <button
          mat-flat-button
          color="primary"
          (click)="confirm()">
            Bestätigen
        </button>
    </mat-dialog-actions>
</div>
