<ng-container *ngIf="(navigationModeActive$ | async) as navigationModeActive">
    <div class="!flex flex-col h-full box-border w-full">
        <mat-nav-list class="!flex flex-1 flex-col w-full">
            <div class="flex-1">
                <div class="flex items-center mb-2">
                    <button
                      mat-mini-fab
                      disabled
                      class="navigation-mode-icon"
                      color="primary">
                        <mat-icon
                          *ngIf="navigationModeActive.key === 'manage'">
                            add_business
                        </mat-icon>
                        <mat-icon
                          *ngIf="navigationModeActive.key === 'purchase'">
                            shopping_cart
                        </mat-icon>
                        <mat-icon
                          *ngIf="
            navigationModeActive.key !== 'manage' &&
            navigationModeActive.key !== 'purchase'">
                            account_circle
                        </mat-icon>
                    </button>

                    <button
                      *ngIf="availableNavigationModes?.length > 1; else noNavigationMenu"
                      mat-button
                      [matMenuTriggerFor]="navigationMenu"
                      class="navigationMode"
                      color="primary">
                        <div class="flex items-center">
                            <span>{{ navigationModeActive["value"] }}</span>
                            <mat-icon>arrow_drop_down</mat-icon>
                        </div>
                    </button>

                    <ng-template #noNavigationMenu>
                        <div class="flex justify-center ml-1 px-1 color-primary">
                            <span>{{ navigationModeActive["value"] }}</span>
                        </div>
                    </ng-template>
                </div>
                <mat-menu #navigationMenu="matMenu">
                    <ng-container *ngFor="let navigationMode of availableNavigationModes">
                        <button
                          mat-menu-item
                          *ngIf="navigationMode.key !== navigationModeActive['key']"
                          (click)="updateNavigationMode(navigationMode)"
                          color="primary">
                            {{ navigationMode.value }}
                        </button>
                    </ng-container>
                </mat-menu>
                <mat-divider></mat-divider>
                <a
                  mat-list-item
                  class="menu-item"
                  routerLinkActive="active"
                  [routerLink]="['/home']">
                    <mat-icon class="sidenav-icon">home</mat-icon>
                    Startseite
                </a>
                <ng-container *ngIf="navigationModeActive.key === 'manage'">
                    <mat-divider></mat-divider>

                    <span class="section_header">Kostenstellen verwalten</span>
                    <a
                      tabindex="0"
                      mat-list-item
                      (click)="navigateToCostCentersCreate()">
                        <mat-icon class="sidenav-icon">add_circle_outline</mat-icon>
                        Kostenstelle erstellen
                    </a>
                    <a
                      tabindex="0"
                      mat-list-item
                      (click)="navigateToCostCenterList()">
                        <mat-icon class="sidenav-icon">request_quote</mat-icon>
                        Kostenstellen anzeigen
                    </a>

                    <mat-divider></mat-divider>

                    <span class="section_header">Produktkategorien verwalten</span>
                    <a
                      tabindex="0"
                      mat-list-item
                      (click)="navigateToProductCategoryList()">
                        <mat-icon class="sidenav-icon">request_quote</mat-icon>
                        Produktkategorien anzeigen
                    </a>

                    <mat-divider></mat-divider>

                    <span class="section_header">Artikel verwalten</span>
                    <a
                      mat-list-item
                      routerLinkActive="active"
                      [routerLink]="productCreateRouterLink">
                        <mat-icon class="sidenav-icon">add_circle_outline</mat-icon>
                        Artikel erstellen
                    </a>
                    <a
                      tabindex="0"
                      mat-list-item
                      routerLinkActive="active"
                      [routerLink]="productListRouterLink">
                        <mat-icon class="sidenav-icon">fact_check</mat-icon>
                        Artikel anzeigen
                    </a>

                    <mat-divider></mat-divider>

                    <span class="section_header">Lieferanten verwalten</span>
                    <a
                      tabindex="0"
                      mat-list-item
                      (click)="navigateToSupplierCreate()">
                        <mat-icon class="sidenav-icon">corporate_fare</mat-icon>
                        Lieferant erstellen
                    </a>
                    <a
                      tabindex="0"
                      mat-list-item
                      (click)="navigateToSupplierList()">
                        <mat-icon class="sidenav-icon">view_list</mat-icon>
                        Lieferanten anzeigen
                    </a>
                </ng-container>

                <ng-container *ngIf="navigationModeActive.key === 'purchase'">
                    <div>
                        <a
                          mat-list-item
                          class="menu-item"
                          routerLinkActive="active"
                          [routerLink]="['/shop/order']">
                            <mat-icon class="sidenav-icon">dynamic_feed</mat-icon>
                            Meine Aufträge
                        </a>
                    </div>
                    <div>
                        <a
                          mat-list-item
                          class="menu-item"
                          routerLinkActive="active"
                          [routerLink]="['/shop/favorites']">
                            <mat-icon class="sidenav-icon">favorite</mat-icon>
                            Meine Favoriten
                        </a>
                    </div>

                    <mat-divider></mat-divider>

                    <span class="section_header">Kategorien</span>
                    <div class="productCategoryWrapper">
                        <mat-accordion>
                            <ng-container *ngFor="let productCategory of productCategories$ | async">
                                <div *ngIf="productCategory.parent_id === null">
                                    <mat-expansion-panel class="product-category-main">
                                        <mat-expansion-panel-header
                                          class="items-center"
                                          (click)="navigateToProductCategoryDetails(productCategory.id)">
                                            <mat-panel-title>
                                                <div class="flex">
                                                    <mat-icon class="sidenav-icon">sort</mat-icon>
                                                </div>
                                                <div class="flex">
                                                    <span>{{ productCategory.display_name }}</span>
                                                </div>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>

                                        <app-sidenav-category-tree
                                          (productCategoryClick)="navigateToProductCategoryDetails($event)"
                                          [childProductCategories]="productCategory.id | childCategoriesByParentId$ | async"
                                          [indentation]="1">
                                        </app-sidenav-category-tree>
                                    </mat-expansion-panel>
                                </div>
                            </ng-container>
                        </mat-accordion>
                    </div>
                    <mat-divider></mat-divider>
                </ng-container>
            </div>

            <div
              class="flex flex-col align-center justify-end gap-1">
                <a
                  *ngIf="navigationModeActive.key === 'purchase' && isSupplierProductProposalAllowed"
                  type="button"
                  mat-raised-button
                  class="createProduct"
                  [routerLink]="supplierProductProposalRouterLink"
                  color="primary">
                    Artikel vorschlagen
                </a>

                <button
                  *ngIf="(sleekplanQuery.isInitialized$ | async) === true"
                  mat-button
                  color="primary"
                  [disabled]="(sleekplanQuery.widgetOpen$ | async) === true"
                  (click)="sleekplanService.openFeedback()"
                  class="feedback-button">
                    <fa-icon
                      *ngIf="(sleekplanQuery.loading$ | async) === true"
                      [icon]="['far', 'spinner-third']"
                      [fixedWidth]="true"
                      animation="spin"
                      size="lg">
                    </fa-icon>

                    <ng-container *ngIf="(sleekplanQuery.loading$ | async) === false">
                        <fa-icon
                          [icon]="['far', 'lightbulb-on']"
                          size="lg" />

                        <ng-container *ngIf="isSidenavOpen">
                            Feedback
                        </ng-container>
                    </ng-container>
                </button>
                <div class="app-version-wrapper">
                    <div>
                        {{ appVersion }}
                    </div>
                    <div
                      *ngIf="isSidenavOpen">
                        {{ stage }}
                    </div>
                </div>
            </div>
        </mat-nav-list>
    </div>
</ng-container>
