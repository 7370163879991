import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {DqnOption} from '@dqn/components/combobox';
import {map} from 'rxjs/operators';
import {ProductAttributeGroupsState, ProductAttributeGroupsStore} from './product-attribute-groups.store';

@Injectable({providedIn: 'root'})
export class ProductAttributeGroupsQuery extends QueryEntity<ProductAttributeGroupsState> {

  constructor(protected store: ProductAttributeGroupsStore) {
    super(store);
  }

  productAttributeGroups$ = this.selectAll();

  productAttributeGroupsAsDqnOptions$ = this.productAttributeGroups$.pipe(
    map(productAttributeGroup => productAttributeGroup.map(({id, display_name}) => ({
        value: id,
        title: display_name,
      } as DqnOption))
    ),
  );
}
