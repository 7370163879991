import {Pipe, PipeTransform} from '@angular/core';
import {BillingFrequenciesQuery} from '../state/billing-frequencies/billing-frequencies.query';
import {BillingFrequency} from '../state/billing-frequencies/billing-frequency.model';

@Pipe({
  name: 'billingFrequencyById'
})
export class BillingFrequencyByIdPipe implements PipeTransform {
  constructor(
    private billingFrequenciesQuery: BillingFrequenciesQuery,
  ) {
  }

  transform(billingFrequencyId: BillingFrequency['id']) {
    return this.billingFrequenciesQuery.selectBillingFrequencyById(billingFrequencyId);
  }
}
