<div
  *ngIf="!formMode.isReadonly"
  class="flex justify-end">
    <button
      mat-raised-button
      color="primary"
      [disabled]="(variantAttributesManagementQuery.variantsAttributes$ | async)?.length === 0"
      (click)="openVariantsAddDialog()">
        Variante hinzufügen
    </button>
</div>

<dqn-table
  size="small"
  [headers]="variantHeaders"
  [rows]="(query.variants$ | async) ?? []">
    <td
      *dqnTableCellTemplate="'variant'; let product"
      dqnTableCell>
        {{ (product.product_attributes | filterProductAttributesByVariantAttributes: variantAttributesManagementQuery.variantAttributes | nameFromProductAttributes) ?? product.name }}
    </td>

    <td
      *dqnTableCellTemplate="'status'; let product"
      dqnTableCell>
        <mat-chip-list
          *ngIf="product.status as productStatus"
          class="status">
            <mat-chip
              class="{{productStatus}}"
              selected>
                {{ productStatus | statusDisplayNameForName }}
            </mat-chip>
        </mat-chip-list>
    </td>

    <td
      *dqnTableCellTemplate="'suppliers'; let product"
      dqnTableCell>
        {{ product.supplier_product_count }}
    </td>

    <dqn-table-row-actions *dqnTableRowAction="let product">
        <a
          *ngIf="product.id > 0"
          [routerLink]="product.id | productDetailsRouterLink">
            <dqn-table-row-action-details text="Details anzeigen" />
        </a>
        <a
          *ngIf="product.id > 0"
          [routerLink]="product.id | productEditRouterLink">
            <dqn-table-row-action-edit text="Bearbeiten" />
        </a>

        <dqn-table-row-action-delete
          *ngIf="!formMode.isReadonly"
          text="Löschen"
          [disabled]="!!product.supplier_product_count"
          (click)="removeVariant(product.id)" />
    </dqn-table-row-actions>
</dqn-table>
