import {SessionQuery} from '@/core/session/state/session.query';
import {SessionService} from '@/core/session/state/session.service';
import {User} from '@/core/session/state/user/user.model';
import {AfterViewInit, ChangeDetectionStrategy, Component, inject, OnInit, ViewChild} from '@angular/core';
import {MatDrawerContainer} from '@angular/material/sidenav';
import {Router, Scroll} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {filter, take, tap} from 'rxjs/operators';
import {UiQuery} from '../state/ui.query';
import {UiService} from '../state/ui.service';

@UntilDestroy()
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent implements OnInit, AfterViewInit {
  private readonly sessionService = inject(SessionService);
  private readonly sessionQuery = inject(SessionQuery);
  private readonly uiQuery = inject(UiQuery);
  private readonly uiService = inject(UiService);
  private readonly router = inject(Router);

  @ViewChild(MatDrawerContainer) drawerContainer: MatDrawerContainer;

  isSidenavOpen: boolean;
  toolBarHeight = 45; // TODO Set height of header separate and combine them in this variable
  user: User;

  ngOnInit(): void {
    this.sessionService.getUser().pipe(take(1)).subscribe();

    this.uiQuery.select().pipe(untilDestroyed(this)).subscribe(result => {
      this.isSidenavOpen = result.isSidenavOpen;
    });
    this.sessionQuery.user$.pipe(untilDestroyed(this)).subscribe(user => {
      this.user = user;
    });
  }

  ngAfterViewInit() {
    // Reset scrolling position for mat drawer content back to top on routing
    // Uses routing scroll events, so we could actually restore the correct scroll position if we manage to save it
    // before routing.
    // Discussions about the behavior and solutions: https://github.com/angular/angular/issues/24547
    this.router.events.pipe(
      filter((e): e is Scroll => e instanceof Scroll),
      tap(() => this.drawerContainer.scrollable.scrollTo({top: 0})),
      untilDestroyed(this),
    ).subscribe();
  }

  sidenavToggle(): void {
    this.isSidenavOpen = !this.isSidenavOpen;
    this.uiService.toggleSidenavState(this.isSidenavOpen);
  }
}
