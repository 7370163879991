import {Pipe, PipeTransform} from '@angular/core';
import {isArtificialId} from '../helpers/is-artificial-id';

@Pipe({
  name: 'isArtificialId',
})
export class IsArtificialIdPipe implements PipeTransform {
  transform(id: number) {
    return isArtificialId(id);
  }
}
