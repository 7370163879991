import {ApproversQuery} from '@/shared/state/approvers/approvers.query';
import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {DqnSingleOption} from '@dqn/components/combobox';
import {map} from 'rxjs/operators';
import {CostCentersState, CostCentersStore} from './cost-centers.store';

@Injectable({providedIn: 'root'})
export class CostCentersQuery extends QueryEntity<CostCentersState> {
  costCenters$ = this.selectAll();

  costCentersAsDqnOptions$ = this.costCenters$.pipe(
    map(costCenters => costCenters.map(costCenter => ({
      value: costCenter.id,
      title: costCenter.name,
      attributes: [
        costCenter.cost_center_number,
      ]
    } as DqnSingleOption)))
  );

  constructor(
    protected store: CostCentersStore,
    protected approversQuery: ApproversQuery
  ) {
    super(store);
  }

  selectCostCenter(id: number) {
    return this.selectEntity(id);
  }

  selectApproversOfCostCenterById(id: number) {
    return this.approversQuery.selectAll({
      filterBy: ({cost_center_id}) => cost_center_id == id
    })
  }

}
