export enum OrderLogEntryTitle {
  Created = 'Bestellung erstellt',
  ApprovalWorkflowStarted = 'Genehmigungsprozess angestoßen',
  ApprovalWorkflowFinished = 'Genehmigungsprozess abgeschlossen',
  Rejected = 'Bestellung abgelehnt',
  Started = 'Bestellung wird ausgeführt',
  Failed = 'Bestellung fehlgeschlagen',
  Finished = 'Bestellung erfolgreich abgeschlossen',
  Canceled = 'Bestellung abgebrochen',
  ItemsUpdated = 'Inhalt der Bestellung angepasst',
}
