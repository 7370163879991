<div
  matDialogTitle
  class="mat-typography flex justify-between items-center">
    Adresse auswählen

    <button
      mat-icon-button
      mat-dialog-close
      matTooltip="Abbrechen"
      class="close-button">
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content class="!flex flex-col">
    <dqn-form-field class="w-full">
        <dqn-label>Adresse</dqn-label>
        <input
          [formControl]="addressSearchControl"
          type="text"
          dqnInput>
    </dqn-form-field>

    <ul class="dqn-scrollbar">
        <app-address-list-item
          *ngFor="let shippingAddress of filteredAddresses$ | async"
          (selectAddress)="selectAddress(shippingAddress)"
          [address]="shippingAddress"
          [isSelected]="selectedAddress?.id === shippingAddress.id">
        </app-address-list-item>
    </ul>
</mat-dialog-content>
