import {SessionQuery} from '@/core/session/state/session.query';
import {ProductForm} from '@/shared/components/product-form/types/product-form';
import {HttpParamOptions} from '@/shared/enums/api/http-param-options';
import {ApiResponse} from '@/shared/types/api/api-response';
import {handleError} from '@/shared/utils';
import {
  createHttpAttributes,
  createHttpIncludes,
  getHttpOptionsWithInclude,
  getHttpOptionsWithIncludeAndAttributes,
  getHttpOptionsWithParams,
  getHttpParams
} from '@/shared/utils/functions/http-params';
import {ProductFilterPresets} from '@/shop/product/state/enums/product-filter-presets';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ID, PaginationResponse, setLoading} from '@datorama/akita';
import {forkJoin, Observable, of} from 'rxjs';
import {catchError, filter, map, mapTo, switchMap, tap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {createProductAttribute} from './product-attributes/product-attribute.model';
import {ProductAttributesService} from './product-attributes/product-attributes.service';
import {Product} from './product.model';
import {ProductsStore} from './products.store';
import {createSupplierProduct} from './supplier-products/supplier-product.model';
import {SupplierProductsService} from './supplier-products/supplier-products.service';
import {VariantBulkAddVariant} from './types/variant-bulk-add-variant';
import {
  BulkUpdateVariantAttributesRequestItem
} from './variant-attribute-options/types/bulk-update-variant-attributes-request-item';
import {VariantAttributeOptionsService} from './variant-attribute-options/variant-attribute-options.service';
import {
  findVariantAttributeOptionForProductAttribute
} from './variant-attributes/helpers/find-variant-attribute-option-for-product-attribute';
import {BulkAddVariantAttributesRequestItem} from './variant-attributes/types/bulk-add-variant-attributes-request-item';
import {VariantAttributesQuery} from './variant-attributes/variant-attributes.query';
import {VariantAttributesService} from './variant-attributes/variant-attributes.service';

@Injectable({providedIn: 'root'})
export class ProductsService {
  static includes = createHttpIncludes([
    'productCategory',
    'supplierProducts',
    'suppliers',
    'productAttributes',
    'productAttributes.productAttributeTemplate.productAttributeType',
    'productAttributes.productAttributeTemplate.quantityUnit',
    'images',
  ]);

  static defaultAttributes = createHttpAttributes([
    'id',
    'tenant_id',
    'name',
    'description',
    'description_long',
    'keywords',
    'ean_gtin',
    'product_category_id',
    'product_category',
    'quantity_unit_id',
    'quantity_unit',
    'scale_unit_id',
    'scale_unit',
    'selling_quantity',
    'basic_quantity',
    'product_attributes',
    'requires_approval',
    'requires_offer',
    'images',
    'is_favorite',
    'is_virtual',
    'parent_id',
    'supplier_product_count',
    'variant_count',
    'preferred_supplier_product_id',
    'status',
  ]);

  static defaultListAttributes = createHttpAttributes([
    ProductsService.defaultAttributes,
    'price_unit_price_from',
    'price_unit_price_to',
    'price_unit_price_currency_code_id',
    'price_billing_frequency_price_from',
    'price_billing_frequency_price_to',
    'price_billing_frequency_price_currency_code_id',
  ]);

  private readonly productsStore = inject(ProductsStore);
  private readonly productAttributesService = inject(ProductAttributesService);
  private readonly supplierProductsService = inject(SupplierProductsService);
  private readonly variantAttributesService = inject(VariantAttributesService);
  private readonly variantAttributeOptionsService = inject(VariantAttributeOptionsService);
  private readonly http = inject(HttpClient);
  private readonly snackBar = inject(MatSnackBar);
  private readonly sessionQuery = inject(SessionQuery);
  private readonly variantAttributesQuery = inject(VariantAttributesQuery);

  get() {
    const options = getHttpOptionsWithIncludeAndAttributes(
      ProductsService.includes,
      ProductsService.defaultAttributes,
      {
        tenant_id: this.sessionQuery?.tenantId?.toString(),
      }
    );

    return this.http.get<ApiResponse<Product[]>>(environment.api.baseUrl + 'products', options).pipe(
      setLoading(this.productsStore),
      catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productsStore)),
      tap(({data: products}) => {
        this.productsStore.set(products);
      })
    );
  }

  getById(id: number) {
    const options = getHttpOptionsWithIncludeAndAttributes(
      ProductsService.includes,
      ProductsService.defaultAttributes,
      {
        tenant_id: this.sessionQuery?.tenantId?.toString(),
      }
    );

    return this.http.get<ApiResponse<Product>>(environment.api.baseUrl + 'products/' + id, options).pipe(
      setLoading(this.productsStore),
      catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productsStore)),
      tap(({data: product}) => {
        this.productsStore.upsert(product.id, product);
      })
    );
  }

  getVariantsForProduct(productId: Product['id']) {
    const options = getHttpOptionsWithIncludeAndAttributes(
      ProductsService.includes,
      ProductsService.defaultListAttributes,
      {
        tenant_id: this.sessionQuery?.tenantId?.toString(),
      }
    );

    return this.http.get<ApiResponse<Product[]>>(`${environment.api.baseUrl}products/${productId}/variants`, options).pipe(
      setLoading(this.productsStore),
      catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productsStore)),
      tap(({data: products}) => this.productsStore.upsertMany(products)),
    );
  }

  getPage(page = 1, perPage = 2, searchTerm = ''): Observable<PaginationResponse<Product>> {
    let httpParams = getHttpParams({
      [HttpParamOptions.Include]: ProductsService.includes,
      [HttpParamOptions.Attributes]: ProductsService.defaultAttributes,
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    if (httpParams && page) {
      httpParams = httpParams.set('page', page.toString());
    }
    if (httpParams && perPage) {
      httpParams = httpParams.set('per_page', perPage.toString());
    }
    if (httpParams && searchTerm) {
      httpParams = httpParams.set(HttpParamOptions.FilterPreset, ProductFilterPresets.AdvancedSearch);
      httpParams = httpParams.set(HttpParamOptions.FilterPresetData + '[searchTerm]', searchTerm);
    }

    const options = {
      params: httpParams
    };

    return this.http
      .get<ApiResponse<Product[]>>(environment.api.baseUrl + 'products', options)
      .pipe(
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productsStore)),
        map((apiResponse) => {
          return {
            currentPage: apiResponse.meta.current_page,
            perPage: apiResponse.meta.per_page,
            lastPage: apiResponse.meta.last_page,
            data: apiResponse?.data,
            total: apiResponse.meta.total,
            from: apiResponse.meta.from,
            to: apiResponse.meta.to
          };
        }),
      );
  }

  addOnDb(product: Product) {
    const options = getHttpOptionsWithInclude(ProductsService.includes, {
      tenant_id: this.sessionQuery?.tenantId?.toString(),
    });

    return this.http.post<ApiResponse<Product>>(environment.api.baseUrl + 'products', product, options).pipe(
      setLoading(this.productsStore),
      catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productsStore)),
      tap(({data}) => {
        this.productsStore.add(data);
      })
    );
  }

  updateOnDb(id: Product['id'], product: Partial<Product>) {
    const options = getHttpOptionsWithInclude(ProductsService.includes, {
      tenant_id: this.sessionQuery?.tenantId?.toString(),
    });

    return this.http.put<ApiResponse<Product>>(environment.api.baseUrl + 'products/' + id, product, options).pipe(
      setLoading(this.productsStore),
      catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productsStore)),
      tap(({data}) => {
        this.productsStore.update(id, data);
      })
    );
  }

  removeOnDb(id: ID) {
    const options = getHttpOptionsWithParams({
      tenant_id: this.sessionQuery?.tenantId?.toString(),
    });

    return this.http.delete(environment.api.baseUrl + 'products/' + id, options).pipe(
      setLoading(this.productsStore),
      catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productsStore)),
      tap(() => {
        this.productsStore.remove(id);
      })
    );
  }

  bulkAddVariants(productId: number, variants: VariantBulkAddVariant[]) {
    const options = getHttpOptionsWithInclude(ProductsService.includes, {
      tenant_id: this.sessionQuery?.tenantId?.toString(),
    });

    const body = {variants};

    return this.http.post<ApiResponse<Product[]>>(environment.api.baseUrl + 'products/' + productId + '/variants/bulk-create', body, options).pipe(
      setLoading(this.productsStore),
      catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productsStore)),
    );
  }

  bulkRemoveVariants(productId: number, variants: { id: number }[]) {
    const options = getHttpOptionsWithParams({
      tenant_id: this.sessionQuery?.tenantId?.toString(),
    });

    const body = {variants};

    return this.http.post<ApiResponse<Product[]>>(environment.api.baseUrl + 'products/' + productId + '/variants/bulk-delete', body, options).pipe(
      setLoading(this.productsStore),
      catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productsStore)),
      tap(() => {
        this.productsStore.remove(variants.map(variant => variant.id));
      }),
    );
  }

  addOrUpdateImage(productId: number, file: string | Blob, name = 'default') {
    const options = getHttpOptionsWithParams({
      tenant_id: this.sessionQuery?.tenantId?.toString(),
    });

    const formData = new FormData();
    formData.append('name', name);
    formData.append('image', file);

    return this.http.post<ApiResponse>(environment.api.baseUrl + 'products/' + productId + '/images', formData, options)
      .pipe(
        setLoading(this.productsStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productsStore)),
      );
  }

  addProductOnDb(productForm: ProductForm) {
    return this.addOnDb(productForm.product).pipe(
      filter(productResponse => !!productResponse?.data),
      switchMap(({data: newProduct}) => this.addOrUpdateAdditionalProductDataOnDb(newProduct, productForm)),
    );
  }

  updateProductOnDb(productForm: ProductForm) {
    return this.updateOnDb(productForm.product.id, productForm.product).pipe(
      filter(productResponse => !!productResponse?.data),
      switchMap(({data: updatedProduct}) => this.addOrUpdateAdditionalProductDataOnDb(updatedProduct, productForm)),
    );
  }

  setActiveProduct(id: number | null): void {
    this.productsStore.setActive(id);
  }

  /**
   * Collect all requests for additional attributes on a product.
   *
   * Includes requests for product attributes, supplierProducts and images.
   */
  private addOrUpdateAdditionalProductDataOnDb(
    product: Product,
    {productImage, productAttributes, supplierProducts, variantConfiguration, variants,}: ProductForm
  ) {
    const requests: Observable<unknown>[] = [
      // Add requests for product attributes
      ...this.getProductAttributeRequests(productAttributes, product),

      // Add requests for supplier products
      ...this.getSupplierProductRequests(supplierProducts, product),

      // Add requests for variant attributes and variants
      ...this.getVariantAndVariantAttributeRequests(product, variantConfiguration, variants),
    ];

    // Add request for product image
    if (productImage) {
      const productImageRequest = this.addOrUpdateImage(product.id, productImage.file);
      requests.push(productImageRequest);
    }

    return requests.length > 0
      ? forkJoin(requests).pipe(
        // Return created product
        mapTo(product),
      )
      : of(product);
  }

  /**
   * Collect all requests to add, update or remove product attributes on a product.
   */
  private getProductAttributeRequests(productAttributes: ProductForm['productAttributes'], product: Product) {
    let requests: Observable<ApiResponse>[] = [];

    const {added, removed, updated} = productAttributes;

    if (added.length > 0) {
      const productAttributeAddedRequests = added
        .filter(productAttribute => productAttribute.value?.trim() !== '')
        .map(productAttribute => createProductAttribute({...productAttribute, product_id: product.id}))
        .map(productAttribute => this.productAttributesService.addProductAttributeBasedOfProductAttributeTemplateOnDb(product.id, productAttribute));

      requests = [...requests, ...productAttributeAddedRequests];
    }

    if (updated.length > 0) {
      const productAttributeUpdatedRequests = updated
        .filter(productAttribute => productAttribute.value?.trim() !== '')
        .map(productAttribute => createProductAttribute({...productAttribute, product_id: product.id}))
        .map(productAttribute => this.productAttributesService.updateProductAttributeBasedOfProductAttributeTemplateOnDb(product.id, productAttribute));

      requests = [...requests, ...productAttributeUpdatedRequests];
    }

    if (removed.length > 0) {
      const productAttributeRemovedRequests = removed
        .filter(productAttribute => productAttribute.value?.trim() !== '')
        .map(productAttribute => this.productAttributesService.deleteProductAttributeBasedOfProductAttributeTemplateOnDb(product.id, productAttribute));

      requests = [...requests, ...productAttributeRemovedRequests];
    }

    return requests;
  }

  /**
   * Collect all requests to add, update or remove supplier products on a product.
   */
  private getSupplierProductRequests(supplierProducts: ProductForm['supplierProducts'], product: Product) {
    let requests: Observable<ApiResponse>[] = [];

    const {added, removed, updated} = supplierProducts;

    if (added.length > 0) {
      const supplierProductsAddedRequests = added
        .map(supplierProduct => createSupplierProduct({...supplierProduct, product_id: product.id, id: null}))
        .map(supplierProduct => this.supplierProductsService.add(supplierProduct));

      requests = [...requests, ...supplierProductsAddedRequests];
    }

    if (updated.length > 0) {
      const supplierProductUpdatedRequests = updated
        .map(supplierProduct => createSupplierProduct({...supplierProduct, product_id: product.id}))
        .map(supplierProduct => this.supplierProductsService.update(supplierProduct.id, supplierProduct));

      requests = [...requests, ...supplierProductUpdatedRequests];
    }

    if (removed.length > 0) {
      const supplierProductRemovedRequests = removed.map(supplierProduct =>
        this.supplierProductsService.remove(supplierProduct.id)
      );

      requests = [...requests, ...supplierProductRemovedRequests];
    }

    return requests;
  }

  getVariantAndVariantAttributeRequests(product: Product, variantConfiguration: ProductForm['variantConfiguration'], variants: ProductForm['variants']) {
    const variantRequests = this.getVariantRequests(variants, product);
    const variantAttributeRequests = this.getVariantAttributeRequests(variantConfiguration, product);

    // No variants or variant attributes to create or update
    if (variantAttributeRequests.length <= 0 && variantRequests.length <= 0) {
      return [];
    }

    // Both variant attributes and variants got created or updated
    if (variantAttributeRequests.length > 0 && variantRequests.length > 0) {
      // IMPORTANT: Variant attributes need to be resolved first for correct mapping of variant attributes
      return [
        forkJoin(this.getVariantAttributeRequests(variantConfiguration, product)).pipe(
          // TODO: Remove additional request by using returned data from bulkAddVariantAttributes
          // Fetch variant attributes for product first to ensure that all variant attributes are available
          switchMap(() => this.variantAttributesService.getForProduct(product.id)),
          // Add requests for variants
          switchMap(() => forkJoin(this.getVariantRequests(variants, product))),
        ),
      ];
    }

    return variantAttributeRequests.length > 0
      ? variantAttributeRequests
      : variantRequests;
  }

  private getVariantAttributeRequests(variantAttributes: ProductForm['variantConfiguration'], product: Product) {
    let requests: Observable<ApiResponse>[] = [];

    const {added, updated} = variantAttributes;

    if (added.length > 0) {
      const variantAttributesForRequest = added.map(variantAttribute => ({
          product_attribute_template_id: variantAttribute.product_attribute_template_id,
          product_id: product.id,
          tenant_id: this.sessionQuery.tenantId,
          variant_attribute_options: variantAttribute.variant_attribute_options.map(option => option.value),
        } as BulkAddVariantAttributesRequestItem)
      );

      const variantAttributesAddRequest = this.variantAttributesService.bulkAddVariantAttributes(variantAttributesForRequest);

      requests = [...requests, variantAttributesAddRequest];
    }

    if (updated.length > 0) {
      const variantAttributeOptionsForRequest = updated.flatMap(variantAttribute => variantAttribute.variant_attribute_options.map(option => ({
          variant_attribute_id: variantAttribute.id,
          tenant_id: this.sessionQuery.tenantId,
          value: option.value,
        } as BulkUpdateVariantAttributesRequestItem))
      );

      const variantAttributesUpdateRequest = this.variantAttributeOptionsService.bulkAddVariantAttributeOptions(variantAttributeOptionsForRequest);

      requests = [...requests, variantAttributesUpdateRequest];
    }

    return requests;
  }

  private getVariantRequests(variants: ProductForm['variants'], product: Product) {
    let requests: Observable<ApiResponse>[] = [];

    const {added, removed} = variants;
    const variantAttributes = this.variantAttributesQuery.getVariantAttributesForProduct(product.id);

    if (added.length > 0) {
      const variantsForRequest = added.map(variant => ({
        variant_attribute_options: variant.product_attributes.map(productAttribute => ({
          id: findVariantAttributeOptionForProductAttribute(variantAttributes, productAttribute)?.id,
        }))
      }) as VariantBulkAddVariant);
      const variantsAddRequest = this.bulkAddVariants(product.id, variantsForRequest);

      requests = [...requests, variantsAddRequest];
    }

    if (removed.length > 0) {
      const variantsForRequest = removed
        .filter(variant => variant.id > 0)
        .map(variant => ({id: variant.id}));
      const variantsRemoveRequest = this.bulkRemoveVariants(product.id, variantsForRequest);

      requests = [...requests, variantsRemoveRequest];
    }

    return requests;
  }
}
