import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {DqnOption} from '@dqn/components/combobox';
import {map} from 'rxjs/operators';
import {ProductAttribute} from '../product-attributes/product-attribute.model';
import {ProductAttributeTemplatesState, ProductAttributeTemplatesStore} from './product-attribute-templates.store';

@Injectable({providedIn: 'root'})
export class ProductAttributeTemplatesQuery extends QueryEntity<ProductAttributeTemplatesState> {
  productAttributeTemplates$ = this.selectAll();

  productAttributeTemplatesAsDqnOptions$ = this.productAttributeTemplates$.pipe(
    map(productAttributeTemplate => productAttributeTemplate.map(({id, display_name}) => ({
        value: id,
        title: display_name,
      } as DqnOption))
    ),
  );

  constructor(protected store: ProductAttributeTemplatesStore) {
    super(store);
  }

  selectProductAttributeTemplateById(id: ProductAttribute['id']) {
    return this.selectEntity(id);
  }
}
