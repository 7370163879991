<mat-card #matCardAlert [ngClass]="allCssClasses">
  <mat-card-content>
      <div class="flex items-center">
      <div>
        <mat-icon>{{icon}}</mat-icon>
      </div>
      <div>
        <p>{{text}}</p>
      </div>
    </div>
  </mat-card-content>
</mat-card>
