import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {DqnSingleOption} from '@dqn/components/combobox';
import {map} from 'rxjs/operators';
import {SuppliersState, SuppliersStore} from './suppliers.store';

@Injectable({providedIn: 'root'})
export class SuppliersQuery extends QueryEntity<SuppliersState> {
  get activeSupplier() {
    return this.getActive();
  }

  suppliers$ = this.selectAll();

  suppliersAsDqnOptions$ = this.suppliers$.pipe(
    map(suppliers => suppliers.map(supplier => ({
      value: supplier.id,
      title: supplier.name,
    } as DqnSingleOption)))
  );

  activeSupplier$ = this.selectActive();

  constructor(protected store: SuppliersStore) {
    super(store);
  }

  selectSupplierById(supplierId: number) {
    return this.selectEntity(supplierId);
  }
}
