import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ApprovalWorkflowComponentInterface} from '../../../../interfaces/approval-workflow-component.interface';
import {ApprovalWorkflows} from '../../enums/approval-workflows.enum';

@Component({
  selector: 'app-no-approval-approval-workflow',
  templateUrl: './no-approval-approval-workflow.component.html',
  styleUrls: ['./no-approval-approval-workflow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoApprovalApprovalWorkflowComponent implements OnInit, ApprovalWorkflowComponentInterface {
  static readonly approvalWorkflowName = ApprovalWorkflows.noApproval;

  @Output() approvalWorkflowDataChange = new EventEmitter<unknown>();
  @Output() validityChange = new EventEmitter<boolean>();

  showTitle: boolean;
  approvalWorkflowData: unknown;

  ngOnInit(): void {
    this.initialize();

    setTimeout(() => {
      this.validityChange.emit(true);
    }, 0);
  }

  private initialize() {
    this.showTitle = false;
  }
}
