<div class="mat-typography">
    <div class="mat-headline-5">Datenschutzhinweise – 4sourcing</div>
    <mat-divider class="my-2"></mat-divider>

    <p class="mat-body-1">
        Diese Datenschutzhinweise richten sich an Nutzer des Portals 4sourcing und informiert darüber, welche Daten wir
        systemseitig verarbeiten, wenn Sie 4sourcing (im Folgenden: „Portal“) nutzen.
    </p>
    <p class="mat-body-1">
        Die enthus Managed Services GmbH, Felix-Wankel-Straße 4, 97526 Sennfeld (im Folgenden: "Wir")
        ist als Betreiberin des Portals portal.4sourcing.de verantwortlich für die personenbezogenen Daten der Nutzer
        (im Folgenden: "Sie") des Portals im Sinne der Datenschutzgesetze, insbesondere der Datenschutz-Grundverordnung
        ("DSGVO") und des Bundesdatenschutzgesetzes ("BDSG").
    </p>
    <p class="mat-body-1">
        4sourcing ist ein Portal, das unseren Auftraggebern und Anwendern über den Browser zur Verfügung steht, um die
        internen Bestellprozesse der Auftraggeber optimiert abzubilden.
    </p>
    <p class="mat-body-1">
        Wir schützen Ihre Privatsphäre und Ihre Daten. Wir erheben, verarbeiten und nutzen Ihre personenbezogenen Daten
        in Übereinstimmung mit dem Inhalt dieser Datenschutzbestimmungen sowie den anwendbaren Datenschutzgesetzen,
        insbesondere der DSGVO sowie dem BDSG.
    </p>
    <p class="mat-body-1">
        Diese Datenschutzhinweise klären Sie über die Art, den Umfang und Zweck der Verarbeitung von personenbezogenen
        Daten innerhalb unseres Onlineangebotes und der mit ihm verbundenen, Funktionen und Inhalte auf.
    </p>
    <p class="mat-body-1">
        Der Schutz Ihrer Privatsphäre bei der Nutzung unseres Portals ist uns wichtig. Nehmen Sie daher bitte
        nachstehende Informationen zur Kenntnis. Wir bitten Sie zudem sich regelmäßig über den Inhalt unserer
        Datenschutzhinweise zu
        informieren. Wir überprüfen die Datenschutzhinweise regelmäßig und passen sie an, sobald die Änderungen der von
        uns durchgeführten Datenverarbeitungen dies erforderlich machen.
    </p>
    <p class="mat-body-1">
        Bei Fragen oder Anmerkungen zu datenschutzrechtlichen Angelegenheiten können Sie sich jederzeit an unseren
        Datenschutzbeauftragten wenden. Diesen erreichen Sie unter folgenden Kontaktdaten:
    </p>
    <div class="indent">
        <p>
            Datenschutzbeauftragter enthus Managed Services GmbH<br/>
            Patric Rudtke<br/>
            c/o enthus Managed Services GmbH<br/>
            Felix-Wankel-Str. 4<br/>
            97526 Sennfeld<br/>
        </p>
        <p>
            Tel.: +49 (0)9721 675 94 10<br/>
            E-Mail: <a href="mailto:datenschutz@enthus.de">datenschutz&#64;enthus.de</a>
        </p>
    </div>

    <div class="mat-headline-6 my-4">Begriffserklärungen</div>
    <p class="mat-body-1">
        „Personenbezogene Daten“ sind alle Informationen, die sich auf eine identifizierte oder identifizierbare
        natürliche Person (im Folgenden „betroffene Person“) beziehen; als identifizierbar wird eine natürliche Person
        angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer
        Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren besonderen
        Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen,
        wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind.
    </p>
    <p class="mat-body-1">
        „Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführten Vorgang oder jede solche
        Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch jeden
        Umgang mit Daten.
    </p>
    <p class="mat-body-1">
        Als „Verantwortlicher“ wird die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die
        allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
        entscheidet, bezeichnet.
    </p>

    <div class="mat-headline-6 my-4">Erfassung allgemeiner Informationen beim Aufruf unseres Portals</div>
    <div class="mat-subtitle-2">Art und Zweck der Verarbeitung</div>
    <p class="mat-body-1">
        Bei der Nutzung unseres Portals werden automatisch Protokolldaten, die sogenannten Server-Logs und Events,
        erfasst. Hierbei handelt es sich um allgemeine Daten, wie den von Ihnen verwendeten Webbrowser, die
        Browserversion, ggf. das Betriebssystem, die Benutzerkennung, den Zeitstempel des Zugriffs auf die Software und
        ähnliches. Des weiteren können Protokolldaten bei dem vom Auftraggeber verwendeten Authentifizierungsdienst
        (i.d.R. Microsoft Entra ID) erzeugt werden, die nicht in unserem Einflussbereich liegen.
    </p>
    <p class="mat-body-1">
        Sie werden insbesondere zu folgenden Zwecken verarbeitet:
    </p>
    <ul>
        <li>Sicherstellung eines problemlosen und sicheren Verbindungsaufbaus und Betrieb des Portals,</li>
        <li>Zurverfügungstellung des Onlineangebotes, seiner Funktionalitäten und Inhalte,</li>
        <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
        <li>zur Optimierung unseres Portals.</li>
    </ul>
    <div class="mat-subtitle-2">Rechtsgrundlage und berechtigtes Interesse</div>
    <p class="mat-body-1">
        Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der
        Verbesserung der Stabilität und Funktionalität unseres Portals, sowie Sicherstellung des ordnungsgemäßen
        IT-Betriebs. Informationen über Ihr Widerspruchsrecht nach Art. 21 DSGVO erhalten Sie im Abschnitt „Ihre Rechte
        und Kontakt“ weiter unten.
    </p>
    <div class="mat-subtitle-2">Speicherdauer</div>
    <p class="mat-body-1">
        Die Daten werden gelöscht, sobald diese für den Zweck der Verarbeitung nicht mehr erforderlich sind. Dies ist
        für die Daten, die der Bereitstellung des Portals dienen, grundsätzlich der Fall, wenn die jeweilige Sitzung
        beendet ist.
    </p>
    <p class="mat-body-1">
        Im Falle der Speicherung der Daten in Logdateien ist dies nach spätestens 90 Tagen der Fall. Eine
        darüberhinausgehende Speicherung ist möglich, z.B. im Rahmen der Optimierung der Seite. In diesem Fall werden
        die IP-Adressen der Nutzer anonymisiert, sodass eine Zuordnung des aufrufenden Endgerätes nicht mehr möglich
        ist.
    </p>

    <div class="mat-headline-6 my-4">Erfassung von Fehlerprotokollen</div>
    <div class="mat-subtitle-2">Art und Zweck der Verarbeitung</div>
    <p class="mat-body-1">
        Um Fehler zu erkennen und zu beheben, werden sogenannte Fehlerprotokolle (error logs) erstellt. Hierdurch können
        wir zeitnah auf etwaige Probleme der Darstellung und Umsetzung der Inhalte reagieren.
    </p>
    <p class="mat-body-1">
        Beim Auftreten von Fehlermeldungen werden allgemeine Daten erhoben, wie z.B. die zuletzt durchgeführte Aktion,
        die geöffnete Seite des Portals, der Webbrowser und die Browserversion, das Betriebssystem, die IP-Adresse
        (pseudonymisiert) und der Zeitstempel, wann die entsprechende Fehlermeldung oder Spezifikation aufgetreten ist.
    </p>
    <div class="mat-subtitle-2">Rechtsgrundlage und berechtigtes Interesse</div>
    <p class="mat-body-1">
        Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der
        Verbesserung der Stabilität und Funktionalität unseres Portals, sowie Sicherstellung des ordnungsgemäßen
        IT-Betriebs. Informationen über Ihr Widerspruchsrecht nach Art. 21 DSGVO erhalten Sie im Abschnitt „Ihre Rechte
        und Kontakt“ weiter unten.
    </p>
    <div class="mat-subtitle-2">Speicherdauer</div>
    <p class="mat-body-1">
        Die Daten werden gelöscht, sobald diese für den Zweck der Verarbeitung nicht mehr erforderlich sind.
    </p>
    <p class="mat-body-1">
        Im Falle der Speicherung der Daten in Logdateien ist dies nach spätestens 90 Tagen der Fall. Eine
        darüberhinausgehende Speicherung ist möglich, z.B. im Rahmen der weiteren Optimierung der Seite. In diesem Fall
        werden die Daten der Nutzer anonymisiert, sodass eine Zuordnung des aufrufenden Endgerätes nicht mehr möglich
        ist.
    </p>

    <div class="mat-headline-6 my-4">Cookies und Local Storage</div>
    <p class="mat-body-1">
        Unser Portal verwendet Cookies und Local Storage-Techniken zu unterschiedlichen in dieser Datenschutzerklärung
        jeweils erläuterten Zwecken. Nach den gesetzlichen Vorgaben benötigen wir dafür Ihre Zustimmung, es sei denn,
        die eingesetzten Techniken sind für unser Angebot unbedingt erforderlich (vgl. § 25 TTDSG). Dort wo Ihre
        Zustimmung erforderlich ist, holen wir diese beim ersten Besuch unseres Portals im Rahmen eines
        Begrüßungsdialogs oder unserer Informationen über Cookies und ähnliche Techniken ein.
    </p>
    <div class="mat-subtitle-2">Art und Zweck der Verarbeitung durch Cookies</div>
    <p class="mat-body-1">
        Cookies sind kleine Textdateien, die von Ihrem Browser gesammelt und temporär auf Ihrem Rechner gespeichert
        werden. Sie können weder auf die Daten Ihrer Festplatte zugreifen noch dort Schaden anrichten.
    </p>
    <p class="mat-body-1">
        Wir setzten aktiv keine Cookies, doch können durch die Authentifizierung über Microsoft EntraID am Portal
        weitere unbedingt erforderliche Cookies gesetzt werden.
    </p>
    <p class="mat-body-1">
        Eine Übersicht ist unter diesem
        <a href="https://learn.microsoft.com/en-us/entra/identity/authentication/concept-authentication-web-browser-cookies">Link</a>
        zu finden.
    </p>
    <div class="mat-subtitle-2">Art und Zweck der Verarbeitung Local Storage</div>
    <p class="mat-body-1">
        Damit sich das Portal an Ihre persönlichen Bedürfnisse und Nutzung anpassen lässt, verwenden wir neben Cookies
        auch die sogenannte Local Storage Technik (auch „Lokale Daten“ und „Lokaler Speicher“ genannt). Dabei werden
        Daten lokal im Cache Ihres Browsers gespeichert, die auch nach dem Schließen des Browser-Fensters oder dem
        Beenden des Programms – soweit Sie den Cache nicht löschen – weiterhin bestehen und ausgelesen werden können.
    </p>
    <p class="mat-body-1">
        Local Storage ermöglicht es, dass Ihre Präferenzen bei der Nutzung des Portals auf Ihrem Rechner gespeichert und
        von Ihnen genutzt werden können. Dabei werden folgende Informationen durch das Portal als lokale Daten
        hinterlegt:
    </p>
    <ul>
        <li>der aktuell ausgewählte Mandant</li>
        <li>der Inhalt des Warenkorbs</li>
    </ul>
    <p class="mat-body-1">
        Auf die im Local Storage gespeicherten Daten können Dritte nicht zugreifen. Sie werden nicht an Dritte
        weitergegeben und auch nicht zu Werbezwecken verwendet.
    </p>
    <div class="mat-subtitle-2">Rechtsgrundlage und berechtigtes Interesse</div>
    <p class="mat-body-1">
        Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an einer
        nutzerfreundlichen und sicheren Gestaltung unseres Portals.
    </p>
    <p class="mat-body-1">
        Informationen über Ihr Widerspruchsrecht nach Art. 21 DSGVO erhalten Sie im Abschnitt „Ihre Rechte und Kontakt“
        weiter unten.
    </p>

    <div class="mat-headline-6 my-4">Erteilung, Erbringung und Genehmigung von Aufträgen</div>
    <p class="mat-body-1">
        Unser Portal ermöglicht die Erteilung von Aufträgen. Diese werden z.B. in Form von Bestellungen innerhalb des
        Portals generiert. Je nach Auftrag handelt es sich dabei um kostenpflichtige Leistungen.
    </p>
    <div class="mat-subtitle-2">Art und Zweck der Verarbeitung</div>
    <p class="mat-body-1">
        Zur Erbringung dieser Leistungen werden auftragsspezifische Informationen (z.B. Lieferadressen, Kostenstellen,
        Name, …) zur Ausführung des Auftrags abgefragt. Ebenso ist für einige Auftragsarten vor der Erbringung eine
        Genehmigung durch weitere Personen des Auftraggebers notwendig.
    </p>
    <div class="mat-subtitle-2">Rechtsgrundlage und berechtigtes Interesse</div>
    <p class="mat-body-1">
        Die Verarbeitung der Daten, die für die Erteilung und Erbringung des Auftrages erforderlich ist, basiert auf
        Art. 6 Abs. 1 lit. b und f DSGVO. Den Informationspflichten nach Art. 13ff DSGVO gegenüber den Mitarbeitern
        unserer Kunden nachzukommen ist Aufgabe des Verantwortlichen und damit die unserer Kunden.
    </p>
    <div class="mat-subtitle-2">Speicherdauer</div>
    <p class="mat-body-1">
        Nach gesetzlichen Vorgaben in Deutschland, erfolgt die Aufbewahrung insbesondere für 10 Jahre gemäß §§ 147 Abs.
        1 AO, 257 Abs. 1 Nr. 1 und 4, Abs. 4 HGB (Bücher, Aufzeichnungen, Lageberichte, Buchungsbelege, Handelsbücher,
        für Besteuerung relevanter Unterlagen, etc.) und 6 Jahre gemäß § 257 Abs. 1 Nr. 2 und 3, Abs. 4 HGB
        (Handelsbriefe).
    </p>

    <div class="mat-headline-6 my-4">Übermittlung von Feedback und Verbesserungsvorschlägen</div>
    <p class="mat-body-1">
        In unserer Plattform haben Sie die Möglichkeit Feedback zu hinterlassen.
    </p>
    <div class="mat-subtitle-2">Art und Zweck der Verarbeitung</div>
    <p class="mat-body-1">
        Die Abgabe von Verbesserungsvorschlägen und Feedback („Feedback-Button“) erfolgt personalisiert und ist dabei
        auch für andere Kunden im Portal einsehbar.
    </p>
    <p class="mat-body-1">
        Bei der Abgabe von Feedback und Verbesserungsvorschlägen, besteht die Möglichkeit der Nutzung von
        Freitextfeldern. Wenn Sie sich für die Eingabe persönlicher Daten in das Freitextfeld entscheiden, behandeln wir
        diese Daten gemäß unserer Datenschutzrichtlinie. In diesem Fall werden die personenbezogenen Daten ebenfalls
        verarbeitet, jedoch vor der Nutzung des Feedbacks durch uns redigiert.
    </p>
    <p class="mat-body-1">
        Wir führen auch Umfragen durch, beispielsweise die Ermittlung des NPS. Die Teilnahme an diesen Umfragen ist
        freiwillig. Wir freuen uns sehr über Ihre Teilnahme, da sie uns hilft, unsere Software und Benutzererfahrung zu
        verbessern. Wenn Sie sich für eine Teilnahme entscheiden, verarbeiten wir Ihre Benutzerkennung und Ihren Namen,
        um sicherzustellen, dass Sie nicht mehrere Anfragen erhalten.
    </p>
    <div class="mat-subtitle-2">Rechtsgrundlage und berechtigtes Interesse</div>
    <p class="mat-body-1">
        Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f i.V.m Art. 6 Abs. 1 lit. a DSGVO auf Basis unseres
        berechtigten Interesses unser Produkt entsprechend den Bedürfnissen unserer Anwender zu verbessern.
        Informationen über Ihr Widerspruchsrecht nach Art. 21 DSGVO erhalten Sie im Abschnitt „Ihre Rechte und Kontakt“
        weiter unten.
    </p>

    <div class="mat-headline-6 my-4">Einsatz von Unterauftragnehmern</div>
    <p class="mat-body-1">
        Um unser Produkt bereitstellen und fortlaufend verbessern zu können, greifen wir ggf. auf Dienste von
        Unterauftragnehmer und deren Tools zurück. In bestimmten Fällen verarbeiten Unterauftragnehmer die in diesen
        Datenschutzhinweisen ausgewiesenen Daten. Diese Unterauftragnehmer haben keinen direkten Zugriff auf die im
        Portal gespeicherten Inhalte.
    </p>
    <ul>
        <li>Functional Software, Inc. d/b/a Sentry; 45 Fremont Street, 8th Floor, San Francisco, CA 94105</li>
        <li>Sleekplan GmbH; Georgenstraße 66, 80799 München</li>
    </ul>
    <p class="mat-body-1">
        Diese Unterauftragnehmer haben wir sorgfältig ausgewählt und gemäß Art. 28 DSGVO verpflichtet und werden für
        folgendes eingesetzt:
    </p>
    <ul>
        <li>Tools zur Überwachung, Fehlerbehebung und Identifizierung von Verbesserungsmöglichkeiten unseres Produkts
        </li>
        <li>Dienste wie Benachrichtigungen über geplante Wartungsarbeiten, Ausfallzeiten oder technische Probleme, für
            die Sie sich anmelden können
        </li>
        <li>Dienste zur Erhebung von Feedback und Verbesserungsvorschlägen</li>
    </ul>
    <p class="mat-body-1">
        Wenn Sie weitere detaillierte Informationen zu unseren Unterauftragnehmer erhalten möchten, wenden Sie sich
        bitte an uns.
    </p>

    <div class="mat-headline-6 my-4">Ergänzende Informationen zu diesen Datenschutzhinweisen</div>
    <p class="mat-body-1">
        Weitere Details zu diesen Datenschutzhinweisen finden Sie auf unseren Datenschutzhinweisen der Webseite der
        enthus <a href="https://enthus.de/datenschutz">LINK</a> unter anderem zu folgenden Themen:
    </p>
    <ul>
        <li>Datensicherheit</li>
        <li>Weitergabe Ihrer personenbezogenen Daten</li>
        <li>Übermittlung von Daten innerhalb der Firmengruppe</li>
        <li>Löschung, Widerrufs- und Widerspruchsrecht</li>
        <li>Änderungen dieser Datenschutzbestimmungen</li>
        <li>Ihre Rechte und Kontakt</li>
    </ul>

    <p>Stand: Juni 2024</p>
</div>
