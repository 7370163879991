import {SessionQuery} from '@/core/session/state/session.query';
import {ApiResponse} from '@/shared/types/api/api-response';
import {handleError} from '@/shared/utils';
import {
  createHttpIncludes,
  getHttpOptionsWithInclude,
  getHttpOptionsWithParams
} from '@/shared/utils/functions/http-params';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {setLoading} from '@datorama/akita';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {Product} from '../product.model';
import {VariantAttributeOptionsStore} from '../variant-attribute-options/variant-attribute-options.store';
import {collectVariantAttributeOptions} from './helpers/collect-variant-attribute-options';
import {BulkAddVariantAttributesRequestItem} from './types/bulk-add-variant-attributes-request-item';
import {VariantAttribute} from './variant-attribute.model';
import {VariantAttributesStore} from './variant-attributes.store';

@Injectable({providedIn: 'root'})
export class VariantAttributesService {
  private static readonly includes = createHttpIncludes([
    'variantAttributeOptions',
    'productAttributeTemplate',
  ]);

  private readonly variantAttributesStore = inject(VariantAttributesStore);
  private readonly variantAttributeOptionsStore = inject(VariantAttributeOptionsStore);
  private readonly sessionQuery = inject(SessionQuery);
  private readonly http = inject(HttpClient);
  private readonly snackBar = inject(MatSnackBar);

  get() {
    const options = getHttpOptionsWithParams({
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http
      .get<ApiResponse<VariantAttribute[]>>(environment.api.baseUrl + 'variant-attributes', options)
      .pipe(
        setLoading(this.variantAttributesStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.variantAttributesStore)),
        tap((response) => {
          this.variantAttributesStore.set(response['data']);
        }),
      );
  }

  getForProduct(id: Product['id']) {
    const options = getHttpOptionsWithInclude(VariantAttributesService.includes, {
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http
      .get<ApiResponse<VariantAttribute[]>>(environment.api.baseUrl + `products/${id}/variant-attributes`, options)
      .pipe(
        setLoading(this.variantAttributesStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.variantAttributesStore)),
        tap(({data: variantAttributes}) => {
          // Extract all individual variant attribute options
          const variantAttributeOptions = collectVariantAttributeOptions(variantAttributes);
          this.variantAttributeOptionsStore.set(variantAttributeOptions);

          this.variantAttributesStore.set(variantAttributes);
        }),
      );
  }

  bulkAddVariantAttributes(bulkAddVariantRequestItems: BulkAddVariantAttributesRequestItem[]) {
    const options = getHttpOptionsWithInclude(VariantAttributesService.includes, {
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    const body = {variant_attributes: bulkAddVariantRequestItems};

    return this.http
      .post<ApiResponse<VariantAttribute[]>>(environment.api.baseUrl + 'variant-attributes/bulk-create', body, options)
      .pipe(
        setLoading(this.variantAttributesStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.variantAttributesStore)),
        // TODO: Set to store as soon as the API returns the created variant attributes
        // tap(({data}) => {
        // this.variantAttributesStore.set(data);
        // }),
      );
  }
}
