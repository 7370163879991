<div
  *ngIf="!formMode.isReadonly"
  class="flex justify-end">
    <button
      mat-raised-button
      color="primary"
      [disabled]="(query.limitOfVariantAttributesReached$ | async) || (variantManagementQuery.hasVariants$ | async)"
      (click)="openVariantAttributesAddDialog()"
      [dqnTooltip]="editVariantConfigurationButtonTooltipText$ | async">
        Variantenattribut hinzufügen
    </button>
</div>

<dqn-table
  size="small"
  [rows]="(query.variantsAttributes$ | async) ?? []"
  [headers]="variantAttributeHeaders">
    <td
      *dqnTableCellTemplate="'name'; let attribute"
      dqnTableCell>
        {{ (attribute.product_attribute_template_id | productAttributeTemplateById$ | async)?.display_name }}
    </td>

    <td
      *dqnTableCellTemplate="'options'; let attribute"
      dqnTableCell>
        <div class="flex gap-1">
            <button
              *ngFor="let option of attribute.variant_attribute_options"
              app-variant-option
              [selected]="true">
                {{ option.value }}
            </button>
        </div>
    </td>

    <dqn-table-row-actions *dqnTableRowAction="let attribute">
        <dqn-table-row-action-edit
          *ngIf="!formMode.isReadonly"
          (click)="openVariantAttributesAddDialog(attribute.product_attribute_template_id)"
          text="Optionen hinzufügen" />
    </dqn-table-row-actions>
</dqn-table>
