import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

export enum ConfirmDialogOptions {
  rejected = 'rejected',
  confirmed = 'confirmed',
}

export interface ConfirmDialogData {
  title: string;
  informationText?: string;
  showComment?: boolean;
  confirmText?: string;
}

export interface ConfirmDialogOutputData {
  selection: ConfirmDialogOptions;
  comment?: string;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  confirmForm: UntypedFormGroup;
  title: string;
  informationText: string;
  showComment: boolean;
  confirmText: string;

  get commentControl() {
    return this.confirmForm?.get('comment');
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ConfirmDialogData,
    private dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) {
  }

  ngOnInit() {
    this.initialize();
  }

  cancel(): void {
    this.dialogRef.close({selection: ConfirmDialogOptions.rejected} as ConfirmDialogOutputData);
  }

  confirm(): void {
    const dialogOutput = {selection: ConfirmDialogOptions.confirmed} as ConfirmDialogOutputData;
    if (this.showComment) {
      dialogOutput.comment = this.commentControl?.value;
    }
    this.dialogRef.close(dialogOutput);
  }

  private initialize() {
    this.title = this.data.title;
    this.informationText = this.data?.informationText;
    this.showComment = !!this.data?.showComment;
    this.confirmText = this.data?.confirmText ?? 'Bestätigen';

    this.initializeForm();
  }

  private initializeForm() {
    this.confirmForm = new UntypedFormGroup({});

    if (this.showComment) {
      this.confirmForm.addControl(
        'comment', new UntypedFormControl('', [Validators.maxLength(255)])
      );
    }
  }
}
