import {SessionQuery} from '@/core/session/state/session.query';
import {ApiResponse} from '@/shared/types/api/api-response';
import {handleError} from '@/shared/utils';
import {
  createHttpIncludes,
  getHttpOptionsWithInclude,
  getHttpOptionsWithParams
} from '@/shared/utils/functions/http-params';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {arrayAdd, arrayRemove, arrayUpdate, setLoading} from '@datorama/akita';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {ProductsStore} from '../products.store';
import {ProductAttribute} from './product-attribute.model';

@Injectable({providedIn: 'root'})
export class ProductAttributesService {
  static readonly includes = createHttpIncludes([
    'productAttributeTemplate.productAttributeType',
    'productAttributeTemplate.quantityUnit',
  ]);

  private readonly http = inject(HttpClient);
  private readonly snackBar = inject(MatSnackBar);
  private readonly productsStore = inject(ProductsStore);
  private readonly sessionQuery = inject(SessionQuery);

  addProductAttributeBasedOfProductAttributeTemplateOnDb(productId: number, newProductAttribute: ProductAttribute) {
    const options = getHttpOptionsWithInclude(ProductAttributesService.includes, {
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http.post<ApiResponse<ProductAttribute>>(
      environment.api.baseUrl + 'product-attributes',
      newProductAttribute,
      options
    ).pipe(
      // TODO: Use separate store for product attributes
      setLoading(this.productsStore),
      catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productsStore)),
      tap(({data: productAttribute}) => {
        this.productsStore.update(
          productId,
          (product) => ({
            product_attributes: arrayAdd(
              product?.product_attributes,
              productAttribute
            )
          })
        );
      })
    );
  }

  updateProductAttributeBasedOfProductAttributeTemplateOnDb(productId: number, productAttribute: ProductAttribute) {
    const options = getHttpOptionsWithInclude(ProductAttributesService.includes, {
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http.put<ApiResponse<ProductAttribute>>(
      environment.api.baseUrl + 'product-attributes/' + productAttribute.id,
      productAttribute,
      options
    )
      .pipe(
        // TODO: Use separate store for product attributes
        setLoading(this.productsStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productsStore)),
        tap(({data: productAttributeFromResponse}) => {
          this.productsStore.update(
            productId,
            (product) => ({
              product_attributes: arrayUpdate(
                product?.product_attributes,
                productAttributeFromResponse.id,
                productAttributeFromResponse
              ),
            })
          );
        })
      );
  }

  deleteProductAttributeBasedOfProductAttributeTemplateOnDb(productId: number, productAttribute: ProductAttribute) {
    const options = getHttpOptionsWithParams({
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http.delete(environment.api.baseUrl + 'product-attributes/' + productAttribute.id, options)
      .pipe(
        // TODO: Use separate store for product attributes
        setLoading(this.productsStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productsStore)),
        tap(() => {
          this.productsStore.update(
            productId,
            (product) => ({
              product_attributes: arrayRemove(
                product?.product_attributes,
                productAttribute.id,
              ),
            })
          );
        })
      );
  }
}
