import {DecimalPipe} from '@angular/common';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {takeWhile} from 'rxjs/operators';
import {GermanNumberStringToNumberPipe} from '../../pipes';

@Component({
  selector: 'app-dqn-input-number',
  templateUrl: './dqn-input-number.component.html',
  styleUrls: ['./dqn-input-number.component.scss']
})
export class DqnInputNumberComponent implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() required = false;
  @Input() error: string;

  @Output() numberChange = new EventEmitter<unknown>();
  @Output() touch = new EventEmitter<void>()

  private _value: number = null;

  @Input() set value(value: number) {
    if (value !== this._value) {
      this._value = value;
      this.valueControl.setValue(this.decimalPipe.transform(value));
    }
  }

  get value() {
    return this._value;
  }

  @Input() set disabled(value: boolean) {
    if (value) {
      this.valueControl.disable();
    } else {
      this.valueControl.enable();
    }
  }

  alive = true;
  valueControl: UntypedFormControl = new UntypedFormControl(null);

  constructor(
    private germanNumberStringToNumberPipe: GermanNumberStringToNumberPipe,
    private decimalPipe: DecimalPipe) {
  }

  ngOnInit(): void {
    this.valueControl.valueChanges.pipe(takeWhile(() => this.alive)).subscribe(value => {
      this.value = this.germanNumberStringToNumberPipe.transform(value);
      this.numberChange.emit(this.value);
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
